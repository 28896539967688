import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

type FrameComponent25Type = {
  prop?: string;
};

const FrameComponent25: FunctionComponent<FrameComponent25Type> = ({
  prop,
}) => {
  const navigate = useNavigate();
  return (
    <section className="self-stretch flex flex-col items-end justify-start gap-[16px] text-center text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
        <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 px-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          
          <img
            className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="relative tracking-[-0.25px] leading-[24px] z-[1] w-full">
            알림
          </b>
        </div>

        <div className="self-stretch overflow-hidden flex flex-row items-end justify-start pt-1.5 px-0 pb-0">
          <div className="h-px flex-1 relative bg-line-color" />
          <div className="w-full flex flex-row items-center justify-center z-[1] ml-[-500px]">
          <div className="flex-1 flex flex-row items-start justify-start py-0 gap-[1px]">
            <div onClick={() => {navigate("/notify/reptail")}} className=" cursor-pointer w-[100px] flex-1 flex flex-col items-center justify-start gap-[8px]">
              <div className="flex flex-row items-start justify-start py-0">
                <b className="relative tracking-[-0.25px] leading-[24px]">
                  렙테일 알림
                </b>
              </div>
              <div className="w-full h-0.5 relative bg-solid1" />
            </div>

  
            <div  className=" w-[100px]  flex-1 flex flex-row items-start justify-center pt-0 px-0 pb-2.5 text-dark-grey">
              <div className="flex flex-row items-center justify-center py-0 pr-[25px] pl-[30px]">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  커뮤니티 알림
                </div>
              </div>
            </div>

          </div>
        </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start py-0 text-right text-xs text-light-gray">
        <div className="relative tracking-[-0.25px] leading-[20px] px-5 font-medium">
          {prop}
        </div>
      </div>
    </section>
  );
};

export default FrameComponent25;
