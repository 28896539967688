import { FunctionComponent } from "react";
import FramePhoneNumberInput1 from "../components/FramePhoneNumberInput1";
import Component2 from "../components/Component2";

const Frame32: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[284px]">
      <FramePhoneNumberInput1 />
      <Component2 prop="다음" />
    </div>
  );
};

export default Frame32;
