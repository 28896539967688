import { FunctionComponent } from "react";
import FrameComponent33 from "../new/FrameComponent33";
import { Link } from "react-router-dom";

const Chat: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[41px_0px]">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-5 relative gap-[0px_12px] text-center text-base text-black font-body5medium">
     
        <img
          className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/new/chevronleft1.svg"
        />
        <b className="w-full flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          채팅목록
        </b>
      </div>
      <main className="w-full self-stretch flex flex-row items-start justify-end pt-0 pb-[134px] px-0">
        <section className="w-full flex flex-col items-end justify-start text-left text-base text-dark-grey font-body5medium">
          <div className="w-full flex flex-col items-start justify-start py-0 pr-0 box-border gap-[20px_0px]">
            <Link className = "no-underline text-[#000] w-full" to="/chatdetail1">
            <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 pb-5 px-5 gap-[0px_8px] border-b-[1px] border-solid border-line-color">
              <div className="h-11 w-11 relative rounded-[50%] bg-[#eee]" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[6px_0px] min-w-[149px]">
                <div className="flex flex-row items-start justify-start gap-[0px_6px]">
                  <div className="flex-1 flex flex-row items-center justify-center gap-[0px_6px]">
                    <b className=" flex-1 relative tracking-[-0.25px] leading-[24px]">
                      닉네임최대노출...
                    </b>
                    <div className="flex flex-row items-start justify-start text-xs text-light-gray">
                      <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                        오후 4:42
                      </div>
                    </div>
                  </div>
                  <img
                    className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                    loading="eager"
                    alt=""
                    src="/new/chatnew.svg"
                  />
                </div>
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium text-gray">
                  최근 대화 내용이 노출됩니다.
                </div>
              </div>
              <div className="h-11 w-11 relative rounded-lg bg-box-color overflow-hidden shrink-0" />
            </div>
            </Link >
            <Link className = "no-underline text-[#000] w-full" to="/chatdetail1">
            <div className="no-underline self-stretch flex flex-row flex-wrap items-center justify-start pt-0 pb-5 pr-[21px] pl-5 gap-[0px_8px] border-b-[1px] border-solid border-line-color">
              <div className="h-11 w-11 relative rounded-[50%] bg-[#eee]" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[6px_0px] min-w-[149px]">
                <div className="flex flex-row items-center justify-center gap-[0px_6px]">
                  <b className="relative tracking-[-0.25px] leading-[24px]">
                    닉네임
                  </b>
                  <div className="flex flex-row items-start justify-start text-xs text-light-gray">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      7일전
                    </div>
                  </div>
                  <img
                    className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                    loading="eager"
                    alt=""
                    src="/new/chatnew.svg"
                  />
                </div>
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium text-gray">
                  최근 대화 내용이 노출됩니다.
                </div>
              </div>
              <div className="h-11 w-11 relative rounded-lg bg-box-color overflow-hidden shrink-0" />
            </div>
            </Link>
            <FrameComponent33 />
            <FrameComponent33 />
          </div>
         
        </section>
      </main>
    
    </div>
  );
};

export default Chat;
