import { FunctionComponent } from "react";
import Completedbadge1 from "../new/Completedbadge1";
import SearchResultContainer from "../new/SearchResultContainer";
import ChatBubbleIcon from "../new/ChatBubbleIcon";
import FrameComponent30 from "../new/FrameComponent30";
import CommunityListArea from "../new/CommunityListArea";

const Search: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[34px] box-border gap-[20px_0px]">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1">
        <div className="flex-1 overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-5 relative gap-[0px_12px] text-center text-base text-black font-body5medium">
          <img
            className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/new/chevronleft1.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            검색
          </b>
        </div>
      </div>
      <Completedbadge1 />
      <SearchResultContainer />
      <ChatBubbleIcon />
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-2.5">
        <button className="cursor-pointer [border:none] py-3 pr-5 pl-[21px] bg-[transparent] flex-1 flex flex-row items-center justify-center relative">
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-box-color" />
          <div className="relative text-base tracking-[-0.25px] leading-[24px] font-medium font-body5medium text-black text-center z-[1]">
            판매자 더보기
          </div>
        </button>
      </div>
      <div className="w-full overflow-x-auto flex flex-col items-center justify-start gap-[20px_0px]">
        <div className="w-full h-1 relative bg-box-color" />
        <FrameComponent30 />
        <CommunityListArea />
      </div>
    </div>
  );
};

export default Search;
