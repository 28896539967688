import { FunctionComponent } from "react";
import TabNotificationFrame from "../components/TabNotificationFrame";
import Component9 from "../components/Component9";
import Component8 from "../components/Component8";
import Component7 from "../components/Component7";
import Component6 from "../components/Component6";

const Frame56: FunctionComponent = () => {

  
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[102px] box-border gap-[16px] text-right text-xs text-light-gray font-body5medium">
      
      <TabNotificationFrame />
      <div className="flex flex-row items-start justify-start py-0 px-5">
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          편집
        </div>
      </div>
      <section className="self-stretch flex flex-col items-start justify-start gap-[20px]">
        <Component9
          prop="공지사항이 업로드 되었어요! "
          prop1="지금 바로 공지사항을 확인해주세요."
        />
        <Component9
          prop="이벤트가 업로드 되었어요! "
          prop1="지금 바로 이벤트를 참여하세요."
          propPadding="unset"
        />
        <Component8 />
        <Component7 />
        <Component6 />
      </section>
    </div>
  );
};

export default Frame56;
