import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const MainLinkToArticleRCKTra: FunctionComponent = () => {
  const navigate = useNavigate();

  const onLinkedCityInfoFrameSetClick = useCallback(() => {
    navigate("/notice");
  }, [navigate]);

  const onLinkedCityInfoFrameSet1Click = useCallback(() => {
    navigate("/qna");
  }, [navigate]);

  const onLinkedCityInfoFrameSet2Click = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[10px] max-w-full text-left text-base text-black font-body5medium">
      <div className="w-[157px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
          고객센터
        </b>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start max-w-full">
        <div
          className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
          onClick={onLinkedCityInfoFrameSetClick}
        >
          <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
            <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
              공지사항
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
              alt=""
              src="/chevronright.svg"
            />
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
          onClick={onLinkedCityInfoFrameSet1Click}
        >
          <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
            <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
              자주하는질문
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
              alt=""
              src="/chevronright.svg"
            />
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
          onClick={onLinkedCityInfoFrameSet2Click}
        >
          <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
            <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
              문의하기
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
              alt=""
              src="/chevronright.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainLinkToArticleRCKTra;
