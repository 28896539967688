import { FunctionComponent } from "react";
import FrameComponent38 from "../components/FrameComponent38";
import FrameComponent37 from "../components/FrameComponent37";

const Frame72: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px] tracking-[normal]">
      <div className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] sticky gap-[12px] top-[0] z-[99] text-center text-base text-black font-body5medium">
        
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] whitespace-nowrap z-[1]">
          구매 목록 상세
        </b>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/xclose.svg"
        />
      </div>
      <FrameComponent38 />
      <FrameComponent37 prop="확인" />
    </div>
  );
};

export default Frame72;
