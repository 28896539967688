import { FunctionComponent } from "react";

const FrameComponent11: FunctionComponent = () => {
  return (
    <section className="flex flex-col items-start justify-start py-0 px-5 box-border gap-[8px] min-h-[232px] text-left text-base text-dark-grey font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">
        신분증 사본 첨부
      </b>
      <div className="flex flex-col items-start justify-start gap-[12px] text-xs text-light-gray">
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          <p className="m-0">{`카드결제 정산 정책에 의해 신분증 사본 제출이 필수적으로 필요합니다. `}</p>
          <p className="m-0">
            파일당 최대 10MB까지 첨부가 가능 / 이미지 파일 형식(jpeg, jpg, png)
          </p>
        </div>
        <div className="w-[130px] h-[130px] relative">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
              <div className="absolute h-[18.46%] w-[18.46%] top-[40.77%] right-[40.77%] bottom-[40.77%] left-[40.77%] flex flex-col items-start justify-start z-[1]">
                <img className="w-6 h-6 relative" alt="" src="/imageadd.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent11;
