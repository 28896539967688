import { FunctionComponent } from "react";

const FrameComponent36: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[20px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">판매 정보</b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-sm text-gray">
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-[16px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              판매일시
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              상품명/관리번호
            </div>
          </div>
          <div className="w-[180px] flex flex-col items-start justify-start gap-[16px] text-right text-black">
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium whitespace-nowrap">
              2024.01.01 0:00:00
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              품명을 입력하세요
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            결제수단
          </div>
          <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey text-right flex items-center shrink-0">
            신용카드
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-[16px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              결제구분
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              판매 수수료
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              상품 수수료
            </div>
          </div>
          <div className="w-[180px] flex flex-col items-start justify-start gap-[16px] text-right text-dark-grey">
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              일시불
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
              000,000,000원
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              000,000,000원
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent36;
