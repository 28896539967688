import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Frame36: FunctionComponent = () => {
  const navigate = useNavigate();

  const onBtnMClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onBtnM1Click = useCallback(() => {
    navigate("/21");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[378px] box-border gap-[266px]">
      <div className="self-stretch h-1 relative bg-box-color hidden" />
      <header className="self-stretch flex flex-col items-start justify-start text-center text-base text-black font-body5medium">
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center py-3 pr-14 pl-[55px] relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px] z-[1]"
            alt=""
            src="/xclose.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            등록 완료
          </b>
        </div>
        <div className="self-stretch h-1 relative bg-solid1 z-[1]" />
      </header>
      <section className="w-[278px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[20px] text-center text-base text-dark-grey font-body5medium">
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
          <p className="m-0">판매자 입점 신청이 완료되었습니다.</p>
          <p className="m-0">정산계좌를 등록하시겠습니까?</p>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
          <button
            className="cursor-pointer [border:none] p-4 bg-[transparent] h-12 flex-1 overflow-hidden flex flex-row items-center justify-center box-border relative gap-[10px]"
            onClick={onBtnMClick}
          >
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle-11.svg"
            />
            <b className="absolute my-0 mx-[!important] top-[calc(50%_-_8px)] left-[37px] text-base tracking-[-0.25px] leading-[16px] inline-block font-body5medium text-solid1 text-center z-[1]">
              홈으로
            </b>
          </button>
          <button
            className="cursor-pointer [border:none] py-4 pr-8 pl-[30px] bg-[transparent] h-12 flex-1 overflow-hidden flex flex-row items-center justify-center box-border relative gap-[10px]"
            onClick={onBtnM1Click}
          >
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <b className="absolute my-0 mx-[!important] top-[calc(50%_-_8px)] left-[30px] text-base tracking-[-0.25px] leading-[16px] inline-block font-body5medium text-white text-center z-[1]">
              등록하기
            </b>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Frame36;
