import { FunctionComponent } from "react";

const FrameComponent32: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[20px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">구매자 정보</b>
      <div className="self-stretch flex flex-row items-center justify-between gap-[20px] text-dark-grey">
        <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
          <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
              닉네임
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
              <div className="flex flex-row items-start justify-start gap-[4px]">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  팔로워999+
                </div>
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  |
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                등록개체999+
              </div>
            </div>
          </div>
        </div>
        <img
          className="h-5 w-5 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/new/chevronright1.svg"
        />
      </div>
    </section>
  );
};

export default FrameComponent32;
