import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameSearchInputType = {
  /** Style props */
  propOpacity?: CSSProperties["opacity"];
};

const FrameSearchInput: FunctionComponent<FrameSearchInputType> = ({
  propOpacity,
}) => {
  const onmStyle: CSSProperties = useMemo(() => {
    return {
      opacity: propOpacity,
    };
  }, [propOpacity]);

  return (
    <div className="w-40 shrink-0 flex flex-col items-start justify-start gap-[12px] text-left text-sm text-black font-body5medium">
      <div className="self-stretch h-40 relative" style={onmStyle}>
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-lg max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/rectangle-7-1@2x.png"
        />
        <img
          className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
          alt=""
          src="/heart-2.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[4px] text-light-gray">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            카테고리
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            |
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            지역
          </div>
        </div>
        <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
          상품명을 입력하세요
        </div>
        <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px]">
          200,000원
        </b>
        <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[8px] text-xs text-place-holder">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              999+
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/heart-1.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              999+
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameSearchInput;
