import { FunctionComponent } from "react";

type AppBarFrame2Type = {
  prop?: string;
};

const AppBarFrame2: FunctionComponent<AppBarFrame2Type> = ({ prop }) => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-left text-xs text-gray font-body5medium">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3  relative gap-[12px] text-center text-base text-black font-body5medium">
        
        <img
          className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          {prop}
        </b>
      </div>
      <div className="flex flex-row items-start justify-start py-0 px-5">
        <div className="flex flex-row items-start justify-start">
          <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">{`최신 일자 순 `}</div>
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBarFrame2;
