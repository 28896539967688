import { FunctionComponent } from "react";
import FrameComponent10 from "../components/FrameComponent10";
import FrameComponent9 from "../components/FrameComponent9";
import Component2 from "../components/Component2";

const Frame27: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[282px]">
      <section className="self-stretch flex flex-col items-center justify-start gap-[20px] text-left text-base text-black font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            휴대폰 정보 관리
          </b>
        </header>
        <div className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px]">
          <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
            이름
          </b>
          <div className="self-stretch flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-center p-3 relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/rectangle-1.svg"
              />
              <div className="flex-1 overflow-hidden flex flex-row items-center justify-between py-0 px-1 z-[1]">
                <div className="flex flex-row items-center justify-start gap-[4px]">
                  <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                    렙테일
                  </div>
                  <div className="h-4 w-px relative bg-black" />
                </div>
                <img
                  className="h-[18px] w-[18px] relative object-cover"
                  loading="eager"
                  alt=""
                  src="/btn-remove1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px]">
          <b className="relative tracking-[-0.25px] leading-[24px]">통신사</b>
          <div className="self-stretch flex flex-row items-start justify-start py-3 px-4 relative gap-[10px] text-place-holder">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              통신사를 선택하세요
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
        <FrameComponent10 />
        <FrameComponent9 />
      </section>
      <Component2 prop="완료" />
    </div>
  );
};

export default Frame27;
