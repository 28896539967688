import { FunctionComponent } from "react";
import FrameComponent31 from "../components/FrameComponent31";
import Nav1 from "../components/Nav1";

const Frame60: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[90px] tracking-[normal] text-center text-sm text-place-holder font-body5medium">
      <FrameComponent31
        prop="판매자 검색"
        appBarTop="unset"
        appBarPosition="unset"
        appBarHeight="176px"
        rectangleIconPosition="sticky"
        rectangleIconTop="0"
        inputFlex="1"
      />
      <div className="flex flex-row items-start justify-start pt-0 px-5 pb-[60px]">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          검색 기록이 없습니다.
        </div>
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[30px] text-left text-base text-black">
        <div className="self-stretch h-1 relative bg-box-color">
          <div className="absolute top-[-63px] right-[6px] w-1 h-[100px] z-[1]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-gray1" />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between py-0 px-5 gap-[20px] mq450:flex-wrap">
          <b className="relative tracking-[-0.25px] leading-[24px]">최근검색</b>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray text-right">
            전체삭제
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start pt-0 px-5 pb-4">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          최근 검색 기록이 없습니다.
        </div>
      </div>
      <Nav1
        prizeoff="/prizeoff.svg"
        commuoff="/commuon.svg"
        propGap="18.75px"
        propColor="#333"
        propColor1="#4d33cd"
      />
    </div>
  );
};

export default Frame60;
