import { FunctionComponent } from "react";
import Component3 from "./Component3";

const FrameMain: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-end justify-end pt-36 px-5 pb-0 relative text-center text-sm text-solid1 font-body5medium">
      <header className="w-full my-0 mx-[!important] absolute top-[0px] right-[0px] left-[0px] bg-white overflow-hidden flex flex-row items-start justify-between py-3 px-5 box-border text-center text-base text-black font-body5medium">
        <img
          className="h-12 w-[375px] relative hidden"
          alt=""
          src="/new/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/new/chevronleft1.svg"
        />
        <div className="flex flex-row items-center justify-start gap-[0px_4px]">
          <b className="relative tracking-[-0.25px] leading-[24px] z-[1]">
            닉네임
          </b>
          <img
            className="h-5 w-5 relative z-[1]"
            loading="eager"
            alt=""
            src="/new/bellringing01.svg"
          />
        </div>
        <img
          className="h-6 w-6 relative object-contain min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/new/component-11@2x.png"
        />
      </header>
      <div className="my-0 mx-[!important] absolute top-[68px] left-[calc(50%_-_167.5px)] rounded-lg bg-solid2 flex flex-row items-start justify-start py-3 px-5 gap-[20px]">
        <div className="h-[46px] w-[335px] relative rounded-lg bg-solid2 hidden z-[0]" />
        <b className="relative tracking-[-0.25px] leading-[22px] z-[1]">안내</b>
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey text-left z-[1]">
          렙테일을 통한 안심 계좌이체를 이용해 보세요!
        </div>
        <Component3
          prop="알림끄기"
          propTop="unset"
          propRight="0px"
          propBottom="-56px"
        />
      </div>
      <div className="w-[270px] flex flex-row flex-wrap items-end justify-center pt-2.5 px-0 pb-0 box-border text-left text-black">
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px_0px]">
          <div className="self-stretch flex flex-col items-start justify-center gap-[12px_0px]">
            <div className="self-stretch flex flex-col items-start justify-center gap-[4px_0px]">
              <div className="flex flex-row items-center justify-center gap-[0px_4px] text-light-gray">
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  카테고리
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  |
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  지역
                </div>
              </div>
              <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
                상품명을 입력하세요상품명을 입력하세요
              </div>
              <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px]">
                500,000원
              </b>
            </div>
            <img
              className="w-[98px] h-[98px] relative rounded-lg overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/new/frame-267.svg"
            />
          </div>
          <button className="cursor-pointer [border:none] pt-3 pb-[11px] pr-5 pl-[21px] bg-solid2 self-stretch rounded-lg flex flex-row items-center justify-center">
            <div className="h-[42px] w-[270px] relative rounded-lg bg-solid2 hidden" />
            <b className="w-[99px] relative text-sm tracking-[-0.25px] leading-[22px] flex font-body5medium text-solid1 text-center items-center justify-center shrink-0 z-[1]">
              상품 상세보기
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default FrameMain;
