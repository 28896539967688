import { FunctionComponent } from "react";
import FrameSet from "../components/FrameSet";
import FrameWithQuestion from "../components/FrameWithQuestion";
import { Link } from "react-router-dom";

const QNA: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px] text-left text-sm text-place-holder font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1">
        <div className="flex-1 overflow-hidden flex flex-row items-start justify-start py-3 relative gap-[12px] text-center text-base text-black font-body5medium">
          <Link to="/">
            <img
              className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/chevronleft.svg"
            />
          </Link>
          <b className="relative tracking-[-0.25px] leading-[24px] z-[1] w-full">
            자주하는질문
          </b>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 pb-1">
        <div className="rounded-lg bg-[#eee] flex-1 flex flex-row items-start justify-start py-3 px-4 relative gap-[10px]">
         
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
            검색어를 입력하세요.
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/searchmd1.svg"
          />
        </div>
      </div>
      <FrameSet />
      <FrameSet />
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 gap-[12px] text-base text-black">
        <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[194px]">
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">{`자주하는 질문이 노출됩니다. `}</div>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-gray">
            2023.01.01
          </div>
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/chevronup.svg"
        />
      </div>
      <section className="self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-5 text-left text-sm text-dark-grey font-body5medium border-[NaNpx] border-solid border-line-color">
        <div className="h-[220px] relative box-border hidden border-b-[1px] border-solid border-line-color" />
        <div className="rounded-lg bg-box-color flex flex-row items-center justify-center p-5 box-border z-[1]">
          <div className="h-[200px]  relative rounded-lg bg-box-color hidden" />
          <div className="h-40 flex-1 relative tracking-[-0.25px] leading-[22px] font-medium inline-block z-[1]">
            질문에 대한 답변이 노출됩니다.질문에 대한 답변이 노출됩니다.질문에
            대한 답변이 노출됩니다.질문에 대한 답변이 노출됩니다.질문에 대한
            답변이 노출됩니다.질문에 대한 답변이 노출됩니다.질문에 대한 답변이
            노출됩니다.질문에 대한 답변이 노출됩니다.질문에 대한 답변이
            노출됩니다.질문에 대한 답변이 노출됩니다.질문에 대한 답변이 질문에
            대한 답변이 질문에 대한 답변이 ....
          </div>
        </div>
      </section>
      <FrameSet />
    </div>
  );
};

export default QNA;
