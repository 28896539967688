import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Btnmobilefull from "./Btnmobilefull";
import Framephoneauth1 from "./Framephoneauth1";
import Framephoneauth from "./Framephoneauth";

const Frameappbar: FunctionComponent = () => {
  const navigate = useNavigate();

  const onBtnClick = useCallback(() => {
    navigate("/19");
  }, [navigate]);

  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[24px] text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch h-[58px] flex flex-col items-start justify-start">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            휴대폰 인증
          </b>
        </header>
        <div className="self-stretch h-1 relative bg-box-color">
          <div className="absolute top-[0px] left-[0px] bg-box-color w-[375px] h-1 hidden" />
          <div className="absolute top-[0px] left-[0px] bg-solid1 w-[282px] h-1 z-[1]" />
        </div>
      </div>
      <Btnmobilefull />
      <Framephoneauth1 />
      <Framephoneauth
        group165Placeholder="인증번호를 입력하세요."
        rectangle="/rectangle.svg"
        onBtnClick={onBtnClick}
      />
    </section>
  );
};

export default Frameappbar;
