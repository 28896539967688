import { FunctionComponent } from "react";

const PersonalAccountInput: FunctionComponent = () => {
  return (
    <section className="h-[134px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[18px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">
        현금영수증 발행
      </b>
      <div className="flex flex-col items-start justify-start gap-[12px] text-sm text-light-gray">
        <div className="flex flex-row items-start justify-start gap-[20px]">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <input
              className="border-solid1 accent-solid1 cursor-pointer m-0 h-[18px] w-[18px] relative overflow-hidden shrink-0"
              checked={true}
              type="radio"
              name="radioGroup-1"
            />
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              개인공제
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <input
              className="border-white accent-white cursor-pointer m-0 h-[18px] w-[18px] relative overflow-hidden shrink-0"
              checked={true}
              type="radio"
              name="radioGroup-1"
            />
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              사업자증빙
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative text-base text-place-holder">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            -없이 사업자번호 혹은 휴대폰번호 입력해주세요.
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalAccountInput;
