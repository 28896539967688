import { FunctionComponent } from "react";

const ImageUploadBoxS: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[506px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[20px] text-left text-base text-place-holder font-body5medium">
      <div className="self-stretch flex-1 rounded-lg flex flex-row items-start justify-start py-5 pr-[50px] pl-4 z-[1] border-[1px] border-solid border-line-color">
        <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
          게시글 내용을 입력하세요.
        </div>
        <div className="h-[346px] w-[335px] relative rounded-lg box-border hidden border-[1px] border-solid border-line-color" />
      </div>
      <b className="relative tracking-[-0.25px] leading-[24px] text-black text-center">
        이미지 업로드
      </b>
      <div className="w-[290px] flex flex-row items-start justify-start gap-[10px]">
        <div className="h-[90px] flex-1 relative">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
            </div>
          </div>
          <img
            className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
            loading="eager"
            alt=""
            src="/imageadd.svg"
          />
        </div>
        <div className="flex-1 flex flex-row items-center justify-center">
          <div className="h-[90px] flex-1 relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
              </div>
            </div>
            <img
              className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="eager"
              alt=""
              src="/x.svg"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-row items-center justify-center">
          <div className="h-[90px] flex-1 relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
              </div>
            </div>
            <img
              className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="eager"
              alt=""
              src="/x.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageUploadBoxS;
