import { FunctionComponent } from "react";
import TagFrame from "./TagFrame";

const ContentFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[12px] text-left text-sm text-gray font-body5medium">
      <TagFrame />
      <div className="self-stretch h-3 flex flex-row items-start justify-start pt-0 px-0 pb-2 box-border">
        <div className="self-stretch flex-1 relative bg-box-color overflow-hidden" />
      </div>
      <div className="self-stretch h-[26px] flex flex-row items-start justify-start py-0 px-[21px] box-border">
        <b className="relative tracking-[-0.25px] leading-[22px]">댓글2</b>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 text-base text-dark-grey">
        <div className="w-[196px] flex flex-row items-start justify-start gap-[10px]">
          <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
          <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
            <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
              닉네임
            </div>
            <div className="flex flex-row items-start justify-start text-xs text-light-gray">
              <div className="flex flex-row items-center justify-start">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  2024.01.01
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey pl-5 pr-5">
        댓글이 노출됩니다.
      </div>
    </section>
  );
};

export default ContentFrame;
