import { FunctionComponent } from "react";

const FrameComponent30: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[30px] max-w-full text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start relative max-w-full">
        <div className="h-[100px] w-1 absolute my-0 mx-[!important] right-[6px] bottom-[-59px] z-[1]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-gray1" />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px] max-w-full z-[2]">
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5 gap-[20px] mq450:flex-wrap">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-center text-xs text-place-holder border-[1px] border-solid border-line-color">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로우
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5 gap-[20px] mq450:flex-wrap">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-center text-xs text-place-holder border-[1px] border-solid border-line-color">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로우
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5 gap-[20px] mq450:flex-wrap">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-center text-xs text-place-holder border-[1px] border-solid border-line-color">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로우
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5 gap-[20px] mq450:flex-wrap">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-center text-xs text-place-holder border-[1px] border-solid border-line-color">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로우
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch h-1 relative bg-box-color overflow-hidden shrink-0" />
    </section>
  );
};

export default FrameComponent30;
