import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FooterButtonBarType = {
  prop?: string;
  prop1?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
  propHeight?: CSSProperties["height"];
};

const FooterButtonBar: FunctionComponent<FooterButtonBarType> = ({
  prop,
  prop1,
  propPadding,
  propHeight,
}) => {
  const footerButtonBarStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
      height: propHeight,
    };
  }, [propPadding, propHeight]);

  return (
    <section
      className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-dark-grey font-body5medium"
      style={footerButtonBarStyle}
    >
      <b className="relative tracking-[-0.25px] leading-[24px]">{prop}</b>
      <div className="self-stretch flex flex-row items-center justify-center text-black">
        <div className="flex-1 flex flex-row items-center justify-center">
          <div className="flex-1 flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              {prop1}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterButtonBar;
