import { FunctionComponent, useMemo, type CSSProperties } from "react";

type InputDefaultLabelType = {
  prop?: string;

  /** Style props */
  propHeight?: CSSProperties["height"];
  propWidth?: CSSProperties["width"];
  propDisplay?: CSSProperties["display"];
  propFlex?: CSSProperties["flex"];
};

const InputDefaultLabel: FunctionComponent<InputDefaultLabelType> = ({
  prop,
  propHeight,
  propWidth,
  propDisplay,
  propFlex,
}) => {
  const inputDefaultLabelStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  const b1Style: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
      display: propDisplay,
    };
  }, [propWidth, propDisplay]);

  const div5Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  return (
    <div
      className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] text-left text-base text-dark-grey font-body5medium"
      style={inputDefaultLabelStyle}
    >
      <b
        className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center"
        style={b1Style}
      >
        이름
      </b>
      <div className="self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative text-place-holder">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/rectangle.svg"
        />
        <div
          className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
          style={div5Style}
        >
          {prop}
        </div>
      </div>
    </div>
  );
};

export default InputDefaultLabel;
