import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FramesStackType = {
  prop?: string;

  /** Style props */
  propAlignSelf?: CSSProperties["alignSelf"];

  /** Action props */
  onFramesStackContainerClick?: () => void;
};

const FramesStack: FunctionComponent<FramesStackType> = ({
  prop,
  propAlignSelf,
  onFramesStackContainerClick,
}) => {
  const topFrameStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div
      className="self-stretch flex flex-col items-start justify-start pt-0 pb-5 pr-[45px] pl-5 gap-[10px] cursor-pointer text-left text-base text-black font-body5medium border-b-[1px] border-solid border-line-color"
      onClick={onFramesStackContainerClick}
    >
      <div
        className="self-stretch flex flex-row items-start justify-start gap-[8px]"
        style={topFrameStyle}
      >
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
          {prop}
        </div>
        <div className="h-6 rounded-lg bg-solid1 overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border text-center text-xs text-mintcream">
          <b className="relative tracking-[-0.25px] leading-[20px]">NEW</b>
        </div>
      </div>
      <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-gray">
        2023.01.01
      </div>
    </div>
  );
};

export default FramesStack;
