import { FunctionComponent } from "react";
import FrameComponent25 from "../components/FrameComponent25";
import FrameComponent24 from "../components/FrameComponent24";
import EditedNotificationFrame from "../components/EditedNotificationFrame";
import FrameComponent23 from "../components/FrameComponent23";
import CompletedFrame from "../components/CompletedFrame";

const Frame54: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[318px] box-border gap-[16px] text-center text-xs text-place-holder font-body5medium">
      <div className="w-[50px] hidden flex-col items-start justify-start gap-[5px]">
        <img
          className="w-[50px] h-[50px] relative"
          alt=""
          src="/group-163.svg"
        />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start relative gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-soild-color z-[0]" />
          <img
            className="h-0 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <FrameComponent25 prop="편집완료" />
      <FrameComponent24
        prop="님이 게시글에 공감하였습니다."
        prop1="10시간전"
        xClose="/xclose.svg"
      />
      <EditedNotificationFrame />
      <FrameComponent23 />
      <div className="w-[47px] h-6 rounded-lg box-border overflow-hidden shrink-0 hidden flex-row items-center justify-center py-0 px-[7px] border-[1px] border-solid border-line-color">
        <b className="relative tracking-[-0.25px] leading-[20px]">팔로우</b>
      </div>
      <CompletedFrame
        prop="닉네임"
        prop1="님이 댓글에 좋아요를 눌렀습니다."
        prop2="10시간전"
        xClose="/xclose.svg"
        propFlex="unset"
        propAlignSelf="stretch"
        propAlignSelf1="unset"
      />
    </div>
  );
};

export default Frame54;
