import { FunctionComponent } from "react";
import CompletedFrame from "./CompletedFrame";

const EditedNotificationFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 text-left text-xs text-light-gray font-body5medium">
      <CompletedFrame
        prop="닉네임이최대로길어진케이스"
        prop1="님이 게시글에 공감하였습니다."
        prop2="10시간전"
        xClose="/xclose-1.svg"
      />
    </section>
  );
};

export default EditedNotificationFrame;
