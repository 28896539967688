import { FunctionComponent } from "react";

const ChatBubbleIcon: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-2.5 gap-[20px_0px] text-left text-base text-black font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-2.5">
        <button className="cursor-pointer [border:none] py-3 pr-5 pl-[21px] bg-[transparent] flex-1 flex flex-row items-center justify-center relative">
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-box-color" />
          <div className="relative text-base tracking-[-0.25px] leading-[24px] font-medium font-body5medium text-black text-center z-[1]">
            렙테일 분양 글 더보기
          </div>
        </button>
      </div>
      <div className="self-stretch h-1 relative bg-box-color" />
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px_0px]">
        <div className="flex flex-row items-start justify-start py-0 px-5">
          <b className="relative tracking-[-0.25px] leading-[24px]">판매자</b>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px_0px] text-dark-grey">
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[0px_10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-[#eee]" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[0px_4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-center text-xs text-place-holder border-[1px] border-solid border-line-color">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로우
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[0px_10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-[#eee]" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[0px_4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-center text-xs text-place-holder border-[1px] border-solid border-line-color">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로우
              </b>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatBubbleIcon;
