import { FunctionComponent } from "react";
import FrameCommunications from "../components/FrameCommunications";
import FrameCommunications1 from "../components/FrameCommunications1";
import FrameAuthenticationInput from "../components/FrameAuthenticationInput";
import Component3 from "../components/Component3";

const Frame26: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px]">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          휴대폰 정보 관리
        </b>
      </header>
      <main className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[24px] min-h-[742px]">
        <FrameCommunications
          prop="이름"
          prop1="렙테일"
          propGap="12px"
          propDisplay="flex"
          propWidth="101px"
          propFlex="unset"
        />
        <FrameCommunications1 />
        <section className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-base text-black font-body5medium">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            휴대폰 번호
          </b>
          <div className="self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative text-place-holder">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              휴대폰 번호를 입력해주세요.
            </div>
          </div>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            기존에 등록된 휴대폰 번호는 010-0000-0000 입니다.
          </div>
        </section>
        <FrameAuthenticationInput />
      </main>
      <Component3 prop="수정하기" propMarginTop="unset" />
    </div>
  );
};

export default Frame26;
