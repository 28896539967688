import { FunctionComponent } from "react";
import CommulistFrame from "../components/CommulistFrame";
import TopLevelFrame from "../components/TopLevelFrame";
import Nav1 from "../components/Nav1";

const Frame51: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[16px]">
      <div className="w-[50px] hidden flex-col items-start justify-start gap-[5px]">
        <img
          className="w-[50px] h-[50px] relative"
          alt=""
          src="/group-163.svg"
        />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start relative gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-soild-color z-[0]" />
          <img
            className="h-0 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <section className="self-stretch flex flex-col items-end justify-start gap-[16px] text-right text-xs text-light-gray font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            {" "}
            최근 본 게시물
          </b>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/xclose.svg"
          />
        </header>
        <div className="flex flex-row items-start justify-start py-0 px-5">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            편집완료
          </div>
        </div>
      </section>
      <CommulistFrame />
      <TopLevelFrame />
      <Nav1
        prizeoff="/prizeoff.svg"
        commuoff="/commuon.svg"
        propGap="unset"
        propColor="#333"
        propColor1="#4d33cd"
      />
    </div>
  );
};

export default Frame51;
