import { FunctionComponent } from "react";

const Component8: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-end justify-start text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 pb-5 pr-0.5 pl-5 gap-[10px] border-b-[1px] border-solid border-line-color">
        <div className="flex-1 flex flex-row items-center justify-center gap-[10px] min-w-[198px]">
          <div className="flex flex-row items-start justify-start py-5 px-0">
            <img
              className="h-11 w-11 relative"
              loading="eager"
              alt=""
              src="/group1.svg"
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium pr-0.5">
              <p className="m-0">{`찜하신 상품 ”크레스티드 게코...” 가격이 `}</p>
              <p className="m-0">{`500,000원으로 변동되었어요. `}</p>
            </div>
            <div className="self-stretch flex flex-row items-center justify-center gap-[9px] text-sm text-solid1">
              <div className="flex-1 flex flex-row items-start justify-start">
                <div className="flex flex-row items-center justify-center py-0 pr-5 pl-0 gap-[6px]">
                  <b className="relative tracking-[-0.25px] leading-[22px]">
                    가격변동 50,000원
                  </b>
                  <img
                    className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
                    alt=""
                    src="/chevrondown.svg"
                  />
                </div>
              </div>
              <img
                className="h-[22px] w-[22px] relative overflow-hidden shrink-0 hidden min-h-[22px]"
                alt=""
                src="/chatnew.svg"
              />
            </div>
            <div className="w-[59px] relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray flex items-center pr-5">
              1시간 전
            </div>
          </div>
        </div>
        <div className="h-[60px] w-[39px] flex flex-row items-start justify-start py-5 px-0 box-border gap-[4px] text-xs text-light-gray">
          <div className="self-stretch w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
            |
          </div>
          <div className="self-stretch w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
            등록개체999+
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component8;
