import { FunctionComponent, useMemo, type CSSProperties } from "react";

type TagFrameType = {
  /** Style props */
  propAlignSelf?: CSSProperties["alignSelf"];
  propHeight?: CSSProperties["height"];
  propWidth?: CSSProperties["width"];
  propPadding?: CSSProperties["padding"];
  propFlex?: CSSProperties["flex"];
  propAlignSelf1?: CSSProperties["alignSelf"];
  propFlex1?: CSSProperties["flex"];
};

const TagFrame: FunctionComponent<TagFrameType> = ({
  propAlignSelf,
  propHeight,
  propWidth,
  propPadding,
  propFlex,
  propAlignSelf1,
  propFlex1,
}) => {
  const tagFrameStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      height: propHeight,
      width: propWidth,
    };
  }, [propAlignSelf, propHeight, propWidth]);

  const tAGICICICStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
      flex: propFlex,
    };
  }, [propPadding, propFlex]);

  const postContentAreaStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
    };
  }, [propAlignSelf1]);

  const div11Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex1,
    };
  }, [propFlex1]);

  return (
    <div
      className="self-stretch h-[94px] flex flex-row items-start justify-start py-0 px-[17px] box-border text-left text-sm text-gray font-body5medium"
      style={tagFrameStyle}
    >
      <div
        className="flex flex-col items-start justify-start py-0 pr-5 pl-0 gap-[12px]"
        style={tAGICICICStyle}
      >
        <div
          className="flex flex-row items-start justify-start relative"
          style={postContentAreaStyle}
        >
          <div className="w-[335px] absolute my-0 mx-[!important] top-[-44px] right-[-184px] tracking-[-0.25px] leading-[22px] font-medium inline-block">{`게시글의 내용이 노출되는 곳입니다. 게시글의 내용이 노출되는 곳입니다. 게시글의 내용이 노출되는 곳입니다. 게시글의 내용이 노출되는 곳입니다. `}</div>
          <div
            className="rounded-xl bg-white shadow-[2px_2px_10px_rgba(0,_0,_0,_0.1)] overflow-hidden flex flex-row items-start justify-start py-1.5 pr-[13px] pl-[11px] gap-[6px] z-[1] border-[1px] border-solid border-line-color"
            style={div11Style}
          >
            <img
              className="h-7 w-7 relative overflow-hidden shrink-0 min-h-[28px]"
              loading="eager"
              alt=""
              src="/ic241.svg"
            />
            <img
              className="h-7 w-7 relative overflow-hidden shrink-0 min-h-[28px]"
              loading="eager"
              alt=""
              src="/ic24-1.svg"
            />
            <img
              className="h-7 w-7 relative overflow-hidden shrink-0 min-h-[28px]"
              loading="eager"
              alt=""
              src="/ic24-2.svg"
            />
            <img
              className="h-7 w-7 relative overflow-hidden shrink-0 min-h-[28px]"
              loading="eager"
              alt=""
              src="/ic24-3.svg"
            />
          </div>
        </div>
        <div className="flex flex-row items-start justify-start py-0 px-[3px] text-center">
          <div className="rounded-xl overflow-hidden flex flex-row items-center justify-start py-[5px] pr-[11px] pl-[9px] gap-[5px] border-[1px] border-solid border-line-color">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/ic24.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              공감하기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagFrame;
