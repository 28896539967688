import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import InputDefaultLabel from "../components/InputDefaultLabel";
import AuthenticationInput from "../components/AuthenticationInput";
import Mobilenumberinputframe from "../components/Mobilenumberinputframe";
import Component2 from "../components/Component2";

const Frame22: FunctionComponent = () => {
  const navigate = useNavigate();

  const onTextFieldBasicButtonInClick = useCallback(() => {
    navigate("/17");
  }, [navigate]);

  const onSectionClick = useCallback(() => {
    navigate("/18");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[146px]">
      <section className="self-stretch flex flex-col items-center justify-start gap-[22px] text-left text-base text-black font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            판매자 정보 관리
          </b>
        </header>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-[19px]">
          <b className="w-[153px] relative tracking-[-0.25px] leading-[24px] flex items-center pr-5">
            개인 판매자 정보 관리
          </b>
        </div>
        <InputDefaultLabel
          prop="홍길동"
          propHeight="unset"
          propWidth="unset"
          propDisplay="inline-block"
          propFlex="unset"
        />
        <AuthenticationInput
          group165Placeholder="13002"
          prop="서울특별시 성동구"
          prop1="성동로 00 (성동동, 성동시티 8단지)"
          propFlex="1"
          propFlex1="1"
          onTextFieldBasicButtonInClick={onTextFieldBasicButtonInClick}
        />
        <Mobilenumberinputframe />
      </section>
      <Component2 prop="수정하기" onSectionClick={onSectionClick} />
    </div>
  );
};

export default Frame22;
