import { FunctionComponent } from "react";

export type FrameComponent2Type = {
  /** Action props */
  onFrameContainerClick?: () => void;
};

const FrameComponent2: FunctionComponent<FrameComponent2Type> = ({
  onFrameContainerClick,
}) => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-[30px] gap-[20px_0px] text-center text-base text-black font-body5medium">
      <div className="self-stretch h-2.5 flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border">
        <img
          className="flex-1 relative rounded-12xs max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/new/vector-3.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[14px_0px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
          <div className="self-stretch flex flex-row items-center justify-between">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              판매자정보
            </b>
            <div
              className="flex flex-row items-start justify-start cursor-pointer text-xs text-place-holder"
              onClick={onFrameContainerClick}
            >
              <div className="flex flex-col items-start justify-start py-0 px-0">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  프로필 보러가기
                </div>
              </div>
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 min-h-[20px]"
                alt=""
                src="/new/chevronright-2.svg"
              />
            </div>
          </div>
          <div className="w-[196px] flex flex-row items-center justify-start gap-[0px_10px] text-left text-dark-grey">
            <div className="h-11 w-11 relative rounded-[50%] bg-[#eee]" />
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                닉네임
              </div>
              <div className="flex flex-row items-start justify-start gap-[0px_4px] text-xs text-light-gray">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                </div>
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  등록개체999+
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center pt-0.5 px-0 pb-0 text-left text-xs text-gray">
          <div className="h-[58px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-0 box-border">
            <div className="mb-[-2px] self-stretch relative tracking-[-0.25px] leading-[20px] font-medium">
              판매자 소개글이 노출됩니다. 판매자 소개글이 노출됩니다.판매자
              소개글이 노출됩니다.판매자 소개글이 노출됩니다.판매자 소개글이
              노출됩니다.판매자 소개글이 노출됩니다.판매자 소개글이
              노출됩니다.판매자 소개글...
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch h-1 relative bg-box-color" />
    </section>
  );
};

export default FrameComponent2;
