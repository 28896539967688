import { FunctionComponent } from "react";
import FooterButtonBar from "../components/FooterButtonBar";
import FrameComponent17 from "../components/FrameComponent17";
import FrameComponent13 from "../components/FrameComponent13";
import FrameComponent7 from "../components/FrameComponent7";
import Component3 from "../components/Component3";

const Frame41: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-px box-border gap-[20px]">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          판매자 정보 관리
        </b>
      </header>
      <main className="self-stretch flex flex-col items-start justify-start py-0 pr-[21px] pl-[19px] gap-[20px] text-left text-base text-black font-body5medium">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          사업자 판매자 정보 관리
        </b>
        <FooterButtonBar
          prop="대표자"
          prop1="대표자명을 입력하세요."
          propPadding="unset"
          propHeight="88px"
        />
        <FooterButtonBar
          prop="상호명"
          prop1="상호명을 입력하세요."
          propPadding="unset"
          propHeight="88px"
        />
        <FrameComponent17 divPadding="unset" propPadding="0px 0px 0px 20px" />
        <FooterButtonBar
          prop="사업자등록번호"
          prop1="사업자등록번호를 입력하세요."
          propPadding="unset"
          propHeight="88px"
        />
        <FrameComponent13 prop="사업자등록증 첨부" propHeight="170px" />
        <FrameComponent7 propMinHeight="unset" propPadding="unset" />
      </main>
      <Component3 prop="수정하기" propMarginTop="unset" />
    </div>
  );
};

export default Frame41;
