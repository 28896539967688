import { FunctionComponent } from "react";
import FrameComponent27 from "../components/FrameComponent27";

const Component10: FunctionComponent = () => {
  return (
    <footer className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[263px] box-border gap-[13px] text-left text-base text-black font-body5medium">
      <div className="w-[50px] hidden flex-col items-start justify-start gap-[5px]">
        <img
          className="w-[50px] h-[50px] relative"
          alt=""
          src="/group-163.svg"
        />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start relative gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-soild-color z-[0]" />
          <img
            className="h-0 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <FrameComponent27 />
      <div className="self-stretch h-0.5 relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color hidden" />
      <div className="self-stretch h-px relative bg-line-color" />
      <div className="w-[352px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="flex-1 flex flex-row items-start justify-start gap-[15px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            2024.01.01
          </div>
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            50,000,000,000원
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Component10;
