import { FunctionComponent } from "react";

const Frame65: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[395px] box-border gap-[347px] text-left text-sm text-place-holder font-body5medium">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          차단 목록
        </b>
      </header>
      <div className="w-[181px] relative tracking-[-0.25px] leading-[22px] font-medium inline-block pl-5 pr-5">
        차단한 사용자가 없습니다.
      </div>
    </div>
  );
};

export default Frame65;
