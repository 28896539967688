import { FunctionComponent } from "react";
import TagFrame from "./TagFrame";

const FrameComponent22: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[214px] flex flex-col items-start justify-start gap-[20px] text-left text-sm text-gray font-body5medium">
      <TagFrame
        propAlignSelf="unset"
        propHeight="unset"
        propWidth="188px"
        propPadding="unset"
        propFlex="1"
        propAlignSelf1="stretch"
        propFlex1="1"
      />
      <div className="self-stretch h-1 relative bg-box-color overflow-hidden shrink-0" />
      <div className="flex flex-row items-start justify-start py-0 px-[21px]">
        <b className="relative tracking-[-0.25px] leading-[22px]">댓글0</b>
      </div>
    </section>
  );
};

export default FrameComponent22;
