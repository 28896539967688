import { FunctionComponent } from "react";

const Navhomeprize: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 pr-5 pl-0 text-center text-base text-solid1 font-body5medium">
      <div className="flex-1 flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
        <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
          <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
            10위
          </div>
          <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
            <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
              닉네임
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                팔로워49
              </div>
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                |
              </div>
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                등록개체69
              </div>
            </div>
          </div>
        </div>
        <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
          <b className="relative tracking-[-0.25px] leading-[20px]">팔로잉</b>
        </div>
      </div>
      <img
        className="h-[50px] w-[50px] relative z-[1] ml-[-70px]"
        loading="eager"
        alt=""
        src="/group-163.svg"
      />
    </div>
  );
};

export default Navhomeprize;
