import { FunctionComponent } from "react";
import FrameComponent5 from "../components/FrameComponent5";
import Nav1 from "../components/Nav1";
import { Link } from "react-router-dom";

const ContactDetail: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[93px]">
      <main className="self-stretch flex flex-col items-start justify-start gap-[20px]">
        <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 relative gap-[12px] text-center text-base text-black font-body5medium">
          <Link to="/Contact">
            <img
              className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/chevronleft.svg"
            />
          </Link>
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            나의 문의 상세
          </b>
        </div>
        <section className="self-stretch flex flex-col items-center justify-start text-center text-xs text-solid1 font-body5medium">
          <div className="self-stretch flex flex-col items-start justify-start pt-0 pb-5 gap-[12px]">
            <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[8px]">
              <div className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border">
                <b className="relative tracking-[-0.25px] leading-[20px]">
                  답변완료
                </b>
              </div>
              <div className="flex-1 flex flex-row items-center justify-between min-w-[150px] text-left text-sm text-gray">
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  [배송 | 배송일정]
                </div>
                <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium">
                  2023.01.01
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start text-left text-base text-black">
              <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
                문의 제목이 노출됩니다.
              </b>
            </div>
          </div>
          <textarea
            className="[border:none] bg-box-color min-h-[300px] w-full [outline:none] rounded-lg flex flex-row items-center justify-center p-5 box-border font-body5medium font-medium text-sm text-black"
            placeholder={`- 판매자 닉네임 : 
- 주문 내용 정보 :
- 문의 내용 : 문의 내용이 노출됩니다`}
            rows={15}
            cols={17}
          />
        </section>
        <div className="self-stretch h-px relative bg-line-color" />
        <FrameComponent5 />
      </main>
      
    </div>
  );
};

export default ContactDetail;
