import { FunctionComponent } from "react";
import SubFrameBCategory from "../components/SubFrameBCategory";
import FrameCommunityLogo from "../components/FrameCommunityLogo";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";
import EyeOpenIconFrame1 from "../components/EyeOpenIconFrame1";
import EyeOpenIconFrame from "../components/EyeOpenIconFrame";
import Nav from "../components/Nav";
import CategorySection from "../new/CategorySection";
import LabelRow from "../new/LabelRow";

const Frame2: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[4px] text-left text-base text-black font-body5medium">
      <CategorySection />
      <div className="self-stretch flex flex-col items-start justify-start text-left text-base text-black font-body5medium">
        <div className="flex flex-row items-start justify-start py-0 px-5">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            최근 렙테일 분양 소식
          </b>
        </div>
      </div>
      <LabelRow />
      
      <FrameComponent2 />
      <div className="self-stretch h-5 flex flex-row items-start justify-start pt-0 px-0 pb-4 box-border">
        <div className="self-stretch flex-1 relative bg-box-color" />
      </div>
      <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          커뮤니티 인기 글
        </b>
      </div>
      <section className="my-2 flex flex-row items-start justify-start py-0 pr-0 pl-5 box-border max-w-full overflow-x-auto">
        <div className="shrink-0 flex flex-row items-center justify-start gap-[16px] my-2">
          <FrameComponent1 />
          <EyeOpenIconFrame1 />
          <EyeOpenIconFrame prop="꿀팁공유" />
          <EyeOpenIconFrame prop="수다수다" />
          <EyeOpenIconFrame prop="질문해요" />
          <EyeOpenIconFrame prop="자랑하기" />
        </div>
      </section>
   
    </div>
  );
};

export default Frame2;
