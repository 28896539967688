import { FunctionComponent } from "react";

const CommunityFrame1: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start pt-0 px-5 pb-5 relative gap-[12px] text-left text-base text-black font-body5medium border-b-[1px] border-solid border-line-color">
      <div className="w-[315px] relative tracking-[-0.25px] leading-[24px] font-medium inline-block">
        내가 쓴댓글이 노출됩니다.
      </div>
      <div className="w-5 h-5 absolute my-0 mx-[!important] top-[2px] right-[20px]">
        <img
          className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
        />
        <div className="absolute h-full top-[0px] bottom-[0px] left-[calc(50%_-_2px)] w-1 flex flex-col items-start justify-start gap-[4px] z-[1]">
          <div className="w-full h-1 absolute my-0 mx-[!important] top-[0px] right-[0px] left-[0px] rounded-[50%] bg-black" />
          <div className="w-full h-1 absolute my-0 mx-[!important] top-[calc(50%_-_2px)] right-[0px] left-[0px] rounded-[50%] bg-black" />
          <div className="w-full h-1 absolute my-0 mx-[!important] right-[0px] bottom-[0px] left-[0px] rounded-[50%] bg-black" />
        </div>
      </div>
      <div className="w-[315px] flex flex-col items-start justify-start gap-[4px] text-xs text-light-gray">
        <div className="flex flex-row items-start justify-start gap-[4px]">
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-row items-center justify-start">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                2024.01.01
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start py-0 pr-0.5 pl-0">
            <div className="flex flex-row items-center justify-start">
              <div className="flex flex-row items-center justify-center">
                <div className="flex flex-row items-center justify-start">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    좋아요 1
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch relative tracking-[-0.25px] leading-[20px] font-medium">
          내가 쓴 댓글의 게시물 제목이 노출됩니다.
        </div>
      </div>
    </section>
  );
};

export default CommunityFrame1;
