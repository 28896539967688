import { FunctionComponent } from "react";
import FramerepresentativeName from "../components/FramerepresentativeName";
import FrameComponent17 from "../components/FrameComponent17";
import FooterButtonBar from "../components/FooterButtonBar";
import FrameDataSubmission from "../components/FrameDataSubmission";

const Frame40: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-px box-border gap-[24px]">
      <FramerepresentativeName />
      <FrameComponent17 />
      <FooterButtonBar
        prop="사업자등록번호"
        prop1="사업자등록번호를 입력하세요."
        propPadding="0px 20px"
        propHeight="unset"
      />
      <FrameDataSubmission />
    </div>
  );
};

export default Frame40;
