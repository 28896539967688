import { FunctionComponent } from "react";

const FrameComponent5: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start pt-0 px-5 pb-[30px] gap-[20px_0px] text-center text-base text-black font-body5medium">
      <div className="self-stretch flex flex-row items-center justify-between">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          최근 분양가
        </b>
        <img
          className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
          alt=""
          src="/new/chevronright-1.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px_0px] text-left text-sm text-gray">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[38px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium inline-block min-w-[104px]">
            2024.01.01
          </div>
          <div className="w-[137px] relative tracking-[-0.25px] leading-[22px] font-medium text-right flex items-center shrink-0">
            50,000,000,000원
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[38px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium inline-block min-w-[104px]">
            2024.01.01
          </div>
          <div className="w-[137px] relative tracking-[-0.25px] leading-[22px] font-medium text-right flex items-center shrink-0">
            50,000,000,000원
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[38px]">
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium inline-block min-w-[104px]">
            2024.01.01
          </div>
          <div className="w-[137px] relative tracking-[-0.25px] leading-[22px] font-medium text-right flex items-center shrink-0">
            50,000,000,000원
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent5;
