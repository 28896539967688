import { FunctionComponent } from "react";
import CommulistArea2 from "./CommulistArea2";
import CommunityFrame3 from "./CommunityFrame3";

const TopLevelFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] min-h-[426px]">
      <CommulistArea2
        heartChecked
        xCloseIcon
        propWidth="unset"
        propAlignSelf="stretch"
        propGap="unset"
        propBorderColor="#d7d7d7"
        propAccentColor="#d7d7d7"
      />
      <div className="self-stretch flex flex-col items-end justify-start py-0 px-5">
        <img
          className="w-5 h-5 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/xclose.svg"
        />
        <CommunityFrame3
          prop="자랑하기"
          heartChecked
          propWidth="unset"
          propPadding="unset"
          propAlignSelf="stretch"
          propFlexWrap="wrap"
          propFlex="1"
          propMinWidth="146px"
          propAlignSelf1="stretch"
          propBorderColor="#d7d7d7"
          propAccentColor="#d7d7d7"
        />
      </div>
    </section>
  );
};

export default TopLevelFrame;
