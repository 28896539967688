import { FunctionComponent } from "react";
import FrameComponent3 from "../components/FrameComponent3";
import ContactInfoFrame from "../components/ContactInfoFrame";

const Frame7: FunctionComponent = () => {
  return (
    <div className="relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[182px]">
      <FrameComponent3 />
      <ContactInfoFrame />
    </div>
  );
};

export default Frame7;
