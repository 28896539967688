import { FunctionComponent } from "react";

const BusinessFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[8px] min-h-[302px] text-left text-xs text-light-gray font-body5medium">
      <div className="self-stretch flex flex-col items-start justify-start gap-[13px]">
        <b className="relative text-base tracking-[-0.25px] leading-[24px] text-black">
          판매 수수료 및 부가세 관련 고지 동의
        </b>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch relative tracking-[-0.25px] leading-[20px] font-medium">
            렙테일 판매자의 경우 상품판매 건마다 상품판매 매출액(VAT포함)에 대해
            정해진 수수료율을 적용한 수수료가 부과됩니다. 판매수수료는 변경 될
            수 있어요!
          </div>
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <input
              className="border-white accent-white cursor-pointer m-0 h-[18px] w-[18px] relative overflow-hidden shrink-0"
              checked={true}
              type="radio"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              동의합니다.
            </div>
          </div>
        </div>
        <div className="self-stretch relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일에서 판매한 매출에 대해 부가세가 발생 할 수 있어요. 해당
          부가세납부의 의무는 판매자에게 있으니 정산 내역을 잘 확인해 주세요!
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[4px] text-solid1">
        <input
          className="border-solid1 accent-solid1 cursor-pointer m-0 h-[18px] w-[18px] relative overflow-hidden shrink-0"
          checked={true}
          type="radio"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          동의합니다.
        </div>
      </div>
    </section>
  );
};

export default BusinessFrame;
