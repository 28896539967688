import { FunctionComponent } from "react";
import FrameComponent13 from "./FrameComponent13";

const FrameComponent12: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[170px] flex flex-row items-start justify-start py-0 px-5 box-border text-left text-base text-dark-grey font-body5medium">
      <FrameComponent13 prop="통장 사본 첨부" />
    </section>
  );
};

export default FrameComponent12;
