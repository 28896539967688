import { FunctionComponent } from "react";
import FrameComponent36 from "../components/FrameComponent36";
import FrameComponent35 from "../components/FrameComponent35";
import FrameComponent34 from "../components/FrameComponent34";
import FrameComponent33 from "../components/FrameComponent33";
import FraudDetectionFrame from "../components/FraudDetectionFrame";
import FrameComponent32 from "../components/FrameComponent32";
import Component3 from "../components/Component3";

const Frame71: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[20px] tracking-[normal]">
      <header className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] sticky gap-[12px] top-[0] z-[99] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] whitespace-nowrap z-[1]">
          판매 목록 상세
        </b>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/xclose.svg"
        />
      </header>
      <main className="self-stretch flex flex-col items-center justify-start gap-[16px] max-w-full">
        <FrameComponent36 />
        <FrameComponent35 />
        <div className="self-stretch h-[15px] flex flex-row items-start justify-start pt-0 px-0 pb-3.5 box-border max-w-full">
          <div className="self-stretch flex-1 relative bg-line-color max-w-full" />
        </div>
        <FrameComponent34 />
        <FrameComponent33 />
        <FraudDetectionFrame />
        <div className="self-stretch h-[15px] flex flex-row items-start justify-start pt-0 px-0 pb-3.5 box-border max-w-full">
          <div className="self-stretch flex-1 relative bg-line-color max-w-full" />
        </div>
        <FrameComponent32 />
      </main>
      <Component3 prop="확인" propMarginTop="unset" />
    </div>
  );
};

export default Frame71;
