import { FunctionComponent } from "react";

const AppBar1: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[170px] flex flex-row items-start justify-start py-0 px-5 box-border text-left text-base text-dark-grey font-body5medium">
      <div className="w-[270px] flex flex-col items-start justify-start gap-[12px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          통장 사본 첨부
        </b>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
          <div className="h-[130px] flex-1 relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                <div className="absolute h-[18.46%] w-[18.46%] top-[40.77%] right-[40.77%] bottom-[40.77%] left-[40.77%] flex flex-col items-start justify-start z-[1]">
                  <img
                    className="w-6 h-6 relative"
                    alt=""
                    src="/imageadd.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 rounded-lg flex flex-row items-center justify-center bg-[url('/public/image-1@2x.png')] bg-cover bg-no-repeat bg-[top]">
            <img
              className="h-[130px] w-[130px] relative rounded-lg object-cover hidden min-h-[130px]"
              alt=""
              src="/image-1@2x.png"
            />
            <div className="flex-1 flex flex-row items-center justify-center z-[1]">
              <div className="h-[130px] flex-1 relative">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                    <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                    <div className="absolute h-[33.08%] w-[33.08%] top-[32.62%] right-[32.92%] bottom-[34.31%] left-[34%] hidden" />
                  </div>
                </div>
                <img
                  className="absolute h-[18.46%] w-[18.46%] top-[7.69%] right-[7.69%] bottom-[73.85%] left-[73.85%] max-w-full overflow-hidden max-h-full z-[1]"
                  loading="eager"
                  alt=""
                  src="/x1.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBar1;
