import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const FrameComponent15: FunctionComponent = () => {
  const navigate = useNavigate();

  const onTextFieldBasicButtonInClick = useCallback(() => {
    navigate("/17");
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-3.5">
      <div
        className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[12px] cursor-pointer"
        onClick={onTextFieldBasicButtonInClick}
      >
        <input
          className="w-full [border:none] [outline:none] bg-box-color h-12 flex-1 rounded-lg flex flex-row items-center justify-center p-3 box-border font-body5medium font-medium text-base text-place-holder min-w-[136px]"
          placeholder="주소찾기를 눌러주세요"
          type="text"
        />
        <button className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-start">
          <img
            className="h-12 w-[114px] relative rounded-lg hidden"
            alt=""
            src="/rectangle.svg"
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            주소찾기
          </b>
        </button>
      </div>
    </div>
  );
};

export default FrameComponent15;
