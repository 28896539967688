import { FunctionComponent, useMemo, type CSSProperties } from "react";

type CommunityFrameType = {
  prop?: string;
  heartChecked?: boolean;

  /** Style props */
  propBorderBottom?: CSSProperties["borderBottom"];
  propBorderColor?: CSSProperties["borderColor"];
  propAccentColor?: CSSProperties["accentColor"];
};

const CommunityFrame: FunctionComponent<CommunityFrameType> = ({
  prop,
  heartChecked,
  propBorderBottom,
  propBorderColor,
  propAccentColor,
}) => {
  const commuHOTListAreaStyle: CSSProperties = useMemo(() => {
    return {
      borderBottom: propBorderBottom,
    };
  }, [propBorderBottom]);

  const heartStyle: CSSProperties = useMemo(() => {
    return {
      borderColor: propBorderColor,
      accentColor: propAccentColor,
    };
  }, [propBorderColor, propAccentColor]);

  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2 box-border max-w-full text-left text-xs text-negative-color font-body5medium">
      <div
        className="flex-1 box-border flex flex-row flex-wrap items-center justify-start pt-0 px-5 pb-5 gap-[12px] max-w-full border-b-[1px] border-solid border-line-color"
        style={commuHOTListAreaStyle}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[146px]">
          <div className="flex flex-row items-center justify-start gap-[8px] text-center">
            <div className="h-6 rounded-lg bg-lavenderblush overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border">
              <b className="relative tracking-[-0.25px] leading-[20px]">HOT</b>
            </div>
            <div className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border text-solid1">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                {prop}
              </b>
            </div>
            <b className="relative tracking-[-0.25px] leading-[20px] text-gray">
              닉네임
            </b>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px] text-base text-black">
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
              게시글 제목을 입력하세요
            </div>
            <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-light-gray">
              게시글의 내용이 노출되는 곳입니다 최대...
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[10px] text-place-holder">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <img
                className="h-3 w-3 relative"
                loading="eager"
                alt=""
                src="/eyeopen.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                0
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/messagechatcircle.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                0
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <input
                className="border-lightgray accent-lightgray cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
                checked={heartChecked}
                type="radio"
                style={heartStyle}
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                0
              </div>
            </div>
          </div>
        </div>
        <div className="h-[98px] w-[98px] relative rounded-lg bg-box-color overflow-hidden shrink-0" />
      </div>
    </section>
  );
};

export default CommunityFrame;
