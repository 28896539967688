import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DropdownDefaultLabel from "../components/DropdownDefaultLabel";
import Component2 from "../components/Component2";

const Frame12: FunctionComponent = () => {
  const navigate = useNavigate();

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/13");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[6px] text-left text-base text-place-holder font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1">
        <header className="flex-1 overflow-hidden flex flex-row items-start justify-start py-3 px-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="relative tracking-[-0.25px] leading-[24px] z-[1]">
            문의하기
          </b>
        </header>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-2">
        <div className="flex-1 flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            제목을 입력해 주세요.
          </div>
        </div>
      </div>
      <DropdownDefaultLabel
        prop="구매"
        prop1="소분류 카테고리를 선택하세요"
        onDefaultContainer1Click={onDefaultContainer1Click}
      />
      <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-place-holder font-body5medium">
        <div className="self-stretch h-[400px] rounded-lg box-border flex flex-row items-start justify-start py-5 pr-[50px] pl-4 z-[1] border-[1px] border-solid border-line-color">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            뮨의내용을 입력하세요. (최대 2000자)
          </div>
          <div className="h-[400px] w-[335px] relative rounded-lg box-border hidden border-[1px] border-solid border-line-color" />
        </div>
        <b className="relative tracking-[-0.25px] leading-[24px] text-black">
          사진첨부
        </b>
        <div className="w-[90px] h-[90px] relative">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
            </div>
          </div>
          <img
            className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
            loading="eager"
            alt=""
            src="/imageadd.svg"
          />
        </div>
        <footer className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium font-body5medium text-light-gray text-left">
          이미지는 JPEG, JPG, PNG만 가능하며 최대 3개까지 첨부 가능합니다.
        </footer>
      </section>
      <Component2 prop="작성완료" />
    </div>
  );
};

export default Frame12;
