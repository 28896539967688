import { FunctionComponent, useEffect, useState } from "react";
import Storyverm from "./Storyverm";
import { Link } from "react-router-dom";

const Header: FunctionComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 관리

  useEffect(() => {
    // 페이지 로드 시 토큰 유무를 확인하여 로그인 상태를 업데이트합니다.
    const token = localStorage.getItem('googleAccessToken');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <section className="bg-white z-[100] self-stretch flex flex-row justify-center items-start max-w-full text-center text-xs text-black font-body5medium">
       <header className="fixed w-full max-w-[540px] top-0 self-stretch bg-white overflow-hidden flex items-center justify-between py-2.5 z-50">
        <Link to="/">
        <img
          className="pl-[20px] h-5 w-[70px] relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/layer-1.svg"
        />
        </Link>
        
        <div className="flex flex-row items-start justify-start gap-[16px]">
          <Link to="/search">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            loading="eager"
            alt=""
            src="/searchmd.svg"
          />
          </Link>
          <Link to="/chat">
          <img
            className="h-6 w-6 relative min-h-[24px]"
            loading="eager"
            alt=""
            src="/chating.svg"
          />
          </Link>
          <Link to="/notify/reptail">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            loading="eager"
            alt=""
            src="/bell01.svg"
          />
          </Link>
          {isLoggedIn ? (<Link to="/menu/login">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            loading="eager"
            alt=""
            src="/menu01.svg"
          />
          </Link>
        ) : (
          <Link to="/menu">
          <img
            className="pr-[20px] h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            loading="eager"
            alt=""
            src="/menu01.svg"
          />
          </Link>)}
        </div>
      </header>
    </section>
  );
};

export default Header;
