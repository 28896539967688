import { FunctionComponent } from "react";

const Storyverm: FunctionComponent = () => {
  return (
    <div className="self-stretch overflow-x-auto flex flex-col items-start justify-start py-4 px-5 box-border max-w-[540px] text-center text-xs text-black font-body5medium">
      <div className="flex flex-row items-center justify-start gap-[10px] shrink-0 mq450:flex-wrap">
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[42px] text-solid1">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <b className="self-stretch relative tracking-[-0.25px] leading-[20px]">
            렙테일
          </b>
        </div>
        <div className="flex flex-col items-start justify-start text-solid1">
          <div className="flex flex-col items-start justify-start gap-[4px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
            />
            <b className="relative tracking-[-0.25px] leading-[20px]">
              최근 분양가
            </b>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[4px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="relative tracking-[-0.25px] leading-[20px]">
            최대영역끝...
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[4px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="relative tracking-[-0.25px] leading-[20px]">
            최대영역끝...
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[4px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="relative tracking-[-0.25px] leading-[20px]">
            최대영역끝...
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[42px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="self-stretch h-5 relative tracking-[-0.25px] leading-[20px] inline-block">
            최대영역끝...
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[42px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="self-stretch h-5 relative tracking-[-0.25px] leading-[20px] inline-block">
            최대영역끝...
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[42px]">
          <img
            className="w-16 h-16 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="self-stretch h-5 relative tracking-[-0.25px] leading-[20px] inline-block">
            최대영역끝...
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storyverm;
