import { FunctionComponent } from "react";

const Prizeoff: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[24px] min-h-[137px] max-w-full text-left text-sm text-place-holder font-body5medium">
      <div className="self-stretch h-px relative bg-line-color" />

      <div className="w-full flex flex-row items-start justify-start py-0 px-5 box-border max-w-full">
          <div className="bg-[#eee] flex-1 flex flex-row rounded-lg items-start justify-start py-3 px-4 box-border relative gap-[10px] max-w-full">
            
            <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
              게시글 제목, 내용을 검색하세요.
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              alt=""
              src="/searchmd-1.svg"
            />
          </div>
        </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[35px] pl-5 text-xs text-gray">
        <div className="flex-1 flex flex-row items-start justify-start gap-[8px] mq450:flex-wrap">
          <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                베이비
              </div>
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                alt=""
              />
            </div>
          </div>
          <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                미구분
              </div>
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                alt=""
              />
            </div>
          </div>
          <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                서대문구
              </div>
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                alt=""
              />
            </div>
          </div>
          <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                조회 높은 순
              </div>
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prizeoff;
