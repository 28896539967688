import { FunctionComponent, useMemo, type CSSProperties } from "react";

type InputFieldFrameType = {
  prop?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propColor?: CSSProperties["color"];
};

const InputFieldFrame: FunctionComponent<InputFieldFrameType> = ({
  prop,
  propFlex,
  propColor,
}) => {
  const div9Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
      color: propColor,
    };
  }, [propFlex, propColor]);

  return (
    <section className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] text-left text-base text-dark-grey font-body5medium">
      <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
        은행
      </b>
      <div className="self-stretch flex flex-row items-center justify-start py-0 pr-4 pl-0 text-place-holder">
        <div className="flex-1 flex flex-row items-center justify-center">
          <div className="flex-1 flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <div
              className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
              style={div9Style}
            >
              {prop}
            </div>
          </div>
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 z-[1] ml-[-40px]"
          alt=""
          src="/chevrondown.svg"
        />
      </div>
    </section>
  );
};

export default InputFieldFrame;
