import { FunctionComponent } from "react";

const FrameComponent26: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-5 box-border max-w-full text-center text-sm text-solid1 font-body5medium">
      <div className="w-[787px] flex flex-row items-start justify-start py-0 pr-0.5 pl-0 box-border gap-[7px] max-w-[222%] shrink-0 mq450:flex-wrap">
        <div className="flex-1 flex flex-col items-start justify-start gap-[20px] min-w-[218px] max-w-full text-left text-xs text-light-gray">
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[3px] pl-0 gap-[8px]">
            <button className="cursor-pointer p-[5px] bg-white w-11 rounded-xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[1px] border-solid border-solid1 hover:bg-gainsboro-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumslateblue">
              <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium font-body5medium text-solid1 text-center">
                전체
              </div>
            </button>
            <button className="cursor-pointer p-[5px] bg-solid1 flex-1 rounded-xl overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-solid1 hover:bg-mediumslateblue hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumslateblue">
              <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium font-body5medium text-white text-center">
                크레스티드게코
              </div>
            </button>
            <button className="cursor-pointer p-[5px] bg-white w-20 rounded-xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[1px] border-solid border-solid1 hover:bg-gainsboro-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumslateblue">
              <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium font-body5medium text-solid1 text-center">
                가고일게코
              </div>
            </button>
            <button className="cursor-pointer p-[5px] bg-white w-20 rounded-xl box-border overflow-hidden shrink-0 flex flex-row items-center justify-center border-[1px] border-solid border-solid1 hover:bg-gainsboro-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-mediumslateblue">
              <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium font-body5medium text-solid1 text-center">
                리키에너스
              </div>
            </button>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between gap-[20px]">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              2024.12.25 기준
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px] text-gray">
              <div className="rounded-xl bg-white flex flex-row items-center justify-start py-1.5 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
                <div className="flex flex-col items-start justify-start py-0 px-0">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    분양가 높은 순
                  </div>
                </div>
                <img className="h-3 w-3 relative" alt="" src="/updownup.svg" />
              </div>
              <div className="rounded-xl bg-white flex flex-row items-center justify-start py-1.5 pr-[11px] pl-[9px] gap-[2px] border-[1px] border-solid border-line-color">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  최신순
                </div>
                <img className="h-3 w-3 relative" alt="" src="/updownup.svg" />
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-lg bg-solid2 flex flex-row items-start justify-start py-3 px-5 box-border gap-[22px] max-w-full text-center text-sm text-solid1">
            <div className="h-[68px] w-[335px] relative rounded-lg bg-solid2 hidden max-w-full" />
            <b className="relative tracking-[-0.25px] leading-[22px] z-[1]">
              안내
            </b>
            <input
              className="w-full [border:none] [outline:none] font-medium font-body5medium text-sm bg-[transparent] h-11 flex-1 relative tracking-[-0.25px] leading-[22px] text-dark-grey text-left flex items-center min-w-[151px] z-[1]"
              placeholder="리스트를 클릭하면 최근 일주일 분양가를 확인하실 수 있습니다."
              type="text"
            />
          </div>
        </div>
        <div className="w-[71px] rounded-xl bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-[5px] whitespace-nowrap border-[1px] border-solid border-solid1">
          <div className="w-[51px] relative tracking-[-0.25px] leading-[22px] font-medium flex items-center justify-center">
            차희 게코
          </div>
        </div>
        <div className="h-8 w-[95px] rounded-xl bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-[5px] whitespace-nowrap border-[1px] border-solid border-solid1">
          <div className="self-stretch w-[75px] relative tracking-[-0.25px] leading-[22px] font-medium flex items-center justify-center">
            레오파드 게코
          </div>
        </div>
        <div className="h-8 w-[83px] rounded-xl bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-[5px] whitespace-nowrap border-[1px] border-solid border-solid1">
          <div className="self-stretch w-[63px] relative tracking-[-0.25px] leading-[22px] font-medium flex items-center justify-center">
            토케이 게코
          </div>
        </div>
        <div className="h-8 w-[83px] rounded-xl bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-[5px] whitespace-nowrap border-[1px] border-solid border-solid1">
          <div className="self-stretch w-[63px] relative tracking-[-0.25px] leading-[22px] font-medium flex items-center justify-center">
            펫테일 게코
          </div>
        </div>
        <div className="h-8 w-[83px] rounded-xl bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-[5px] whitespace-nowrap border-[1px] border-solid border-solid1">
          <div className="self-stretch w-[63px] relative tracking-[-0.25px] leading-[22px] font-medium flex items-center justify-center">
            납테일 게코
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent26;
