import { FunctionComponent } from "react";

type ListType = {
  badgeLangkingGold?: string;
  prop?: string;
};

const List: FunctionComponent<ListType> = ({ badgeLangkingGold, prop }) => {
  return (
    <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px] text-center text-base text-solid1 font-body5medium">
      <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
        <div className="w-[34px] flex flex-col items-center justify-start">
          <img
            className="w-4 h-4 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src={badgeLangkingGold}
          />
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
            {prop}
          </div>
        </div>
        <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
            닉네임
          </div>
          <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              팔로워999+
            </div>
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              |
            </div>
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              등록개체999+
            </div>
          </div>
        </div>
      </div>
      <div className="w-[47px] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 text-xs text-place-holder border-[1px] border-solid border-line-color">
        <b className="relative tracking-[-0.25px] leading-[20px]">팔로우</b>
      </div>
    </div>
  );
};

export default List;
