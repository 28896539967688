import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type FrameComponent3Type = {
  /** Style props */
  propHeight?: CSSProperties["height"];
};

const FrameComponent3: FunctionComponent<FrameComponent3Type> = ({
  propHeight,
}) => {
  const frameSectionStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  return (
    <section
      className="self-stretch h-[292px] flex flex-row items-start justify-start pt-0 px-5 pb-[30px] box-border text-center text-base text-black font-body5medium"
      style={frameSectionStyle}
    >
      <div className="self-stretch w-[274px] flex flex-col items-start justify-start gap-[20px_0px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          부모 개체 정보
        </b>
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[0px_14px] text-sm text-dimgray">
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[10px_0px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              부
            </div>
            <div className="self-stretch flex-1 relative rounded-8xs bg-whitesmoke-100" />
            <div className="self-stretch flex flex-col items-start justify-start gap-[2px_0px] text-left text-light-gray">
              <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    크리스티드게코
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    |
                  </div>
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  서울
                </div>
              </div>
              <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
                모프명
              </div>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[10px_0px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              모
            </div>
            <div className="self-stretch flex-1 relative rounded-8xs bg-whitesmoke-100" />
            <div className="self-stretch flex flex-col items-start justify-start gap-[2px_0px] text-left text-light-gray">
              <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    크리스티드게코
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    |
                  </div>
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  서울
                </div>
              </div>
              <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
                모프명
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent3;
