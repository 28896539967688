import { FunctionComponent } from "react";

const FrameComponent34: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[20px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">
        개체 상태 인증 사진
      </b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-sm text-dark-grey">
        <b className="relative tracking-[-0.25px] leading-[22px]">
          판매자 인증 사진
        </b>
        <div className="w-[290px] flex flex-row items-start justify-start gap-[10px]">
          <div className="h-[90px] flex-1 relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
              </div>
            </div>
            <img
              className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="eager"
              alt=""
              src="/imageadd.svg"
            />
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                  <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                </div>
              </div>
              <img
                className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/x.svg"
              />
            </div>
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                  <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                </div>
              </div>
              <img
                className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/x.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
          배송전 개체 상태를 잘 확인할 수 있도록 전체/옆 모습 등 디테일한 사진을
          등록 해주세요.
        </div>
      </div>
    </section>
  );
};

export default FrameComponent34;
