import { FunctionComponent } from "react";

type CommunityFrame2Type = {
  ic24?: string;
};

const CommunityFrame2: FunctionComponent<CommunityFrame2Type> = ({ ic24 }) => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-5 gap-[4px] text-left text-base text-black font-body5medium border-b-[1px] border-solid border-line-color">
      <div className="self-stretch flex flex-row flex-wrap items-start justify-center py-0 px-5">
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[205px]">
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
            내가 쓴댓글이 노출됩니다.
          </div>
          <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
            <div className="flex flex-row items-center justify-center">
              <div className="flex flex-row items-center justify-start">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  2024.01.01
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start py-0 pr-0.5 pl-0">
              <div className="flex flex-row items-center justify-start">
                <div className="flex flex-row items-center justify-center">
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      좋아요 1
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src={ic24}
          />
          <div className="flex flex-col items-center justify-center py-0 px-2 gap-[4px] z-[1]">
            <div className="w-1 h-1 relative rounded-[50%] bg-black" />
            <div className="w-1 h-1 relative rounded-[50%] bg-black" />
            <div className="w-1 h-1 relative rounded-[50%] bg-black" />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-10 pl-5 text-xs text-light-gray">
        <div className="flex-1 relative tracking-[-0.25px] leading-[20px] font-medium">
          내가 쓴 댓글의 게시물 제목이 노출됩니다.
        </div>
      </div>
    </section>
  );
};

export default CommunityFrame2;
