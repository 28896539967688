import { FunctionComponent } from "react";
import FrameComponent16 from "../components/FrameComponent16";
import FrameComponent15 from "../components/FrameComponent15";
import Component3 from "../components/Component3";

const Frame38: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-px box-border gap-[9px]">
      <main className="self-stretch flex flex-col items-center justify-start text-left text-base text-dark-grey font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            판매자 등록하기
          </b>
        </header>
        <div className="self-stretch flex flex-row items-start justify-start">
          <div className="h-1 w-52 relative bg-solid1 z-[1]" />
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[30px]">
          <div className="h-0 flex-1 relative bg-box-color" />
        </div>
        <div className="self-stretch h-11 flex flex-row items-start justify-start py-0 px-[19px] box-border text-black">
          <b className="w-[167px] relative tracking-[-0.25px] leading-[24px] flex items-center pr-5">
            사업자 판매자 정보 입력
          </b>
        </div>
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center shrink-0">
            대표자
          </b>
        </div>
        <FrameComponent16 prop="대표자명을 입력하세요." />
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="relative tracking-[-0.25px] leading-[24px]">상호명</b>
        </div>
        <FrameComponent16
          prop="상호명을 입력하세요."
          propAlignSelf="unset"
          propFlex="unset"
        />
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center shrink-0">
            거래 가능 주소
          </b>
        </div>
        <FrameComponent15 />
        <FrameComponent16
          prop="권역주소"
          propAlignSelf="stretch"
          propFlex="1"
        />
        <FrameComponent16
          prop="상세주소"
          propAlignSelf="stretch"
          propFlex="1"
        />
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            사업자등록번호
          </b>
        </div>
        <FrameComponent16
          prop="사업자등록번호를 입력하세요."
          propAlignSelf="unset"
          propFlex="unset"
        />
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            사업자등록증 첨부
          </b>
        </div>
        <section className="self-stretch flex flex-row items-start justify-start py-0 px-5">
          <div className="h-[130px] w-[130px] relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                <div className="absolute h-[18.46%] w-[18.46%] top-[40.77%] right-[40.77%] bottom-[40.77%] left-[40.77%] flex flex-col items-start justify-start z-[1]">
                  <img
                    className="w-6 h-6 relative"
                    alt=""
                    src="/imageadd.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Component3 prop="다음" propMarginTop="unset" />
    </div>
  );
};

export default Frame38;
