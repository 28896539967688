import { FunctionComponent } from "react";
import Component3 from "./Component3";

const StepFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[2px] text-center text-xs text-white font-body5medium">
      <div className="flex flex-row items-start justify-start py-0 pr-[27px] pl-5">
        <div className="rounded-lg bg-solid1 flex flex-row items-center justify-center py-3 pr-[15px] pl-[19px] relative">
          <div className="h-14 w-[148px] relative rounded-lg bg-solid1 hidden z-[0]" />
          <b className="relative tracking-[-0.25px] leading-[16px] z-[1]">
            <p className="m-0">동의를 해주셔야</p>
            <p className="m-0">다음 단계로 넘어갑니다.</p>
          </b>
          <img
            className="h-2.5 w-[11.3px] absolute my-0 mx-[!important] bottom-[-8px] left-[67.7px] rounded-12xs object-contain z-[1]"
            loading="eager"
            alt=""
            src="/polygon-1.svg"
          />
        </div>
      </div>
      <Component3 prop="다음" propMarginTop="unset" />
    </section>
  );
};

export default StepFrame;
