import React, { FunctionComponent, useCallback, useState, useEffect } from "react";
import MobileHeaderInstance from "../components/MobileHeaderInstance";
import { useNavigate } from "react-router-dom";
import useScript from "../components/UseScript";
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

const Frame16: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 관리

  useEffect(() => {
    // 페이지 로드 시 토큰 유무를 확인하여 로그인 상태를 업데이트합니다.
    const token = localStorage.getItem('googleAccessToken');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      console.log(tokenResponse);
      // 로그인 성공 시 토큰을 저장하고 로그인 상태를 업데이트합니다.
      localStorage.setItem('googleAccessToken', tokenResponse.access_token);
      setIsLoggedIn(true);
      navigate("/nickname/reg");
    },
  });
  
  const onEllipseClusterContainerClick = useCallback(() => {
    navigate("/nickname/reg");
  }, [navigate]);

  const handleLogout = () => {
    // 로그아웃 시 로컬 스토리지에서 토큰을 제거하고 로그인 상태를 업데이트합니다.
    localStorage.removeItem('googleAccessToken');
    setIsLoggedIn(false);
  };

  return (
    <div className="pt-[150px] pb-[100px] max-w-[540px] m-auto w-full relative bg-white overflow-hidden flex flex-col items-center justify-start px-0 box-border gap-[108px] text-center text-xs text-gray font-body5medium">

      <div className="h-[124px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[20px] text-sm text-black">
        <img
          className="w-40 flex-1 relative max-h-full overflow-hidden"
          loading="eager"
          alt=""
          src="/frame-259.svg"
        />
        <div className="flex flex-col items-center justify-start gap-[8px]">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            파충류 입분양부터 용품, 커뮤니티까지!
          </div>
          <b className="relative text-base tracking-[-0.25px] leading-[24px]">
            <span>{`파충류의 모든 것, `}</span>
            <span className="text-solid1">렙테일</span>
          </b>
        </div>
      </div>
     
      <div className="h-[111px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[16px] text-left">
        {isLoggedIn ? ( // 로그인 상태에 따라 다른 UI를 표시합니다.
          <div>
            <button onClick={handleLogout}>로그아웃</button>
          </div>
        ) : (
          <div
            className="flex flex-row items-start justify-start gap-[16px] cursor-pointer"
            onClick={() => login()}
          >
            <img
              className="h-11 w-11 relative rounded-[50%] object-cover min-h-[44px]"
              loading="eager"
              alt=""
              src="/ellipse-49@2x.png"
            />
            <img
              className="h-11 w-11 relative rounded-[50%] object-cover min-h-[44px]"
              loading="eager"
              alt=""
              src="/ellipse-50@2x.png"
            />
            <img
              className="h-11 w-11 relative rounded-[50%] object-cover min-h-[44px]"
              loading="eager"
              alt=""
              src="/ellipse-51@2x.png"
            />
            <img
              className="h-11 w-11 relative rounded-[50%] object-cover min-h-[44px]"
              loading="eager"
              alt=""
              src="/ellipse-52@2x.png"
            />
          </div>
        )}
        <div className="flex flex-row items-start justify-start py-0 pr-6 pl-5">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            <span>{`SNS계정으로 `}</span>
            <span className="text-solid1">간편login</span>
            <span> 하세요.</span>
          </div>
        </div>
      </div>
      <div className="w-[221px] relative tracking-[-0.25px] leading-[20px] font-medium inline-block pl-5 pr-5">
        {`계정 생성을 완료하면 `}
        <span className="[text-decoration:underline]">
          서비스이용약관, 개인정보처리방침
        </span>
        에 동의하게 됩니다.
      </div>
   
    </div>
  );
};

export default Frame16;
