import { FunctionComponent } from "react";
import TopFrame from "../components/TopFrame";
import MainLinkToArticleRCKTra from "../components/MainLinkToArticleRCKTra";
import NavbarInstance from "../components/NavbarInstance";

const Frame3: FunctionComponent = () => {
  return (
    <div className="pt-[50px] pb-[0] max-w-[540px] m-auto w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[40px] tracking-[normal]">
      <TopFrame />
      <MainLinkToArticleRCKTra />
      <NavbarInstance />
    </div>
  );
};

export default Frame3;
