import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const FigmaElementsGroup: FunctionComponent = () => {
  const navigate = useNavigate();

  const onDeepLinkedFramesClick = useCallback(() => {
    navigate("/list/buy");
  }, [navigate]);

  const onDeepLinkedFrames1Click = useCallback(() => {
    // Please sync "채팅목록" to the project'
    navigate("/chat");
  }, [navigate]);

  const onDeepLinkedFrames2Click = useCallback(() => {
    navigate("/list/like");
  }, [navigate]);

  const onDeepLinkedFrames3Click = useCallback(() => {
    navigate("/list/follow");
  }, [navigate]);

  const onDeepLinkedFrames4Click = useCallback(() => {
    // Please sync "정산내역(판매자)" to the project
  }, []);

  return (
    <section className="self-stretch h-[341px] flex flex-col items-start justify-start gap-[10px] max-w-full text-left text-base text-black font-body5medium">
      <div className="w-[157px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
          나의활동
        </b>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
        <div className="self-stretch flex flex-col items-start justify-start max-w-full">
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onDeepLinkedFramesClick}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                구매 내역
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onDeepLinkedFrames1Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                채팅 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onDeepLinkedFrames2Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                찜 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onDeepLinkedFrames3Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                팔로우 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onDeepLinkedFrames4Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                정산 내역
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch h-px relative bg-line-color" />
      </div>
    </section>
  );
};

export default FigmaElementsGroup;
