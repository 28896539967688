import { FunctionComponent } from "react";

const FrameComponent5: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 text-left text-base text-black font-body5medium">
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
        <div className="w-[142px] flex flex-row items-start justify-start">
          <div className="flex-1 flex flex-row items-start justify-start">
            <div className="flex-1 flex flex-col items-start justify-start gap-[6px]">
              <b className="self-stretch relative tracking-[-0.25px] leading-[24px]">
                렙테일
              </b>
              <div className="flex flex-row items-start justify-start text-xs text-light-gray">
                <div className="flex flex-row items-center justify-start">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    2024.01.01
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium text-dark-grey">
          안녕하세요 닉네임님, 불편에 이용을 드려 죄송합니다. 문의주신 내용
          신속히 확인해본 결과 아래의 사유에 의해 배송 일정이 지연 되었다는 점
          안내드립니다.
        </div>
      </div>
    </section>
  );
};

export default FrameComponent5;
