import { FunctionComponent } from "react";

const MobileNav: FunctionComponent = () => {
  return (
    <section className="w-full my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[0px] bg-white box-border overflow-hidden flex flex-row items-start justify-between pt-2.5 px-5 pb-10 gap-[18.75px] z-[1] text-left text-xs text-dark-grey font-body5medium border-t-[1px] border-solid border-line-color">
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/homeoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          홈
        </div>
      </div>
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px] text-solid1">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/reptailon.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일
        </div>
      </div>
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/prizeoff.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            판매자랭킹
          </div>
        </div>
      </div>
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative"
          loading="eager"
          alt=""
          src="/shopoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일샵
        </div>
      </div>
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative"
          loading="eager"
          alt=""
          src="/commuoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          커뮤니티
        </div>
      </div>
    </section>
  );
};

export default MobileNav;
