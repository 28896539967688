import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const AppBarFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-left text-base text-black font-body5medium">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 relative gap-[12px] text-center text-base text-black font-body5medium">
        <Link to="/notice">
          <img
            className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
        </Link>
        <b className="relative tracking-[-0.25px] leading-[24px] z-[1] w-full ">
          공지사항
        </b>
      </div>
      <div className="self-stretch flex flex-col items-center justify-start">
        <div className="self-stretch flex flex-col items-start justify-start pt-0 pb-5 gap-[10px]">
          <div className="flex flex-row items-start justify-start gap-[8px]">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              제목이 노출됩니다.
            </b>
            <div className="h-6 rounded-lg bg-solid1 overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border text-center text-xs text-mintcream">
              <b className="relative tracking-[-0.25px] leading-[20px]">NEW</b>
            </div>
          </div>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-gray">
            2023.01.01
          </div>
        </div>
        <div className="w-full rounded-lg bg-box-color flex flex-row items-center justify-center p-5 box-border text-sm text-dark-grey">
          <div className="h-[340px] relative rounded-lg bg-box-color hidden" />
          <div className="h-[300px] flex-1 relative tracking-[-0.25px] leading-[22px] font-medium inline-block z-[1]">
            <p className="m-0">
              공지사항 텍스트가 입력됩니다공지사항 텍스트가 입력됩니다공지사항
              텍스트가 입력됩니다공지사항 텍스트가 입력됩니다공지사항 텍스트가
              입력됩니다공지사항 텍스트가 입력됩니다공지사항 텍스트가
              입력됩니다공지사항 텍스트가 입력됩니다공지사항 텍스트가
              입력됩니다공지사항 텍스트가 입력됩니다공지사항 텍스트가
              입력됩니다공지사항 텍스트가 입력됩니다공지사항 텍스트가
              입력됩니다  텍스트길이에 따라서 영역 늘어남
            </p>
            <p className="m-0">사진 추가할수도 있게 해야함</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBarFrame;
