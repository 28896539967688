import { FunctionComponent } from "react";

const CategoryRegion: FunctionComponent = () => {
  return (
    <div className="w-[312px] flex flex-col items-start justify-start gap-[12px_0px] text-left text-sm text-dark-grey font-body5medium">
      <div className="w-[270px] flex flex-row items-end justify-start">
        <div className="flex-1 rounded-lg bg-white overflow-hidden flex flex-row items-center justify-center py-5 px-[19px] border-[1px] border-solid border-line-color">
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium">
            <p className="m-0">네 , 안녕하세요! 해당 개체는 ~ 이렇습니다.</p>
            <p className="m-0">
              분양 가격은 ~ 형성 되어 있으며 , 거래방식은 렙테일 안심결제와
              카드결제 모두 가능합니다.
            </p>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-end justify-start gap-[0px_8px] text-center text-xs text-light-gray">
        <div className="h-[270px] flex-1 rounded-lg bg-white box-border overflow-hidden flex flex-row items-center justify-center p-5 border-[1px] border-solid border-line-color">
          <img
            className="h-[270px] w-[270px] relative rounded-lg object-cover"
            alt=""
            src="/new/-20240116--1158-2@2x.png"
          />
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          16:33
        </div>
      </div>
    </div>
  );
};

export default CategoryRegion;
