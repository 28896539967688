import { FunctionComponent } from "react";

const CompleteInputLabel: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[20px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">
        개인 판매자 정보 입력
      </b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-dark-grey">
        <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
          이름
        </b>
        <div className="self-stretch flex flex-row items-center justify-center text-black">
          <div className="flex-1 flex flex-row items-center justify-center p-3 relative">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle-1.svg"
            />
            <div className="flex-1 overflow-hidden flex flex-row items-center justify-between py-0 px-1 z-[1]">
              <div className="flex flex-row items-center justify-start gap-[4px]">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  렙테일
                </div>
                <div className="h-4 w-px relative bg-black" />
              </div>
              <img
                className="h-[18px] w-[18px] relative object-cover"
                loading="eager"
                alt=""
                src="/btn-remove1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteInputLabel;
