import { FunctionComponent } from "react";
import CompletedFrame1 from "../components/CompletedFrame1";
import Component2 from "../components/Component2";

const Frame69: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[40px]">
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[50px]">
        <div className="flex-1 overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] relative gap-[12px] text-center text-base text-black font-body5medium">
          
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            분양 거래 평가하기
          </b>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/xclose.svg"
          />
        </div>
      </section>
      <CompletedFrame1 />
      <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-center text-base text-black font-body5medium">
        <b className="relative tracking-[-0.25px] leading-[24px]">한줄평가</b>
        <textarea
          className="bg-[transparent] h-[250px] w-auto [outline:none] self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-4 font-body5medium font-medium text-base text-place-holder z-[1] border-[1px] border-solid border-whitesmoke-100"
          placeholder="한줄평가를 입력해주세요 (150자 이내)"
          rows={13}
          cols={17}
        />
      </section>
      <Component2 prop="평가하기" />
    </div>
  );
};

export default Frame69;
