import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameComponent16Type = {
  prop?: string;

  /** Style props */
  propAlignSelf?: CSSProperties["alignSelf"];
  propFlex?: CSSProperties["flex"];
};

const FrameComponent16: FunctionComponent<FrameComponent16Type> = ({
  prop,
  propAlignSelf,
  propFlex,
}) => {
  const frameDiv2Style: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const inputDefaultStyle: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  return (
    <div
      className="flex flex-row items-start justify-start pt-0 px-5 pb-6 text-left text-base text-place-holder font-body5medium"
      style={frameDiv2Style}
    >
      <div
        className="flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative"
        style={inputDefaultStyle}
      >
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/rectangle.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
          {prop}
        </div>
      </div>
    </div>
  );
};

export default FrameComponent16;
