import { FunctionComponent } from "react";
import InputFieldFrame from "../components/InputFieldFrame";
import BusinessOwnerNameField from "../components/BusinessOwnerNameField";
import FrameComponent12 from "../components/FrameComponent12";
import FrameComponent11 from "../components/FrameComponent11";
import Component2 from "../components/Component2";

const Frame33: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px] text-left text-base text-black font-body5medium">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          정산계좌 관리
        </b>
      </header>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          정산계좌 등록
        </b>
      </div>
      <InputFieldFrame prop="은행을 선택하세요." />
      <BusinessOwnerNameField />
      <section className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] text-left text-base text-dark-grey font-body5medium">
        <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
          계좌번호
        </b>
        <div className="self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative text-place-holder">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            계좌번호를 -없이 입력하세요.
          </div>
        </div>
      </section>
      <FrameComponent12 />
      <FrameComponent11 />
      <Component2 prop="등록" />
    </div>
  );
};

export default Frame33;
