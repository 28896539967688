import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameComponent4Type = {
  prop?: string;
  prop1?: string;

  /** Style props */
  dividerBorderBottom?: CSSProperties["borderBottom"];
  divBackgroundColor?: CSSProperties["backgroundColor"];
  propColor?: CSSProperties["color"];

  /** Action props */
  onFrameContainerClick?: () => void;
};

const FrameComponent4: FunctionComponent<FrameComponent4Type> = ({
  prop,
  prop1,
  dividerBorderBottom,
  divBackgroundColor,
  propColor,
  onFrameContainerClick,
}) => {
  const frameDivStyle: CSSProperties = useMemo(() => {
    return {
      borderBottom: dividerBorderBottom,
    };
  }, [dividerBorderBottom]);

  const badgeCategoryBadge1Style: CSSProperties = useMemo(() => {
    return {
      backgroundColor: divBackgroundColor,
    };
  }, [divBackgroundColor]);

  const bStyle: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <div
      className="self-stretch flex flex-col items-start justify-start pt-0 pb-5 gap-[12px] cursor-pointer text-center text-xs text-solid1 font-body5medium border-b-[1px] border-solid border-line-color"
      onClick={onFrameContainerClick}
      style={frameDivStyle}
    >
      <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[8px]">
        <div
          className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border"
          style={badgeCategoryBadge1Style}
        >
          <b
            className="relative tracking-[-0.25px] leading-[20px]"
            style={bStyle}
          >
            {prop}
          </b>
        </div>
        <div className="flex-1 flex flex-row items-center justify-between min-w-[150px] text-left text-sm text-gray">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            {prop1}
          </div>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium">
            2023.01.01
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start text-left text-base text-black">
        <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
          문의 제목이 노출됩니다.
        </div>
      </div>
    </div>
  );
};

export default FrameComponent4;
