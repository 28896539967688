import { FunctionComponent } from "react";
import MainLinkToArticleRCKTra from "./MainLinkToArticleRCKTra";


const MainFrame1: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[257px] flex flex-col items-start justify-start gap-[10px] max-w-full text-left text-base text-black font-body5medium">
      
      <div className="self-stretch flex flex-col items-start justify-start max-w-full">
        <MainLinkToArticleRCKTra />
        <div className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full">
          <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
            <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
              설정
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/chevronright.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainFrame1;
