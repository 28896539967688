import { FunctionComponent, useMemo, type CSSProperties } from "react";

type Component3Type = {
  prop?: string;

  /** Style props */
  propMarginTop?: CSSProperties["marginTop"];
};

const Component3: FunctionComponent<Component3Type> = ({
  prop,
  propMarginTop,
}) => {
  const footerStyle: CSSProperties = useMemo(() => {
    return {
      marginTop: propMarginTop,
    };
  }, [propMarginTop]);

  return (
    <div
      className="self-stretch bg-white box-border flex flex-row items-start justify-center pt-3.5 px-3.5 pb-10 max-w-full mt-[-324px] border-t-[1px] border-line-color"
      style={footerStyle}
    >
      <button className="cursor-pointer [border:none] py-3 pr-[21px] pl-5 bg-[transparent] w-[335px] flex flex-row items-center justify-center box-border relative max-w-full">
        <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
        <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
          {prop}
        </b>
      </button>
    </div>
  );
};

export default Component3;
