import { FunctionComponent } from "react";
import DropdownDefaultLabel from "../components/DropdownDefaultLabel";
import Component2 from "../components/Component2";

const Frame13: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[6px] text-left text-base text-place-holder font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1">
        <header className="flex-1 overflow-hidden flex flex-row items-start justify-start py-3 px-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="relative tracking-[-0.25px] leading-[24px] z-[1]">
            문의하기
          </b>
        </header>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-2">
        <div className="flex-1 flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            제목을 입력해 주세요.
          </div>
        </div>
      </div>
      <DropdownDefaultLabel
        prop="신고"
        prop1="상품신고"
        propGap="unset"
        propAlignSelf="stretch"
        propColor="#000"
      />
      <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-black font-body5medium">
        <div className="self-stretch h-[400px] rounded-lg box-border flex flex-row items-start justify-center py-5 pr-[11px] pl-4 z-[1] border-[1px] border-solid border-line-color">
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
            <p className="m-0">문의 내용과 함꼐 다음 정보를 입력해주세요.</p>
            <p className="m-0">{`- 상대방 닉네임 : `}</p>
            <p className="m-0">- 사건 발생 시점 :</p>
            <p className="m-0">- 신고사유 :</p>
          </div>
          <div className="h-[400px] w-[335px] relative rounded-lg box-border hidden border-[1px] border-solid border-line-color" />
        </div>
        <b className="relative tracking-[-0.25px] leading-[24px]">사진첨부</b>
        <div className="w-[290px] flex flex-row items-start justify-start gap-[10px]">
          <div className="h-[90px] flex-1 relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
              </div>
            </div>
            <img
              className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="eager"
              alt=""
              src="/imageadd.svg"
            />
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                  <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                </div>
              </div>
              <img
                className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/x.svg"
              />
            </div>
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                  <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                </div>
              </div>
              <img
                className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/x.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
          이미지는 JPEG, JPG, PNG만 가능하며 최대 3개까지 첨부 가능합니다.
        </div>
      </section>
      <Component2 prop="작성완료" />
    </div>
  );
};

export default Frame13;
