import { FunctionComponent } from "react";
import EllipseGroup from "../components/EllipseGroup";

const Frame61: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-12 box-border gap-[20px] text-left text-base text-black font-body5medium">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          받은 분양 평가
        </b>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          alt=""
          src="/xclose.svg"
        />
      </header>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-[21px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          받은 분양 평가
        </b>
      </div>
      <section className="self-stretch flex flex-col items-start justify-start gap-[16px] text-left text-sm text-black font-body5medium">
        <div className="self-stretch h-10 flex flex-col items-start justify-start py-0 px-5 box-border gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="w-[54px] flex flex-row items-center justify-start gap-[6px]">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">5점</b>
            </div>
            <div className="flex flex-row items-center justify-end py-0 pr-0 pl-4 gap-[6px] text-dark-grey">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/usersprofiles02.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">
                999+명
              </b>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle-background.svg"
          />
        </div>
        <div className="self-stretch h-10 flex flex-col items-start justify-start py-0 px-5 box-border gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="w-[54px] flex flex-row items-center justify-start gap-[6px]">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starnon.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">4점</b>
            </div>
            <div className="flex flex-row items-center justify-end py-0 pr-0 pl-6 gap-[6px] text-dark-grey">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/usersprofiles02.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">
                000명
              </b>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle-background.svg"
          />
        </div>
        <div className="self-stretch h-10 flex flex-col items-start justify-start py-0 px-5 box-border gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="w-[54px] flex flex-row items-center justify-start gap-[6px]">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starnon.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/starnon.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">3점</b>
            </div>
            <div className="flex flex-row items-center justify-end py-0 pr-0 pl-[33px] gap-[6px] text-dark-grey">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/usersprofiles02.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">00명</b>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle-background.svg"
          />
        </div>
        <div className="self-stretch h-10 flex flex-col items-start justify-start py-0 px-5 box-border gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="w-[54px] flex flex-row items-center justify-start gap-[6px]">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starnon.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/starnon.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/starnon.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">2점</b>
            </div>
            <div className="flex flex-row items-center justify-end py-0 pr-0 pl-[33px] gap-[6px] text-dark-grey">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/usersprofiles02.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">00명</b>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle-background.svg"
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="w-[54px] flex flex-row items-center justify-start gap-[6px]">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starfull.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starnon.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                loading="eager"
                alt=""
                src="/starnon.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/starnon.svg"
              />
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/starnon.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">1점</b>
            </div>
            <div className="w-[94px] flex flex-row items-center justify-end gap-[6px] text-dark-grey">
              <img
                className="h-6 w-6 relative min-h-[24px]"
                alt=""
                src="/usersprofiles02.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[22px]">0명</b>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full"
            alt=""
          />
        </div>
      </section>
      <div className="self-stretch h-1 relative bg-box-color" />
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          최신 리뷰 999+개
        </b>
      </div>
      <EllipseGroup />
    </div>
  );
};

export default Frame61;
