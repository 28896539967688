import { FunctionComponent, useMemo, type CSSProperties } from "react";

type AuthenticationInputType = {
  group165Placeholder?: string;
  prop?: string;
  prop1?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propFlex1?: CSSProperties["flex"];

  /** Action props */
  onTextFieldBasicButtonInClick?: () => void;
};

const AuthenticationInput: FunctionComponent<AuthenticationInputType> = ({
  group165Placeholder,
  prop,
  prop1,
  propFlex,
  propFlex1,
  onTextFieldBasicButtonInClick,
}) => {
  const div6Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  const div7Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex1,
    };
  }, [propFlex1]);

  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[13px] text-left text-base text-place-holder font-body5medium">
      <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex text-dark-grey items-center">
        거래 가능 주소
      </b>
      <div
        className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] cursor-pointer"
        onClick={onTextFieldBasicButtonInClick}
      >
        <input
          className="w-full [border:none] [outline:none] bg-box-color h-12 flex-1 rounded-lg flex flex-row items-center justify-center p-3 box-border font-body5medium font-medium text-base text-place-holder min-w-[136px]"
          placeholder={group165Placeholder}
          type="text"
        />
        <button className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-start">
          <img
            className="h-12 w-[114px] relative rounded-lg hidden"
            alt=""
            src="/rectangle.svg"
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            주소찾기
          </b>
        </button>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/rectangle.svg"
        />
        <div
          className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
          style={div6Style}
        >
          {prop}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/rectangle.svg"
        />
        <div
          className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
          style={div7Style}
        >
          {prop1}
        </div>
      </div>
    </div>
  );
};

export default AuthenticationInput;
