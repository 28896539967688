import { FunctionComponent } from "react";
import Header from "../components/Header";
import Prizeoff from "../components/Prizeoff";
import Text1 from "../components/Text1";
import MobilNav from "../components/MobilNav";
import Nav from "../components/Nav";
import SubFrameBCategory from "../components/SubFrameBCategory";
import FrameCommunityLogo from "../components/FrameCommunityLogo";
import FrameComponent2 from "../components/FrameComponent2";
import LabelRow from "../new/LabelRow";


const Frame: FunctionComponent = () => {
  return (
    <div className=" relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[4px] tracking-[normal]">

      <LabelRow />
      <Prizeoff />
      <div className="self-stretch h-auto relative">
        <Text1 />

      </div>
    </div>
  );
};

export default Frame;
