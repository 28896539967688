import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FramephoneauthType = {
  group165Placeholder?: string;
  rectangle?: string;

  /** Style props */
  propBackgroundColor?: CSSProperties["backgroundColor"];
  propBackgroundColor1?: CSSProperties["backgroundColor"];

  /** Action props */
  onBtnClick?: () => void;
};

const Framephoneauth: FunctionComponent<FramephoneauthType> = ({
  group165Placeholder,
  rectangle,
  propBackgroundColor,
  propBackgroundColor1,
  onBtnClick,
}) => {
  const groupInputStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const btnStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-dark-grey font-body5medium">
      <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
        인증번호
      </b>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px]">
        <input
          className="w-full [border:none] [outline:none] bg-box-color h-12 flex-1 rounded-lg flex flex-row items-center justify-center p-3 box-border font-body5medium font-medium text-base text-place-holder min-w-[136px]"
          placeholder={group165Placeholder}
          type="text"
          style={groupInputStyle}
        />
        <button
          className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-start"
          onClick={onBtnClick}
          style={btnStyle}
        >
          <img
            className="h-12 w-[114px] relative rounded-lg hidden"
            alt=""
            src={rectangle}
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            인증요청
          </b>
        </button>
      </div>
    </div>
  );
};

export default Framephoneauth;
