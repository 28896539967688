import { FunctionComponent } from "react";

const FrameGroup: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[10px_0px] text-left text-sm text-black font-body5medium">
      <div className="w-[270px] h-[86px] flex flex-row items-start justify-start gap-[0px_6px]">
        <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
        <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
        <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
      </div>
      <div className="relative tracking-[-0.25px] leading-[22px]">
        <p className="m-0">
          <b className="font-body5medium">닉네임</b>
          <span className="font-medium">{`님이 판매 분양글에 대한 개체 상태 `}</span>
        </p>
        <p className="m-0 font-medium">
          인증사진을 보냈습니다. 지금 확인해보시겠습니까?
        </p>
      </div>
      <div className="w-[310px] flex flex-row items-end justify-start pt-0 px-0 pb-2.5 box-border gap-[0px_6px] text-center text-xs text-light-gray">
        <button className="cursor-pointer [border:none] pt-[11px] pb-3 pr-5 pl-[21px] bg-solid2 flex-1 rounded-lg flex flex-row items-center justify-center">
          <div className="h-[42px] w-[270px] relative rounded-lg bg-solid2 hidden" />
          <b className="relative text-sm tracking-[-0.25px] leading-[22px] font-body5medium text-solid1 text-center z-[1]">
            인증사진 보러가기
          </b>
        </button>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          13:33
        </div>
      </div>
      <div className="self-stretch h-[86px] flex flex-row items-start justify-end py-0 px-px box-border">
        <div className="self-stretch w-[270px] flex flex-row items-start justify-start gap-[0px_6px]">
          <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
          <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
          <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-end">
        <div className="w-[291px] relative tracking-[-0.25px] leading-[22px] flex items-center shrink-0 box-border pl-5">
          <span className="w-full">
            <p className="m-0">
              <b className="font-body5medium">닉네임</b>
              <span className="font-medium">{`님이 판매 분양글에 대한 개체 상태 `}</span>
            </p>
            <p className="m-0 font-medium">
              인증사진을 보냈습니다. 지금 확인해보시겠습니까?
            </p>
          </span>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-px pl-[25px] text-center text-xs text-light-gray">
        <div className="flex-1 flex flex-row flex-wrap items-end justify-start gap-[0px_6px]">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            19:55
          </div>
          <button className="cursor-pointer [border:none] pt-[11px] pb-3 pr-5 pl-[21px] bg-solid2 flex-1 rounded-lg flex flex-row items-center justify-center box-border min-w-[99px]">
            <div className="h-[42px] w-[270px] relative rounded-lg bg-solid2 hidden" />
            <b className="relative text-sm tracking-[-0.25px] leading-[22px] font-body5medium text-solid1 text-center z-[1]">
              인증사진 보러가기
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default FrameGroup;
