import { FunctionComponent } from "react";
import DefaultInputFieldWithLabel from "../components/DefaultInputFieldWithLabel";
import PersonalAccountInput from "../components/PersonalAccountInput";
import Component2 from "../components/Component2";

const Frame5: FunctionComponent = () => {
  return (
    <div className="max-w-[540px] m-auto w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px] tracking-[normal]">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 px-5 sticky gap-[12px] top-[0] z-[99] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="relative tracking-[-0.25px] leading-[24px] z-[1]">
          결제하기
        </b>
      </header>
      <section className="self-stretch flex flex-col items-center justify-start gap-[8px] max-w-full text-left text-base text-black font-body5medium">
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            결제 금액
          </b>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-3 box-border max-w-full text-sm text-gray">
          <div className="flex-1 flex flex-col items-start justify-start gap-[16px] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                결제금액
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                결제수수료
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                배송택배비
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                총 결제액
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch h-3 flex flex-row items-start justify-start pt-0 px-0 pb-3 box-border max-w-full">
          <img
            className="flex-1 relative max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/vector-35.svg"
          />
        </div>
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            구매자 정보
          </b>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between py-0 px-5 box-border min-h-[72px] gap-[20px] text-sm text-gray mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-[16px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              구매자
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              휴대폰번호
            </div>
          </div>
          <div className="w-[180px] flex flex-col items-start justify-start gap-[16px] text-right text-black">
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              성함
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              010-0000-0000
            </div>
          </div>
        </div>
        <div className="self-stretch h-3 flex flex-row items-start justify-start pt-0 px-0 pb-3 box-border max-w-full">
          <img
            className="flex-1 relative max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/vector-35.svg"
          />
        </div>
        <div className="self-stretch h-9 flex flex-row items-start justify-start py-0 px-5 box-border">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              배송 방법 선택
            </b>
            <img
              className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-1 box-border max-w-full text-place-holder">
          <div className="flex-1 flex flex-row items-start justify-start py-3 px-4 box-border relative gap-[10px] max-w-full">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              배송 방법을 선택하세요
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
        <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray pl-5 pr-5">
          배송 과정에서의 사고 문제는 입/분양자 분들 및 배송업체(배송업체 배송
          선택시)에게 있으며 렙테일에서는 책임질 수 없어요!
        </div>
        <div className="self-stretch h-8 flex flex-row items-start justify-start py-0 px-5 box-border text-xs text-light-gray">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <img
              className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/btncircleoff.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              동의합니다.
            </div>
          </div>
        </div>
        <div className="self-stretch h-3 flex flex-row items-start justify-start pt-0 px-0 pb-3 box-border max-w-full">
          <img
            className="flex-1 relative max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/vector-35.svg"
          />
        </div>
        <DefaultInputFieldWithLabel />
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[52px] pl-5 text-sm text-dark-grey">
          <div className="flex-1 flex flex-row items-start justify-start gap-[20px] mq450:flex-wrap">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              입금계좌
            </div>
            <b className="relative tracking-[-0.25px] leading-[22px] text-right">
              국민은행 000000-0000000 (주)렙테일
            </b>
          </div>
        </div>
      </section>
      <PersonalAccountInput />
      <Component2 prop="결제하기" />
    </div>
  );
};

export default Frame5;
