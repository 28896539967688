import { FunctionComponent } from "react";

const GroupComponent1: FunctionComponent = () => {
  return (
    <section className="self-stretch bg-white flex flex-row flex-wrap items-start justify-start pt-3.5 pb-10 pr-5 pl-[21px] gap-[12px] border-t-[1px] border-solid border-line-color">
      <div className="h-[102px] w-[375px] relative bg-white box-border hidden border-t-[1px] border-solid border-line-color" />
      <div className="flex-1 rounded-lg bg-box-color flex flex-row items-start justify-start py-3 pr-4 pl-2.5 gap-[10px] z-[1]">
        <img
          className="h-12 w-[234px] relative rounded-lg hidden"
          alt=""
          src="/new/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative min-h-[24px] z-[2]"
          alt=""
          src="/new/camera01.svg"
        />
        <input
          className="w-[calc(100%_-_50px)] [border:none] [outline:none] font-medium font-body5medium text-base bg-[transparent] h-6 flex-1 relative tracking-[-0.25px] leading-[24px] text-place-holder text-left inline-block min-w-[104px] z-[1]"
          placeholder="댓글을 입력하세요."
          type="text"
        />
      </div>
      <button className="cursor-pointer [border:none] p-3 bg-solid1 w-[88px] rounded-lg flex flex-row items-center justify-center box-border z-[1]">
        <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center">
          입력
        </b>
      </button>
    </section>
  );
};

export default GroupComponent1;
