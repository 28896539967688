import { FunctionComponent } from "react";
import FrameComponent25 from "../components/FrameComponent25";
import FrameCommunityAlert1 from "../components/FrameCommunityAlert1";
import FrameCommunityAlert from "../components/FrameCommunityAlert";
import UnreadCommentFrame from "../components/UnreadCommentFrame";
import FrameComponent24 from "../components/FrameComponent24";
import CompletedFrame from "../components/CompletedFrame";

const Frame55: FunctionComponent = () => {
  
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[228px] box-border gap-[16px]">
     
      <FrameComponent25 prop="편집" />
      <FrameCommunityAlert1 />
      <FrameCommunityAlert />
      <UnreadCommentFrame />
      <FrameComponent24
        prop="님이 대댓글을 달았습니다."
        prop1="10시간 전"
        xClose="pending_3:21436"
      />
      <CompletedFrame
        prop="닉네임"
        prop1="님이 댓글에 좋아요를 눌렀습니다."
        prop2="10시간 전"
        xClose="pending_3:21446"
        propFlex="unset"
        propAlignSelf="stretch"
        propAlignSelf1="unset"
      />
    </div>
  );
};

export default Frame55;
