import { FunctionComponent } from "react";

const FrameComponent31: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 px-0 pb-5 gap-[0px_12px] text-left text-sm text-black font-body5medium border-b-[1px] border-solid border-line-color">
      <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px] min-w-[146px]">
        <div className="flex flex-row items-start justify-start gap-[0px_4px] text-light-gray">
          <div className="flex flex-row items-start justify-start gap-[0px_4px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              카테고리
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              |
            </div>
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            지역
          </div>
        </div>
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          상품명을 입력하세요상품명을 입력하세요
        </div>
        <b className="w-40 relative text-base tracking-[-0.25px] leading-[24px] flex items-center">
          200,000원
        </b>
        <div className="flex flex-row items-start justify-start gap-[0px_8px] text-xs text-place-holder">
          <div className="flex flex-row items-center justify-start gap-[0px_2px]">
            <img
              className="h-3 w-3 relative"
              loading="eager"
              alt=""
              src="/new/eyeopen.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              999+
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[0px_2px]">
            <input
              className="border-lightgray accent-lightgray cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
              checked={true}
              type="radio"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              999+
            </div>
          </div>
        </div>
      </div>
      <img
        className="h-[98px] w-[98px] relative rounded-lg overflow-hidden shrink-0"
        loading="eager"
        alt=""
        src="/new/frame-267.svg"
      />
    </div>
  );
};

export default FrameComponent31;
