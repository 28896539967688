import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const FrameComponent3: FunctionComponent = () => {
  const navigate = useNavigate();

  const onFrameContainerClick = useCallback(() => {
    navigate("/8");
  }, [navigate]);

  const onFrameContainer1Click = useCallback(() => {
    // Please sync "차단목록" to the project
  }, []);

  const onFrameContainer3Click = useCallback(() => {
    // Please sync "회원탈퇴" to the project
  }, []);

  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-left text-base text-black font-body5medium">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 px-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="relative tracking-[-0.25px] leading-[24px] z-[1]">설정</b>
      </header>
      <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
        <div className="w-[159px] flex flex-row items-start justify-start py-0 px-[21px] box-border">
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
            설정
          </b>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start">
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 cursor-pointer"
            onClick={onFrameContainerClick}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 gap-[30px]">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                휴대폰 정보 관리
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 cursor-pointer"
            onClick={onFrameContainer1Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 gap-[30px]">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                차단 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-3.5 px-5">
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 gap-[30px]">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                로그아웃
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 cursor-pointer"
            onClick={onFrameContainer3Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 gap-[30px]">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                계정 탈퇴
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent3;
