import { FunctionComponent, useMemo, type CSSProperties } from "react";

type CommunityFrame3Type = {
  prop?: string;
  heartChecked?: boolean;

  /** Style props */
  propWidth?: CSSProperties["width"];
  propPadding?: CSSProperties["padding"];
  propAlignSelf?: CSSProperties["alignSelf"];
  propFlexWrap?: CSSProperties["flexWrap"];
  propFlex?: CSSProperties["flex"];
  propMinWidth?: CSSProperties["minWidth"];
  propAlignSelf1?: CSSProperties["alignSelf"];
  propBorderColor?: CSSProperties["borderColor"];
  propAccentColor?: CSSProperties["accentColor"];
};

const CommunityFrame3: FunctionComponent<CommunityFrame3Type> = ({
  prop,
  heartChecked,
  propWidth,
  propPadding,
  propAlignSelf,
  propFlexWrap,
  propFlex,
  propMinWidth,
  propAlignSelf1,
  propBorderColor,
  propAccentColor,
}) => {
  const communityFrameStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
      padding: propPadding,
      alignSelf: propAlignSelf,
      flexWrap: propFlexWrap,
    };
  }, [propWidth, propPadding, propAlignSelf, propFlexWrap]);

  const completedFrameStyle: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propFlex, propMinWidth]);

  const completedBadgeStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
    };
  }, [propAlignSelf1]);

  const heart3Style: CSSProperties = useMemo(() => {
    return {
      borderColor: propBorderColor,
      accentColor: propAccentColor,
    };
  }, [propBorderColor, propAccentColor]);

  return (
    <div
      className="w-[375px] flex flex-row items-center justify-start py-0 px-5 box-border gap-[12px] text-left text-xs text-solid1 font-body5medium"
      style={communityFrameStyle}
    >
      <div
        className="flex flex-col items-start justify-start gap-[8px]"
        style={completedFrameStyle}
      >
        <div className="flex flex-row items-center justify-start gap-[8px] text-center">
          <div className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border">
            <b className="relative tracking-[-0.25px] leading-[20px]">{prop}</b>
          </div>
          <b className="relative tracking-[-0.25px] leading-[20px] text-gray">
            닉네임
          </b>
        </div>
        <div
          className="flex flex-col items-start justify-start gap-[6px] text-base text-black"
          style={completedBadgeStyle}
        >
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
            게시글 제목을 입력하세요
          </div>
          <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-light-gray">
            게시글의 내용이 노출되는 곳입니다 최대...
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[10px] text-place-holder">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              0
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/messagechatcircle.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              0
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <input
              className="cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
              type="radio"
              checked={heartChecked}
              style={heart3Style}
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              0
            </div>
          </div>
        </div>
      </div>
      <div className="h-[98px] w-[98px] relative rounded-lg bg-box-color overflow-hidden shrink-0" />
    </div>
  );
};

export default CommunityFrame3;
