import { FunctionComponent } from "react";
import FrameCommunications1 from "../components/FrameCommunications1";
import FrameCommunications from "../components/FrameCommunications";
import FrameAuthenticationInput from "../components/FrameAuthenticationInput";
import Component3 from "../components/Component3";

const Frame25: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px]">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          휴대폰 정보 관리
        </b>
      </header>
      <main className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[24px] min-h-[742px]">
        <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-base text-black font-body5medium">
          <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
            이름
          </b>
          <div className="self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative text-place-holder">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              이름을 입력해 주세요.
            </div>
          </div>
        </section>
        <FrameCommunications1 />
        <FrameCommunications
          prop="휴대폰 번호"
          prop1="휴대폰 번호를 입력해주세요."
        />
        <FrameAuthenticationInput />
      </main>
      <Component3 prop="완료" propMarginTop="unset" />
    </div>
  );
};

export default Frame25;
