import { FunctionComponent } from "react";

const LabelRow: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start text-left text-base text-black font-body5medium">
      
      <div className="self-stretch overflow-x-auto flex flex-col items-start justify-start py-4 px-5 text-center text-xs">
        <div className="w-[582px] flex flex-row items-center justify-start gap-[0px_10px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px] min-w-[42px] text-solid1">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/new/frame-80.svg"
            />
            <b className="self-stretch relative tracking-[-0.25px] leading-[20px]">
              렙테일
            </b>
          </div>
          <div className="flex flex-col items-start justify-start text-solid1">
            <div className="flex flex-col items-start justify-start gap-[4px_0px]">
              <img
                className="w-16 h-16 relative overflow-hidden shrink-0"
                alt=""
                src="/new/frame-80.svg"
              />
              <b className="relative tracking-[-0.25px] leading-[20px]">
                최근 분양가
              </b>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[4px_0px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/new/frame-82.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px]">
              최대영역끝...
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[4px_0px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/new/frame-82.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px]">
              최대영역끝...
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[4px_0px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              alt=""
              src="/new/frame-82.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px]">
              최대영역끝...
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px] min-w-[42px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              alt=""
              src="/new/frame-82.svg"
            />
            <div className="self-stretch h-5 relative tracking-[-0.25px] leading-[20px] inline-block">
              최대영역끝...
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px] min-w-[42px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              alt=""
              src="/new/frame-82.svg"
            />
            <div className="self-stretch h-5 relative tracking-[-0.25px] leading-[20px] inline-block">
              최대영역끝...
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px] min-w-[42px]">
            <img
              className="w-16 h-16 relative overflow-hidden shrink-0"
              alt=""
              src="/new/frame-82.svg"
            />
            <div className="self-stretch h-5 relative tracking-[-0.25px] leading-[20px] inline-block">
              최대영역끝...
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LabelRow;
