import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const ReptailShop: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[200px_0px]">
      
        <section className="self-stretch h-[514px] flex flex-col items-center justify-start gap-[20px_0px] text-center text-9xl text-white font-body5medium">
        
          <div className="flex-1 rounded-xl bg-solid1 overflow-hidden flex flex-col items-center justify-start py-[50px] pr-[81px] pl-[76px] relative">
            <div className="w-[383px] h-[298px] absolute my-0 mx-[!important] right-[-29px] bottom-[-11px]">
              <div className="absolute top-[10px] left-[0px] w-[383px] h-72 flex items-center justify-center">
                <img
                  className="w-full h-full object-contain absolute left-[0px] top-[13px] [transform:scale(1.087)]"
                  alt=""
                  src="/new/m003t0550-e-online-shopping-03aug22-1@2x.png"
                />
              </div>
              <img
                className="absolute top-[43.9px] left-[247.7px] w-5 h-[26.4px] object-contain z-[1]"
                loading="eager"
                alt=""
                src="/new/-184x8-1@2x.png"
              />
              <img
                className="absolute top-[74px] left-[79px] w-[16.2px] h-[10.2px] object-contain z-[1]"
                loading="eager"
                alt=""
                src="/new/-184x8-2@2x.png"
              />
              <img
                className="absolute top-[0px] left-[38px] w-[38px] h-[39.1px] object-contain z-[1]"
                loading="eager"
                alt=""
                src="/new/-144x8-1@2x.png"
              />
              <img
                className="absolute top-[96px] left-[283px] w-[38.2px] h-[37.9px] object-contain z-[1]"
                loading="eager"
                alt=""
                src="/new/-144x8-2@2x.png"
              />
              <img
                className="absolute top-[98px] left-[252px] w-[5px] h-[5px] object-cover z-[1]"
                loading="eager"
                alt=""
                src="/new/-94x8-1@2x.png"
              />
              <img
                className="absolute top-[118px] left-[110px] w-2 h-2 object-cover z-[1]"
                loading="eager"
                alt=""
                src="/new/-84x8-1@2x.png"
              />
              <img
                className="absolute top-[164px] left-[245px] w-[7px] h-[7px] object-cover z-[1]"
                loading="eager"
                alt=""
                src="/new/-84x8-2@2x.png"
              />
              <img
                className="absolute top-[66px] left-[128px] w-2 h-2 object-cover z-[1]"
                loading="eager"
                alt=""
                src="/new/-44x8-1@2x.png"
              />
              <img
                className="absolute top-[20px] left-[311px] w-[21px] h-[22px] object-cover z-[1]"
                loading="eager"
                alt=""
                src="/new/-44x8-2@2x.png"
              />
              <img
                className="absolute top-[109px] left-[51px] w-[17px] h-[18px] object-cover z-[1]"
                loading="eager"
                alt=""
                src="/new/-34x8-1@2x.png"
              />
            </div>
            <div className="flex flex-col items-center justify-center relative gap-[8px_0px] z-[1]">
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row items-center justify-start py-0 px-5">
                  <h1 className="m-0 relative text-inherit tracking-[-0.25px] leading-[38px] font-bold font-inherit">{`렙테일샵 `}</h1>
                </div>
                <Link className="no-underline text-[#fff]" to="https://www.cafe24.com/" target="_blank" rel="noopener noreferrer">
                  <h1 className="m-0 relative text-inherit tracking-[-0.25px] leading-[38px] font-bold font-inherit">
                    메인 바로가기
                  </h1>
                </Link>
              </div>
              <div className="relative text-base tracking-[-0.25px] leading-[24px]">
                <p className="m-0">
                  <b className="font-body5medium">귀뚜라미, 먹이</b>
                  <span className="font-medium font-body5medium">{`부터 `}</span>
                  <b className="font-body5medium">용품</b>
                  <span className="font-medium">{`까지 `}</span>
                </p>
                <p className="m-0 font-medium">다양한 상품들이 있어요!</p>
                <p className="m-0">
                  <b>렙테일 계정과 연동 시 할인!</b>
                </p>
              </div>
              <img
                className="w-[30px] h-[30px] absolute my-0 mx-[!important] top-[42px] right-[-19px] overflow-hidden shrink-0 z-[2]"
                loading="eager"
                alt=""
                src="/new/chevronright1.svg"
              />
            </div>
          </div>

        </section>
      
    </div>
  );
};

export default ReptailShop;
