import { FunctionComponent } from "react";
import CircleText from "./CircleText";
import CategoryRegion from "./CategoryRegion";

const InfoFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start pt-0 px-5 pb-2.5 gap-[20px_0px]">
      <CircleText
        circleLabelPlaceholder={`안녕하세요! 해당 개체 분양받고싶어요.
개체에 관해 좀더 자세히 설명 들을 수 있을까요?`}
        propFlexWrap="wrap"
        propMinWidth="175px"
      />
      <CategoryRegion />
    </section>
  );
};

export default InfoFrame;
