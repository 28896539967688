import { FunctionComponent, useMemo, type CSSProperties } from "react";

type Component5Type = {
  prop?: string;

  /** Style props */
  propMinWidth?: CSSProperties["minWidth"];
  propFlex?: CSSProperties["flex"];
};

const Component5: FunctionComponent<Component5Type> = ({
  prop,
  propMinWidth,
  propFlex,
}) => {
  const groupDivStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const div10Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  return (
    <section className="self-stretch flex flex-row items-center justify-center text-left text-base text-place-holder font-body5medium">
      <div className="flex-1 flex flex-row items-start justify-center pt-3.5 pb-10 pr-5 pl-[21px] relative">
        <div className="w-full my-0 mx-[!important] absolute h-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-white box-border overflow-hidden border-t-[1px] border-solid border-line-color" />
        <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[12px] z-[1]">
          <div
            className="bg-[#eee] rounded-lg flex-1 flex flex-row items-center justify-start py-3 px-[17px] box-border relative min-w-[66px]"
            style={groupDivStyle}
          >
            
            <div
              className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
              style={div10Style}
            >
              {prop}
            </div>
          </div>
          <button className="cursor-pointer [border:none] p-3 bg-solid1 w-[88px] rounded-lg flex flex-row items-center justify-center box-border">
            <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center">
              입력
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Component5;
