import { FunctionComponent, useMemo, type CSSProperties } from "react";

type CompletedFrameType = {
  prop?: string;
  prop1?: string;
  prop2?: string;
  xClose?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propAlignSelf?: CSSProperties["alignSelf"];
  propAlignSelf1?: CSSProperties["alignSelf"];
};

const CompletedFrame: FunctionComponent<CompletedFrameType> = ({
  prop,
  prop1,
  prop2,
  xClose,
  propFlex,
  propAlignSelf,
  propAlignSelf1,
}) => {
  const completedFrame1Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
      alignSelf: propAlignSelf,
    };
  }, [propFlex, propAlignSelf]);

  const linkArticleContainerStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf1,
    };
  }, [propAlignSelf1]);

  return (
    <div
      className="flex-1 flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-5 gap-[10px] text-left text-xs text-light-gray font-body5medium border-b-[1px] border-solid border-line-color"
      style={completedFrame1Style}
    >
      <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
      <div className="flex-1 flex flex-row items-start justify-start gap-[9px] min-w-[183px] text-base text-dark-grey">
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
          <div
            className="self-stretch relative tracking-[-0.25px] leading-[24px]"
            style={linkArticleContainerStyle}
          >
            <b>{prop}</b>
            <span className="font-medium">{prop1}</span>
          </div>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            {prop2}
          </div>
        </div>
        <img
          className="h-[22px] w-[22px] relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src={xClose}
        />
      </div>
      <div className="w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center z-[2]">
        |
      </div>
      <div className="w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center z-[3]">
        등록개체999+
      </div>
    </div>
  );
};

export default CompletedFrame;
