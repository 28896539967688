import { FunctionComponent } from "react";

const Frame74: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-60 box-border gap-[14px] text-left text-base text-dark-grey font-body5medium">
      <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-left text-base text-dark-grey font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            팔로우 목록
          </b>
        </header>
        <div className="self-stretch flex flex-col items-start justify-start gap-[14px]">
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워999+
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
        </div>
      </section>
      <div className="self-stretch flex flex-row items-center justify-between pt-0 px-5 pb-3.5">
        <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
          <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
              닉네임
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                팔로워999+
              </div>
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                |
              </div>
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                등록개체999+
              </div>
            </div>
          </div>
        </div>
        <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-center text-xs text-white">
          <b className="relative tracking-[-0.25px] leading-[20px]">팔로잉</b>
        </div>
      </div>
    </div>
  );
};

export default Frame74;
