import { FunctionComponent } from "react";

const Mobilenumberinputframe: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-dark-grey font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">휴대폰 번호</b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-place-holder">
        <div className="self-stretch flex flex-row items-center justify-center">
          <div className="flex-1 flex flex-row items-center justify-start py-3 px-4 relative">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle-4.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              01012345678
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">{`휴대폰 번호는 ‘메뉴 > 설정 > 휴대폰 정보 관리’에서 변경해 주세요.`}</div>
      </div>
    </div>
  );
};

export default Mobilenumberinputframe;
