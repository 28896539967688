import { FunctionComponent } from "react";
import AppBarFrame from "../components/AppBarFrame";
import Nav1 from "../components/Nav1";

const NoticeDetail: FunctionComponent = () => {
  return (
    <div className="relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[232px]">
      <AppBarFrame />
    </div>
  );
};

export default NoticeDetail;
