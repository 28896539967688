import { FunctionComponent } from "react";

const Component1: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-left text-sm text-black font-body5medium">
      <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[4px] text-light-gray">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          카테고리
        </div>
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          |
        </div>
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          지역
        </div>
      </div>
      <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
        상품명을 입력하세요
      </div>
      <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px]">
        200,000원
      </b>
      <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[8px] text-xs text-place-holder">
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <img className="h-3 w-3 relative" loading="eager" alt="" />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            999+
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <input
            className="border-lightgray accent-lightgray cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
            checked={true}
            type="radio"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            999+
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component1;
