import { FunctionComponent } from "react";

const Completedbadge1: FunctionComponent = () => {
  return (
    <section className="self-stretch h-28 flex flex-row items-start justify-start py-0 pr-0 pl-px box-border text-left text-sm text-black font-body5medium">
      <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[24px_0px]">
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[21px] pl-[19px]">
          <div className="bg-[#eee] rounded-lg flex-1 flex flex-row items-start justify-between py-3 px-4 relative">
            
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
              검색명
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/new/searchmd1.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-end justify-start py-0 pl-0 text-center text-base text-dark-grey">
          
          <div className="self-stretch w-full flex flex-col items-center justify-end z-[1] border-b-[0.5px] border-solid border-[#ccc]">
            <div className="self-stretch h-[34px] flex flex-row items-start justify-center gap-[0px_20px]">
              <div className="self-stretch flex flex-col items-center justify-start py-0 px-2.5 text-solid1 border-b-[2px] border-solid border-solid1">
                <b className="relative tracking-[-0.25px] leading-[24px]">
                  전체
                </b>
              </div>
              <div className="flex flex-col items-center justify-start py-0 px-2.5">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  렙테일
                </div>
              </div>
              <div className="flex flex-col items-center justify-start py-0 px-2.5">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  판매자
                </div>
              </div>
              <div className="flex flex-col items-center justify-center py-0 px-2.5">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  커뮤니티
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Completedbadge1;
