import { FunctionComponent } from "react";

const Frame37: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[378px] box-border gap-[242px]">
      <div className="self-stretch h-1 relative bg-box-color hidden" />
      <header className="self-stretch flex flex-col items-start justify-start text-center text-base text-black font-body5medium">
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center py-3 pr-14 pl-[55px] relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px] z-[1]"
            alt=""
            src="/xclose.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            등록 완료
          </b>
        </div>
        <div className="self-stretch h-1 relative bg-solid1 z-[1]" />
      </header>
      <section className="flex flex-col items-center justify-start py-0 px-5 gap-[20px] text-center text-base text-dark-grey font-body5medium">
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
          <p className="m-0">사업자 판매 신청이 완료되었습니다.</p>
          <p className="m-0">승인을 위해 1-2일 소요됩니다.</p>
          <p className="m-0">승인이 완료되면 정산계좌를 꼭 입력해주세요!</p>
        </div>
        <button className="cursor-pointer [border:none] p-3 bg-[transparent] w-[238px] flex flex-row items-center justify-center box-border relative">
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid2" />
          <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-solid1 text-center z-[1]">
            홈으로
          </b>
        </button>
      </section>
    </div>
  );
};

export default Frame37;
