import { FunctionComponent } from "react";
import FrameComponent21 from "../components/FrameComponent21";
import ContentFrame from "../components/ContentFrame";
import FrameComponent20 from "../components/FrameComponent20";
import PostTitleInput from "../components/PostTitleInput";
import Component5 from "../components/Component5";

const Frame45: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[60px]">
  
      <FrameComponent21 />
      <main className="self-stretch flex flex-col items-center justify-start gap-[12px]">
        <ContentFrame />
        <section className="self-stretch flex flex-col items-start justify-start py-0 pr-5 pl-[19px] gap-[12px] text-left text-xs text-solid1 font-body5medium">
          <FrameComponent20 />
          <PostTitleInput />
          <div className="flex flex-row items-start justify-start py-0 px-3.5">
            <div className="flex flex-row items-start justify-start gap-[10px]">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                좋아요1
              </div>
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-gray">
                대댓글쓰기
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-3.5 text-gray">
            <div className="flex flex-row items-start justify-start gap-[6px]">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                댓글 2개 보기
              </b>
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 min-h-[20px]"
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div>
        </section>
        <Component5
          prop="댓글을 입력하세요."
          propMinWidth="152px"
          propFlex="1"
        />
      </main>
    </div>
  );
};

export default Frame45;
