import { FunctionComponent, useMemo, type CSSProperties } from "react";

type DropdownDefaultLabelType = {
  prop?: string;
  prop1?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
  propAlignSelf?: CSSProperties["alignSelf"];
  propColor?: CSSProperties["color"];

  /** Action props */
  onDefaultContainer1Click?: () => void;
};

const DropdownDefaultLabel: FunctionComponent<DropdownDefaultLabelType> = ({
  prop,
  prop1,
  propGap,
  propAlignSelf,
  propColor,
  onDefaultContainer1Click,
}) => {
  const defaultStyle: CSSProperties = useMemo(() => {
    return {
      gap: propGap,
      alignSelf: propAlignSelf,
    };
  }, [propGap, propAlignSelf]);

  const div3Style: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <section className="self-stretch h-[118px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[14px] text-left text-base text-black font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-between py-3 px-4 relative">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
          {prop}
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          alt=""
          src="/chevrondown.svg"
        />
      </div>
      <div
        className="flex flex-row items-start justify-start py-3 px-4 relative gap-[10px] cursor-pointer text-place-holder"
        onClick={onDefaultContainer1Click}
        style={defaultStyle}
      >
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <div
          className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
          style={div3Style}
        >
          {prop1}
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          alt=""
          src="/chevrondown.svg"
        />
      </div>
    </section>
  );
};

export default DropdownDefaultLabel;
