import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type CircleTextType = {
  circleLabelPlaceholder?: string;

  /** Style props */
  propFlexWrap?: CSSProperties["flexWrap"];
  propMinWidth?: CSSProperties["minWidth"];
};

const CircleText: FunctionComponent<CircleTextType> = ({
  circleLabelPlaceholder,
  propFlexWrap,
  propMinWidth,
}) => {
  const frameWithCategoriesStyle: CSSProperties = useMemo(() => {
    return {
      flexWrap: propFlexWrap,
    };
  }, [propFlexWrap]);

  const circleLabelStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[23px] text-center text-xs text-light-gray font-body5medium">
      <div
        className="flex-1 flex flex-row items-end justify-start gap-[0px_8px]"
        style={frameWithCategoriesStyle}
      >
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          16:32
        </div>
        <textarea
          className="[border:none] bg-box-color h-[106px] w-auto [outline:none] flex-1 rounded-lg overflow-hidden flex flex-row items-center justify-center p-5 box-border font-body5medium font-medium text-sm text-dark-grey"
          placeholder={circleLabelPlaceholder}
          rows={5}
          cols={14}
          style={circleLabelStyle}
        />
      </div>
    </div>
  );
};

export default CircleText;
