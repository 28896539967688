import { FunctionComponent } from "react";

const FrameComponent29: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[20px] min-h-[210px] text-left text-base text-black font-body5medium">
      <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
        <b className="relative tracking-[-0.25px] leading-[24px]">최근검색</b>
        <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray text-right">
          전체삭제
        </div>
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-dark-grey">
        <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[194px] mq450:gap-[32px]">
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
            크레스티드 게코
          </div>
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
            검색어1
          </div>
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
            검색어2
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[32px]">
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/x2.svg"
          />
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/x2.svg"
          />
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/x2.svg"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent29;
