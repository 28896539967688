import { FunctionComponent } from "react";

const Component4: FunctionComponent = () => {
  return (
    <section className="self-stretch bg-box-color overflow-hidden flex flex-col items-center justify-start pt-[30px] pb-10 pr-[31px] pl-8 gap-[18px] z-[1] text-left text-xs text-gray font-body5medium">
      <div className="flex flex-row items-start justify-start py-0 pr-[23px] pl-[22px] gap-[6px] mq450:flex-wrap">
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          이용약관
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-light-gray mq450:w-full mq450:h-1">
          |
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          개인정보처리방침
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-light-gray mq450:w-full mq450:h-1">
          |
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          위치기반서비스이용약관
        </div>
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[2px] opacity-[0.6] text-dark-grey">
        <div className="flex flex-row items-start justify-start py-0 px-0 gap-[4px]">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            렙테일
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            |
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            대표이사 : 남송무
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            |
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            서울특별시 성동구 성수로 12길 34
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[4px]">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium whitespace-pre-wrap">
            대표전화 010-1234-5678
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            |
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            사업자등록번호 : 123-45-6789
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[4px] mq450:flex-wrap">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium whitespace-nowrap">
            reptail@gmail.com
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium text-light-gray mq450:w-full mq450:h-1">
            |
          </div>
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            통신판매업신고 : 제2024-강남1234-5678
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start gap-[10px]">
        <img
          className="h-[50px] w-[50px] relative overflow-hidden shrink-0 min-h-[50px]"
          loading="eager"
          alt=""
          src="/graphic-elements.svg"
        />
        <img
          className="h-[50px] w-[50px] relative min-h-[50px]"
          loading="eager"
          alt=""
          src="/group.svg"
        />
        <img
          className="h-[50px] w-[50px] relative min-h-[50px]"
          loading="eager"
          alt=""
          src="/group-192.svg"
        />
      </div>
    </section>
  );
};

export default Component4;
