import { FunctionComponent } from "react";

const FrameCommunications1: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">통신사</b>
      <div className="self-stretch flex flex-row items-start justify-start py-3 px-4 relative gap-[10px] text-place-holder">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
          통신사를 선택하세요
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          alt=""
          src="/chevrondown.svg"
        />
      </div>
    </section>
  );
};

export default FrameCommunications1;
