import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameComponent6Type = {
  prop1?: string;

  /** Style props */
  propColor?: CSSProperties["color"];
};

const FrameComponent6: FunctionComponent<FrameComponent6Type> = ({
  prop1,
  propColor,
}) => {
  const div4Style: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <section className="self-stretch flex flex-col items-center justify-start py-0 px-5 box-border gap-[30px] min-h-[476px] text-left text-lg text-black font-body5medium">
      <div className="flex flex-row items-start justify-start pt-0 px-0 pb-3">
        <b className="relative tracking-[-0.25px] leading-[26px]">
          닉네임을 등록해주세요
        </b>
      </div>
      <div className="flex flex-col items-end justify-start">
        <img
          className="w-[74px] h-[74px] relative object-cover"
          alt=""
          src="/--1@2x.png"
        />
        <img
          className="w-5 h-5 relative z-[1] mt-[-21px]"
          loading="eager"
          alt=""
          src="/edit.svg"
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base">
        <b className="w-[87px] relative tracking-[-0.25px] leading-[24px] inline-block">
          닉네임
        </b>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-center p-3 relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                alt=""
                src="/rectangle-1.svg"
              />
              <div className="flex-1 overflow-hidden flex flex-row items-center justify-between py-0 px-1 z-[1]">
                <div className="flex flex-row items-center justify-center gap-[4px]">
                  <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                    렙테일
                  </div>
                  <div className="h-4 w-px relative bg-black hidden" />
                </div>
                <img
                  className="h-[18px] w-[18px] relative object-cover"
                  loading="eager"
                  alt=""
                  src="/btn-remove@2x.png"
                />
              </div>
            </div>
          </div>
          <div
            className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-negative-color"
            style={div4Style}
          >
            {prop1}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent6;
