import { FunctionComponent, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Component3 from "../components/Component3";

const ContactNew: FunctionComponent = () => {
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/12");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[68px] tracking-[normal]">
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border max-w-full">
        <div className="w-full flex flex-col items-start justify-start gap-[12px] shrink-0 text-left text-base text-place-holder font-body5medium">
         
            <div className="w-full overflow-hidden shrink-0 flex flex-row items-start justify-start py-3 text-[#000] text-center box-border relative gap-[12px]">
              <Link to="/contact">
                <img
                  className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
                  loading="eager"
                  alt=""
                  src="/chevronleft.svg"
                />
              </Link>
              <b className="w-full relative tracking-[-0.25px] leading-[24px] z-[1]">
                문의하기
              </b>
            </div>
    
          <div className="w-full flex flex-row items-start justify-start py-0 box-border max-w-full">
            <div className="rounded-lg bg-[#eee] w-full *:flex-1 flex flex-row items-center justify-center py-3 pr-[21px] pl-4 box-border relative max-w-full">
              <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
                제목을 입력해 주세요.
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row items-start justify-start py-0 box-border max-w-full">
            <div
              className=" rounded-lg bg-[#eee] flex-1 flex flex-row items-start justify-start py-3 px-4 box-border relative gap-[10px] max-w-full cursor-pointer"
              onClick={onDefaultContainerClick}
            >
              
              <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
                중분류 카테고리를 선택하세요
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div>
          <div className="w-full h-[400px] flex flex-row items-start justify-start py-0 box-border max-w-full">
            <div className="self-stretch flex-1 rounded-lg box-border flex flex-row items-start justify-start py-5 pr-[50px] pl-4 max-w-full z-[1] border-[1px] border-solid border-line-color mq450:pr-5 mq450:box-border">
              <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium">
                뮨의내용을 입력하세요. (최대 2000자)
              </div>
              <div className="h-[400px] w-[335px] relative rounded-lg box-border hidden max-w-full border-[1px] border-solid border-line-color" />
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 text-black">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              사진첨부
            </b>
          </div>
          <div className="w-[130px] flex flex-row items-start justify-start py-0 box-border">
            <div className="h-[130px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                </div>
              </div>
              <img
                className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/imageadd.svg"
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-start justify-start py-0 box-border max-w-full text-xs text-light-gray">
            <div className="flex-1 relative tracking-[-0.25px] leading-[20px] font-medium inline-block max-w-full">
              이미지는 JPEG, JPG, PNG만 가능하며 최대 3개까지 첨부 가능합니다.
            </div>
          </div>
        </div>
      </div>
      <Component3 prop="작성완료" propMarginTop="unset" />
    </div>
  );
};

export default ContactNew;
