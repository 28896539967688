import { FunctionComponent } from "react";
import AppBarFrame2 from "../components/AppBarFrame2";
import FrameB1 from "../components/FrameB1";
import FrameB from "../components/FrameB";

const Frame67: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[128px] text-left text-xs text-dark-grey font-body5medium">
      <main className="self-stretch flex flex-col items-start justify-start gap-[20px]">
        <AppBarFrame2 prop="판매 목록" />
        <FrameB1 />
        <FrameB prop="완료" />
        <FrameB
          prop="취소"
          propMinHeight="unset"
          propBackgroundColor="#ffebeb"
          propColor="#e24d4d"
        />
      </main>
      <div className="self-stretch h-[52px] flex flex-row items-center justify-center p-4 box-border relative">
        <footer className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] box-border border-b-[1px] border-solid border-line-color" />
        <div className="self-stretch w-[101px] flex flex-row items-center justify-start py-0 px-5 box-border gap-[4px] z-[1]">
          <div className="self-stretch flex-1 relative tracking-[-0.25px] leading-[20px] font-medium flex items-center">
            상세보기
          </div>
          <img
            className="h-4 w-4 relative overflow-hidden shrink-0"
            alt=""
            src="/chevronright.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Frame67;
