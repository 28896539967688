import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const EyeOpenIconFrame1: FunctionComponent = () => {
  return (
    <Link className = "no-underline"  to="/community/detail/2">
    <div className="w-[189px] rounded-lg bg-white shadow-[0px_2px_10px_rgba(0,_0,_0,_0.15)] shrink-0 flex flex-col items-center justify-start p-4 box-border gap-[8px] text-left text-xs text-solid1 font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start text-center">
        <div className="w-[67px] rounded-lg bg-solid2 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border">
          <b className="relative tracking-[-0.25px] leading-[20px]">
            헬프미닥터
          </b>
        </div>
      </div>
      <div className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] font-medium text-black">
        게시글 제목을 입력하세...
      </div>
      <div className="self-stretch flex flex-row items-start justify-start text-place-holder">
        <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[10px]">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              0
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/messagechatcircle.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              0
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <input
              className="cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
              type="radio"
            />
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              0
            </div>
          </div>
        </div>
      </div>
    </div>
    </Link>
  );
};

export default EyeOpenIconFrame1;
