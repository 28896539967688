import { FunctionComponent } from "react";
import MobileRegisterBtn from "./MobileRegisterBtn";

const RightArrowButton: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-[30px] gap-[20px_0px] text-left text-xs text-gray font-body5medium">
      <div className="self-stretch h-auto relative bg-gainsboro">
        <div className="relative top-[0px] left-[0px] bg-gainsboro w-full aspect-square hidden" />
        <img
          className="relative top-[0px] left-[0px] w-full aspect-square object-cover z-[1]"
          alt=""
          src="/new/-20240116--1159-1@2x.png"
        />
        <img
          className="absolute top-[20px] left-[20px] w-6 h-6 overflow-hidden z-[2]"
          loading="eager"
          alt=""
          src="/new/chevronleft.svg"
        />
        <img
          className="absolute top-[22px] right-[20px] w-5 h-5 z-[2]"
          loading="eager"
          alt=""
          src="/new/share-icon.svg"
        />
        
      </div>
      <MobileRegisterBtn icon="/-icon.svg" />
      <div className="self-stretch flex flex-col items-start justify-start gap-[14px_0px] text-center text-base text-solid1">
        <div className="self-stretch h-1 relative bg-box-color" />
        <div className="self-stretch overflow-hidden flex flex-row items-end justify-start pt-1.5 px-0 pb-0">
        
          <div className="w-full flex flex-row items-center justify-center z-[1]">
            <div className="flex-1 flex flex-row items-start justify-start py-0 px-5 gap-[0px_1px] border-b-[1.5px] border-solid border-[#eee]">
              <div className="flex-1 flex flex-col items-center justify-start py-0 box-border gap-[8px_0px] min-w-[109px]">
                <b className="relative tracking-[-0.25px] leading-[24px]">
                  상품정보
                </b>
                <div className="self-stretch h-0.5 relative bg-solid1" />
              </div>
              <div className="flex-1 flex flex-row items-start justify-center pt-0 px-0 pb-2.5 box-border min-w-[109px] text-dark-grey">
                <div className="w-[75px] flex flex-row items-center justify-center">
                  <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                    상세설명
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightArrowButton;
