import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const FrameComponent1: FunctionComponent = () => {
  return (
    <Link className = "no-underline" to="/community/detail/3">
    <div className="rounded-lg bg-white shadow-[0px_2px_10px_rgba(0,_0,_0,_0.15)] flex flex-col items-start justify-start py-5 pr-4 pl-5 gap-[8px] text-left text-xs text-solid1 font-body5medium">
      <div className="w-[57px] rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-center p-0.5 box-border text-center">
        <b className="relative tracking-[-0.25px] leading-[20px]">짝꿍찾기</b>
      </div>
      <div className="relative text-base tracking-[-0.25px] leading-[24px] font-medium text-black">
        게시글 제목을 입력하세...
      </div>
      <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[10px] text-place-holder">
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <img
            className="h-3 w-3 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/messagechatcircle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <input
            className="cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
            type="radio"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
      </div>
    </div>
    </Link>
  );
};

export default FrameComponent1;
