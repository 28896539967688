import { FunctionComponent, useMemo, type CSSProperties } from "react";

type Nav1Type = {
  prizeoff?: string;
  commuoff?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
  propColor?: CSSProperties["color"];
  propColor1?: CSSProperties["color"];
};

const Nav1: FunctionComponent<Nav1Type> = ({
  prizeoff,
  commuoff,
  propGap,
  propColor,
  propColor1,
}) => {
  const nav21Style: CSSProperties = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const div1Style: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  const div2Style: CSSProperties = useMemo(() => {
    return {
      color: propColor1,
    };
  }, [propColor1]);

  return (
    <footer
      className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-between pt-2.5 px-5 pb-10 gap-[18.75px] text-left text-xs text-dark-grey font-body5medium border-t-[1px] border-solid border-line-color mq450:flex-wrap"
      style={nav21Style}
    >
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/homeoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          홈
        </div>
      </div>
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/reptailoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일
        </div>
      </div>
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src={prizeoff}
          />
          <div
            className="relative tracking-[-0.25px] leading-[20px] font-medium"
            style={div1Style}
          >
            판매자랭킹
          </div>
        </div>
      </div>
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative"
          loading="eager"
          alt=""
          src="/shopoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일샵
        </div>
      </div>
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative"
          loading="eager"
          alt=""
          src={commuoff}
        />
        <div
          className="relative tracking-[-0.25px] leading-[20px] font-medium"
          style={div2Style}
        >
          커뮤니티
        </div>
      </div>
    </footer>
  );
};

export default Nav1;
