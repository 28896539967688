import { FunctionComponent } from "react";
import Addressinput from "../components/Addressinput";
import FrameComponent7 from "../components/FrameComponent7";
import Component2 from "../components/Component2";

const Frame24: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[20px] text-left text-base text-black font-body5medium">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          판매자 정보 관리
        </b>
      </header>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-[19px]">
        <b className="w-[167px] relative tracking-[-0.25px] leading-[24px] flex items-center pr-5">
          사업자 판매자 정보 관리
        </b>
      </div>
      <section className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] text-left text-base text-dark-grey font-body5medium">
        <b className="relative tracking-[-0.25px] leading-[24px]">대표자</b>
        <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative text-place-holder">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            홍길동
          </div>
        </div>
      </section>
      <section className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] text-left text-base text-dark-grey font-body5medium">
        <b className="relative tracking-[-0.25px] leading-[24px]">상호명</b>
        <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative text-place-holder">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            렙테일
          </div>
        </div>
      </section>
      <Addressinput />
      <section className="self-stretch h-[88px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] text-left text-base text-dark-grey font-body5medium">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          사업자 등록번호
        </b>
        <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative text-place-holder">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            000-00-00000
          </div>
        </div>
      </section>
      <FrameComponent7 propMinHeight="119px" propPadding="0px 20px" />
      <Component2 prop="수정하기" />
    </div>
  );
};

export default Frame24;
