import { FunctionComponent, useMemo, type CSSProperties } from "react";

type Framephoneauth1Type = {
  /** Style props */
  propHeight?: CSSProperties["height"];
};

const Framephoneauth1: FunctionComponent<Framephoneauth1Type> = ({
  propHeight,
}) => {
  const framephoneauthStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  return (
    <div
      className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-dark-grey font-body5medium"
      style={framephoneauthStyle}
    >
      <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
        휴대폰 번호
      </b>
      <div className="self-stretch flex flex-row items-center justify-center text-black">
        <div className="flex-1 flex flex-row items-center justify-center">
          <div className="flex-1 flex flex-row items-center justify-start py-3 px-4 relative">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              010-0000-0000
            </div>
          </div>
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 hidden"
          alt=""
          src="/chevrondown.svg"
        />
      </div>
    </div>
  );
};

export default Framephoneauth1;
