import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const DefaultInputFieldWithLabel: FunctionComponent = () => {
  const navigate = useNavigate();

  const onBtnFull1Click = useCallback(() => {
    navigate("/6");
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[20px] min-h-[96px] text-left text-base text-black font-body5medium">
      <div className="flex flex-row items-center justify-start gap-[4px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          결제 수단 선택
        </b>
        <img
          className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/.svg"
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0.5 pl-0 gap-[15px] mq450:flex-wrap">
        <button className="cursor-pointer [border:none] py-[13px] pr-[38px] pl-[43px] bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap">
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
          <b className="relative text-sm tracking-[-0.25px] leading-[22px] font-body5medium text-white text-center z-[1]">
            안심 계좌이체
          </b>
        </button>
        <button
          className="cursor-pointer [border:none] py-[13px] pr-[50px] pl-[55px] bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap"
          onClick={onBtnFull1Click}
        >
          <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-box-color" />
          <b className="relative text-sm tracking-[-0.25px] leading-[22px] font-body5medium text-solid1 text-center z-[1]">
            카드 결제
          </b>
        </button>
      </div>
    </div>
  );
};

export default DefaultInputFieldWithLabel;
