import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const TopFrame: FunctionComponent = () => {
  const navigate = useNavigate();

  const onBtnClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] top-[0] sticky max-w-full text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-6 pl-5 box-border max-w-full">
        <div className="flex-1 flex flex-row items-center justify-start gap-[9px] max-w-full">
          <img
            className="h-10 w-10 relative object-cover"
            loading="eager"
            alt=""
            src="/ellipse-67@2x.png"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] whitespace-nowrap">
            닉네임
          </b>
          <button
            className="cursor-pointer [border:none] p-0.5 bg-solid1 w-[63px] rounded-lg overflow-hidden shrink-0 flex flex-row items-center justify-center box-border hover:bg-mediumslateblue"
            onClick={onBtnClick}
          >
            <b className="relative text-xs tracking-[-0.25px] leading-[20px] font-body5medium text-white text-center">
              login하기
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default TopFrame;
