import { FunctionComponent, useMemo, type CSSProperties } from "react";

type Component9Type = {
  prop?: string;
  prop1?: string;

  /** Style props */
  propPadding?: CSSProperties["padding"];
};

const Component9: FunctionComponent<Component9Type> = ({
  prop,
  prop1,
  propPadding,
}) => {
  const articleFrameStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div className="self-stretch flex flex-col items-end justify-start text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 px-5 pb-5 gap-[10px] border-b-[1px] border-solid border-line-color">
        <img
          className="h-11 w-11 relative"
          loading="eager"
          alt=""
          src="/group1.svg"
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[183px]">
          <div className="self-stretch flex flex-row items-center justify-center gap-[9px]">
            <div
              className="flex-1 flex flex-row items-start justify-start py-0 pr-[31px] pl-0"
              style={articleFrameStyle}
            >
              <div className="relative tracking-[-0.25px] leading-[24px]">
                <p className="m-0">
                  <b>{prop}</b>
                </p>
                <p className="m-0 font-medium">{prop1}</p>
              </div>
            </div>
            <img
              className="h-[22px] w-[22px] relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/chatnew.svg"
            />
          </div>
          <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              1시간 전
            </div>
            <div className="self-stretch w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
              |
            </div>
            <div className="self-stretch w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
              등록개체999+
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component9;
