import { FunctionComponent, useCallback } from "react";
import Btnmobilefull from "./Btnmobilefull";
import Framephoneauth1 from "./Framephoneauth1";
import { useNavigate } from "react-router-dom";

const FramePhoneNumberInput1: FunctionComponent = () => {
  const navigate = useNavigate();

  const onBtnClick = useCallback(() => {
    navigate("/wrong-info");
  }, [navigate]);

  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[24px] text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch h-[58px] flex flex-col items-start justify-start">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            휴대폰 인증
          </b>
        </header>
        <div className="self-stretch h-1 relative bg-box-color">
          <div className="absolute top-[0px] left-[0px] bg-box-color w-[375px] h-1 hidden" />
          <div className="absolute top-[0px] left-[0px] bg-solid1 w-[282px] h-1 z-[1]" />
        </div>
      </div>
      <Btnmobilefull />
      <Framephoneauth1 propHeight="unset" />
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px]">
        <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
          인증번호
        </b>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-black">
          <div className="flex-1 rounded-lg bg-box-color flex flex-row items-end justify-between py-3 px-4 box-border min-w-[106px]">
            <img
              className="h-12 w-[209px] relative rounded-lg hidden"
              alt=""
              src="/rectangle.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              123456
            </div>
            <div className="flex flex-row items-start justify-start gap-[10px] text-smi text-solid1">
              <div className="relative tracking-[-0.25px] leading-[21px] font-medium z-[1]">
                2:54
              </div>
              <img
                className="h-[18px] w-[18px] relative object-cover z-[1]"
                loading="eager"
                alt=""
                src="/btn-remove1@2x.png"
              />
            </div>
          </div>
          <button
            className="cursor-pointer [border:none] py-4 pr-8 pl-[30px] bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-center"
            onClick={onBtnClick}
          >
            <img
              className="h-12 w-[114px] relative rounded-lg hidden"
              alt=""
              src="/rectangle.svg"
            />
            <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
              인증요청
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default FramePhoneNumberInput1;
