import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import InputDefaultLabel from "./InputDefaultLabel";
import AuthenticationInput from "./AuthenticationInput";

const PersonalInfoFrame: FunctionComponent = () => {
  const navigate = useNavigate();

  const onTextFieldBasicButtonInClick = useCallback(() => {
    navigate("/17");
  }, [navigate]);

  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] text-left text-base text-black font-body5medium">
      <div className="self-stretch h-[62px] flex flex-col items-start justify-start">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            판매자 등록하기
          </b>
        </header>
        <div className="w-52 h-1 relative bg-solid1 z-[1]" />
        <div className="self-stretch h-0 relative bg-box-color" />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          개인 판매자 정보 입력
        </b>
      </div>
      <InputDefaultLabel prop="이름을 입력해 주세요." />
      <AuthenticationInput
        group165Placeholder="주소찾기를 눌러주세요"
        prop="권역주소"
        prop1="상세주소"
        onTextFieldBasicButtonInClick={onTextFieldBasicButtonInClick}
      />
    </section>
  );
};

export default PersonalInfoFrame;
