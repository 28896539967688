import { FunctionComponent } from "react";

export type FrameComponent4Type = {
  emptyFrame?: string;
  emptyFrame1?: string;
  buttonFloat?: string;
};

const FrameComponent4: FunctionComponent<FrameComponent4Type> = ({
  emptyFrame,
  emptyFrame1,
  buttonFloat,
}) => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 pb-[30px] pr-[29px] pl-5 text-left text-base text-black font-body5medium">
      <div className="flex-1 flex flex-col items-start justify-start gap-[20px_0px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          배송 방법 정보
        </b>
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[0px_10px] text-center text-sm text-dark-grey">
          <div className="flex flex-col items-start justify-start gap-[21px_0px]">
            <div className="w-[46px] h-[46px] rounded-xl bg-white shadow-[0px_1px_5px_2px_rgba(0,_0,_0,_0.05)] overflow-hidden shrink-0 flex flex-row items-center justify-center p-[3px] box-border">
              <div className="h-10 w-[38.4px] relative overflow-hidden shrink-0">
                <img
                  className="absolute h-[87%] w-[90.62%] top-[8.5%] right-[4.69%] bottom-[4.5%] left-[4.69%] max-w-full overflow-hidden max-h-full"
                  alt=""
                  src="/new/vector.svg"
                />
                <img
                  className="absolute h-[12.5%] w-[33.33%] top-[20.75%] right-[33.33%] bottom-[66.75%] left-[33.33%] max-w-full overflow-hidden max-h-full z-[1]"
                  loading="eager"
                  alt=""
                  src="/new/group.svg"
                />
                <div className="absolute h-[47.75%] w-[74.22%] top-[33.75%] right-[12.76%] bottom-[18.5%] left-[13.02%] z-[1]">
                  <img
                    className="absolute h-[10.99%] w-[26.32%] top-[52.88%] right-[36.84%] bottom-[36.13%] left-[36.84%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/new/group-1.svg"
                  />
                  <img
                    className="absolute h-[6.81%] w-[28.42%] top-[43.46%] right-[35.79%] bottom-[49.74%] left-[35.79%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/new/vector-1.svg"
                  />
                  <img
                    className="absolute h-[1.57%] w-[84.91%] top-[62.3%] right-[7.37%] bottom-[36.13%] left-[7.72%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/new/group-2.svg"
                  />
                  <img
                    className="absolute top-[3.2px] left-[1.6px] w-[6.5px] h-[7.9px]"
                    alt=""
                    src="/new/group-3.svg"
                  />
                  <img
                    className="absolute top-[3.2px] left-[20.5px] w-[6.5px] h-[7.9px]"
                    alt=""
                    src="/new/group-4.svg"
                  />
                  <img
                    className="absolute h-[6.81%] w-[27.72%] top-[93.72%] right-[36.14%] bottom-[-0.52%] left-[36.14%] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/new/vector-2.svg"
                  />
                  <img
                    className="absolute h-[93.72%] w-full top-[0%] right-[0%] bottom-[6.28%] left-[0%] max-w-full overflow-hidden max-h-full z-[1]"
                    alt=""
                    src="/new/vector-31.svg"
                  />
                  <img
                    className="absolute h-[22.51%] w-[84.91%] top-[62.3%] right-[7.37%] bottom-[15.18%] left-[7.72%] max-w-full overflow-hidden max-h-full z-[2]"
                    alt=""
                    src="/new/vector-4.svg"
                  />
                </div>
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full z-[3]"
                  alt=""
                  src="/new/group-5.svg"
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[16px_0px]">
              <div className="w-[46px] h-[46px] relative flex items-center justify-center">
                <img
                  className="w-full h-full object-contain absolute left-[-1px] top-[0px] [transform:scale(1.261)]"
                  loading="eager"
                  alt=""
                  src={emptyFrame}
                />
              </div>
              <div className="w-[46px] h-[46px] relative flex items-center justify-center">
                <img
                  className="w-full h-full object-contain absolute left-[-1px] top-[0px] [transform:scale(1.261)]"
                  loading="eager"
                  alt=""
                  src={emptyFrame1}
                />
              </div>
            </div>
            <div className="w-[46px] h-[46px] relative flex items-center justify-center">
              <img
                className="w-full h-full object-contain absolute left-[-1px] top-[0px] [transform:scale(1.261)]"
                loading="eager"
                alt=""
                src={buttonFloat}
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[20px_0px] min-w-[175px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[2px_0px]">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                렌코몬 배송
              </div>
              <div className="relative text-xs tracking-[-0.25px] leading-[16px] font-medium text-light-gray text-left">
                <p className="m-0">{`파충류 전문 배송업체 ‘렌코몬’이 집 앞까지 빠르고 안전하게 배송해 드려요`}</p>
      
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[2px_0px]">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                자유 배송업체 배송
              </div>
              <div className="relative text-xs tracking-[-0.25px] leading-[16px] font-medium text-light-gray text-left">
                판매자가 자유롭게 정한 배송업체를 통해서 배송해 드려요
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[2px_0px]">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                만나서 거래
              </div>
              <div className="relative text-xs tracking-[-0.25px] leading-[16px] font-medium text-light-gray text-left">
                가까운 거리에서 배송비 없이 직거래하세요
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[2px_0px]">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                고속 배송
              </div>
              <div className="relative text-xs tracking-[-0.25px] leading-[16px] font-medium text-light-gray text-left">
                <p className="m-0">{`배송업체가 아닌 판매자가 정한 다른 배송 방법으로 배송받으세요`}</p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent4;
