import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent19 from "../components/FrameComponent19";
import FigmaElementsGroup from "../components/FigmaElementsGroup";
import MainFrame1 from "../components/MainFrame1";
import RootFrame from "../components/RootFrame";
import Nav from "../components/Nav";
import NavbarInstance from "../components/NavbarInstance";

const Frame43: FunctionComponent = () => {
  const navigate = useNavigate();

  const onParentFrameContainerClick = useCallback(() => {
    navigate("/1");
  }, [navigate]);

  const onParentFrameContainer1Click = useCallback(() => {
    navigate("/2");
  }, [navigate]);

  const onParentFrameContainer2Click = useCallback(() => {
    navigate("/3");
  }, [navigate]);

  const onBtnContainerClick = useCallback(() => {
    navigate("/seller/reg");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start tracking-[normal] text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-5 box-border top-[0] z-[99] sticky max-w-full">
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 box-border min-h-[72px] [row-gap:20px]">
        <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[159px]">
          <img
            className="h-10 w-10 relative object-cover"
            loading="eager"
            alt=""
            src="/ellipse-67@2x.png"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
            닉네임
          </b>
        </div>
        <div
          className="rounded-lg bg-solid1 overflow-hidden flex flex-row items-center justify-center py-0.5 pr-1 pl-2 whitespace-nowrap cursor-pointer text-center text-xs text-white"
          onClick={onBtnContainerClick}
        >
          <b className="relative tracking-[-0.25px] leading-[20px]">
            판매자 등록하기
          </b>
        </div>
      </div>
      <FrameComponent19 />
      <FigmaElementsGroup />
      <MainFrame1/>
      <NavbarInstance />

    </div>
  );
};

export default Frame43;
