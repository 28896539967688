import { FunctionComponent } from "react";
import CommulistArea1 from "../components/CommulistArea1";
import CommulistArea from "../components/CommulistArea";

const Frame48: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-16 box-border gap-[20px] text-center text-base text-solid1 font-body5medium">
      <div className="w-[50px] hidden flex-col items-start justify-start gap-[5px]">
        <img
          className="w-[50px] h-[50px] relative"
          alt=""
          src="/group-163.svg"
        />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start relative gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-soild-color z-[0]" />
          <img
            className="h-0 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          마이 커뮤니티
        </b>
      </header>
      <div className="self-stretch overflow-hidden flex flex-row items-end justify-start pt-1.5 px-0 pb-0">
        <div className="h-px flex-1 relative bg-line-color" />
        <div className="w-[375px] flex flex-row items-center justify-center z-[1] ml-[-470px]">
          <div className="flex-1 flex flex-row flex-wrap items-start justify-start py-0 px-5 gap-[1px]">
            <div className="flex-1 flex flex-col items-center justify-start gap-[8px] min-w-[109px]">
              <div className="flex flex-row items-start justify-start py-0 pr-5 pl-6">
                <b className="relative tracking-[-0.25px] leading-[24px]">
                  내가 쓴 글보기
                </b>
              </div>
              <div className="w-[150px] h-0.5 relative bg-solid1" />
            </div>
            <div className="flex flex-row items-start justify-center pt-0 px-0 pb-2.5 text-dark-grey">
              <div className="flex flex-row items-center justify-center py-0 pr-7 pl-8">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  내가 쓴 댓글보기
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 px-5 text-dark-grey">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          4개의 작성글이 있습니다.
        </b>
        <div className="w-[55px] rounded-xl bg-white box-border overflow-hidden shrink-0 flex flex-row items-center justify-center p-1 text-left text-xs text-gray border-[1px] border-solid border-line-color">
          <div className="flex flex-row items-center justify-start py-0 px-[5px] gap-[2px]">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              전체
            </div>
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
      </div>
      <CommulistArea1 prop="수다수다" heartChecked />
      <CommulistArea
        prop="짝꿍찾기"
        propBorderBottom="1px solid #e5e5e5"
        propHeight="unset"
        propHeight1="24px"
        propAlignSelf="unset"
      />
      <CommulistArea1
        prop="자랑하기"
        propBorderColor="unset"
        propAccentColor="unset"
      />
      <CommulistArea
        prop="기타"
        propBorderBottom="1px solid #e5e5e5"
        propHeight="24px"
        propHeight1="unset"
        propAlignSelf="stretch"
      />
    </div>
  );
};

export default Frame48;
