import { FunctionComponent } from "react";

const FrameComponent23: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 text-left text-xs text-light-gray font-body5medium">
      <div className="flex-1 flex flex-row flex-wrap items-center justify-start pt-0 pb-5 pr-[19px] pl-5 gap-[10px] border-b-[1px] border-solid border-line-color">
        <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
        <div className="flex-1 flex flex-row items-start justify-start gap-[10px] min-w-[183px] text-base text-dark-grey">
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
            <div className="relative tracking-[-0.25px] leading-[24px]">
              <b>닉네임</b>
              <span className="font-medium">
                님이 댓글에 좋아요를 눌렀습니다.
              </span>
            </div>
            <div className="w-[63px] relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray flex items-center pr-5">
              10시간전
            </div>
          </div>
          <img
            className="h-[22px] w-[22px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/xclose-1.svg"
          />
        </div>
        <div className="w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
          |
        </div>
        <div className="w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
          등록개체999+
        </div>
      </div>
    </div>
  );
};

export default FrameComponent23;
