import { FunctionComponent } from "react";

const FrameComponent: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[16px] text-left text-xs text-gray font-body5medium">
      <div className="flex flex-row items-start justify-start gap-[8px]">
        <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              베이비
            </div>
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
        <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              미구분
            </div>
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
        <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              서대문구
            </div>
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
        <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
          <div className="flex flex-row items-center justify-start gap-[2px]">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              조회 높은 순
            </div>
            <img
              className="h-3 w-3 relative overflow-hidden shrink-0"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-between gap-[20px] text-solid1 mq450:flex-wrap">
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/btncircleon.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            판매 중
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px] text-gray">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            종류
          </div>
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/settings04.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
