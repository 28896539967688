import { FunctionComponent } from "react";
import AppBarFrame2 from "../components/AppBarFrame2";
import CategoryBadge1 from "../components/CategoryBadge1";
import FrameB from "../components/FrameB";
import CategoryBadge from "../components/CategoryBadge";

const Frame68: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-24 box-border gap-[20px]">
      <AppBarFrame2 prop="구매 목록" />
      <CategoryBadge1 />
      <FrameB
        prop="완료"
        propMinHeight="190px"
        propBackgroundColor="#e0daff"
        propColor="#4d33cd"
      />
      <CategoryBadge />
    </div>
  );
};

export default Frame68;
