import { FunctionComponent } from "react";
import BaseFrame from "../components/BaseFrame";
import FrameComponent22 from "../components/FrameComponent22";
import Component5 from "../components/Component5";

const Frame46: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[60px] text-left text-sm text-place-holder font-body5medium">
      <div className="w-[50px] hidden flex-col items-start justify-start gap-[5px]">
        <img
          className="w-[50px] h-[50px] relative"
          alt=""
          src="/group-163.svg"
        />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start relative gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-soild-color z-[0]" />
          <img
            className="h-0 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <BaseFrame />
      <FrameComponent22 />
      <div className="flex flex-row items-start justify-start pt-0 px-5 pb-[106px]">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          작성된 댓글이 없습니다.
        </div>
      </div>
      <Component5 prop="댓글을 입력하세요." propMinWidth="152px" propFlex="1" />
    </div>
  );
};

export default Frame46;
