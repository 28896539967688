import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { Link } from "react-router-dom";

type NavType = {
  homeon?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
  propColor?: CSSProperties["color"];
};

const Nav: FunctionComponent<NavType> = ({propGap, propColor }) => {
  

  const nav2Style: CSSProperties = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const divStyle: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <section
      className="m-auto z-10 left-0 right-0 fixed w-full max-w-[540px] bottom-0 self-stretch bg-white overflow-hidden flex flex-row items-start justify-between pt-2.5 pb-[20px] text-left text-xs text-dark-grey font-body5medium border-t-[1px] border-solid border-line-color"
      style={nav2Style}
    >
      <Link className="no-underline visited:no-underline visited:text-[#000] text-[#000] " to="/">
      <div className="pl-[20px] w-[52px] flex flex-col items-center justify-center gap-[4px] text-solid1">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/homeon.svg"
          
        />
        <div
          className=" relative tracking-[-0.25px] leading-[20px] font-medium"
          style={divStyle}
        >
          홈
        </div>
      </div>
      </Link>
      <Link className="no-underline text-[#000]  visited:no-underline" to="/reptail">
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/reptailoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일
        </div>
      </div>
      </Link>
      <Link className="no-underline visited:no-underline text-[#000] " to="/ranking">
      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/prizeoff.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            판매자랭킹
          </div>
        </div>
      </div>
      </Link>
      <Link className="no-underline text-[#000] visited:no-underline" to="/shop">
      <div className="w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative"
          loading="eager"
          alt=""
          src="/shopoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          렙테일샵
        </div>
      </div>
      </Link>
      <Link className="no-underline text-[#000] visited:no-underline" to="/community">
      <div className="pr-[20px] w-[52px] flex flex-col items-center justify-center gap-[4px]">
        <img
          className="w-6 h-6 relative"
          loading="eager"
          alt=""
          src="/commuoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          커뮤니티
        </div>
      </div>
      </Link>
    </section>
  );
};

export default Nav;
