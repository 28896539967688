import { FunctionComponent, useCallback } from "react";
import FrameBuyerInfo from "../components/FrameBuyerInfo";
import { useNavigate } from "react-router-dom";
import Component3 from "../components/Component3";

const Frame6: FunctionComponent = () => {
  const navigate = useNavigate();

  const onBtnFullClick = useCallback(() => {
    navigate("/7");
  }, [navigate]);

  return (
    <div className="max-w-[540px] m-auto w-full relative bg-white overflow-hidden flex flex-col items-start justify-start tracking-[normal]">
      <main className="self-stretch flex flex-col items-center justify-start gap-[15px]">
        <section className="self-stretch flex flex-col items-center justify-start gap-[20px] text-left text-base text-black font-body5medium">
          <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 px-5 sticky gap-[12px] top-[0] z-[99] text-center text-base text-black font-body5medium">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/chevronleft.svg"
            />
            <b className="relative tracking-[-0.25px] leading-[24px] z-[1]">
              결제하기
            </b>
          </header>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              결제 금액
            </b>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[16px] text-sm text-gray">
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                결제금액
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                결제수수료
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                배송택배비
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                총 결제액
              </div>
              <div className="w-[180px] relative tracking-[-0.25px] leading-[22px] font-medium text-black text-right flex items-center shrink-0">
                000,000,000원
              </div>
            </div>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full shrink-0"
            loading="eager"
            alt=""
            src="/vector-35.svg"
          />
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              구매자 정보
            </b>
          </div>
          <FrameBuyerInfo />
        </section>
        <div className="flex flex-row items-start justify-start py-0 pr-[21px] pl-[19px] gap-[15px] mq450:flex-wrap">
          <button
            className="cursor-pointer [border:none] py-[13px] pr-[38px] pl-[43px] bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap"
            onClick={onBtnFullClick}
          >
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-box-color" />
            <b className="relative text-sm tracking-[-0.25px] leading-[22px] font-body5medium text-solid1 text-center z-[1]">
              안심 계좌이체
            </b>
          </button>
          <button className="cursor-pointer [border:none] py-[13px] pr-[50px] pl-[55px] bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap">
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
            <b className="relative text-sm tracking-[-0.25px] leading-[22px] font-body5medium text-white text-center z-[1]">
              카드 결제
            </b>
          </button>
        </div>
      </main>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[536px] box-border max-w-full mt-[-324px] mq600:pb-[348px] mq600:box-border">
        <img
          className="flex-1 relative max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/vector-35.svg"
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[607px] box-border max-w-full mt-[-324px] mq600:pb-[395px] mq600:box-border">
        <img
          className="flex-1 relative max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/vector-35.svg"
        />
      </div>
      <Component3 prop="결제하기" />
    </div>
  );
};

export default Frame6;
