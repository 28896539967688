import { FunctionComponent } from "react";

const FraudDetectionFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[6px] min-h-[82px] text-left text-smi text-light-gray font-body5medium">
      <div className="self-stretch relative tracking-[-0.25px] leading-[21px] font-medium">
        허위사진 혹은 타인의 사진 도용 시 법적 책임이 따르며 배송 및 개체 상태
        관련하여 렙테일에서는 책임지지 않으니 조심해주세요!
      </div>
      <div className="flex flex-row items-center justify-start gap-[4px] text-xs">
        <img
          className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/btncircleoff.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          동의합니다.
        </div>
      </div>
    </section>
  );
};

export default FraudDetectionFrame;
