import { FunctionComponent } from "react";

const FrameAccountOwnerName: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[8px] text-left text-base text-dark-grey font-body5medium">
      <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
        예금주
      </b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-xs text-light-gray">
        <div className="self-stretch relative tracking-[-0.25px] leading-[20px] font-medium">
          사업자의 경우 사업자등록증의 대표자명과 예금주명이 같은 계좌 또는
          사업자 전용 계좌를 입력해주세요.
        </div>
        <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative text-base text-black">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            홍길동
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px] z-[2]"
            alt=""
            src="/chevrondown.svg"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameAccountOwnerName;
