import { FunctionComponent } from "react";

const FrameComponent20: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-left text-xs text-gray font-body5medium">
      <div className="w-[91px] flex flex-row items-start justify-start gap-[10px]">
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          좋아요1
        </div>
        <div className="flex-1 relative tracking-[-0.25px] leading-[20px] font-medium">
          댓글쓰기
        </div>
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start text-base text-dark-grey">
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[218px]">
          <div className="w-[196px] flex flex-row items-start justify-start gap-[10px]">
            <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
            <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
              <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                닉네임
              </div>
              <div className="flex flex-row items-start justify-start text-xs text-light-gray">
                <div className="flex flex-row items-center justify-start">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    2024.01.01
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-sm tracking-[-0.25px] leading-[22px] font-medium">
            댓글이 노출됩니다.
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px] ml-[-36px] text-center text-xs text-light-gray">
          <img
            className="h-3.5 w-3.5 relative"
            loading="eager"
            alt=""
            src="/-icon-1.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            신고
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent20;
