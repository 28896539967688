import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";



import Frame2 from "./pages/Frame2";
import Frame from "./pages/Frame";
import Frame1 from "./pages/Frame1";
import Frame3 from "./pages/Frame3";
import Frame4 from "./pages/Frame4";
import Frame5 from "./pages/Frame5";
import Frame6 from "./pages/Frame6";
import Frame7 from "./pages/Frame7";
import Notice from "./pages/Notice";
import NoticeDetail from "./pages/NoticeDetail";
import Contact from "./pages/Contact";
import ContactNew from "./pages/ContactNew";
import Frame12 from "./pages/Frame12";
import Frame13 from "./pages/Frame13";
import ContactDetail from "./pages/ContactDetail";
import Frame16 from "./pages/Frame16";
import Frame17 from "./pages/Frame17";
import Frame18 from "./pages/Frame18";
import Frame19 from "./pages/Frame19";
import Frame20 from "./pages/Frame20";
import Frame21 from "./pages/Frame21";
import Frame22 from "./pages/Frame22";
import Frame23 from "./pages/Frame23";
import Frame24 from "./pages/Frame24";
import Frame25 from "./pages/Frame25";
import Frame26 from "./pages/Frame26";
import Frame27 from "./pages/Frame27";
import Frame28 from "./pages/Frame28";
import Frame29 from "./pages/Frame29";
import Frame30 from "./pages/Frame30";
import Frame31 from "./pages/Frame31";
import Frame32 from "./pages/Frame32";
import Frame33 from "./pages/Frame33";
import Frame34 from "./pages/Frame34";
import Frame35 from "./pages/Frame35";
import WrongInfo from "./pages/WrongInfo";
import RightInfo from "./pages/RightInfo";
import RightInfo1 from "./pages/RightInfo1";
import Frame36 from "./pages/Frame36";
import Frame37 from "./pages/Frame37";
import Frame38 from "./pages/Frame38";
import Frame39 from "./pages/Frame39";
import Frame40 from "./pages/Frame40";
import Frame41 from "./pages/Frame41";
import Frame42 from "./pages/Frame42";
import Frame43 from "./pages/Frame43";
import Frame44 from "./pages/Frame44";
import On from "./pages/On";
import Frame45 from "./pages/Frame45";
import Frame46 from "./pages/Frame46";
import Frame47 from "./pages/Frame47";
import Frame48 from "./pages/Frame48";
import Frame49 from "./pages/Frame49";
import Frame50 from "./pages/Frame50";
import Frame51 from "./pages/Frame51";
import Frame52 from "./pages/Frame52";
import Frame53 from "./pages/Frame53";
import Frame54 from "./pages/Frame54";
import Frame55 from "./pages/Frame55";
import Frame56 from "./pages/Frame56";
import Frame57 from "./pages/Frame57";
import Component10 from "./pages/Component10";
import Frame58 from "./pages/Frame58";
import Frame59 from "./pages/Frame59";
import Frame60 from "./pages/Frame60";
import Frame61 from "./pages/Frame61";
import Frame62 from "./pages/Frame62";
import Frame63 from "./pages/Frame63";
import Frame64 from "./pages/Frame64";
import Frame65 from "./pages/Frame65";
import Frame66 from "./pages/Frame66";
import Frame67 from "./pages/Frame67";
import Frame68 from "./pages/Frame68";
import Frame69 from "./pages/Frame69";
import Frame70 from "./pages/Frame70";
import Frame71 from "./pages/Frame71";
import Frame72 from "./pages/Frame72";
import Frame721 from "./pages/Frame721";
import Frame73 from "./pages/Frame73";
import Frame74 from "./pages/Frame74";
import QNA from "./pages/QNA";
import ReptailShop from "./pages/reptailshop";
import Search from "./pages/Search";
import Chat from "./pages/Chat";
import ChatDetail1 from "./pages/ChatDetail1";
import ChatDetail2 from "./pages/ChatDetail2";
import ReptailDetail from "./pages/ProductDetail";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/16":
        title = "";
        metaDescription = "";
        break;
      case "/27":
        title = "";
        metaDescription = "";
        break;
      case "/28":
        title = "";
        metaDescription = "";
        break;
      case "/29":
        title = "";
        metaDescription = "";
        break;
      case "/7":
        title = "";
        metaDescription = "";
        break;
      case "/6":
        title = "";
        metaDescription = "";
        break;
      case "/30":
        title = "";
        metaDescription = "";
        break;
      case "/1":
        title = "";
        metaDescription = "";
        break;
      case "/9":
        title = "";
        metaDescription = "";
        break;
      case "/3":
        title = "";
        metaDescription = "";
        break;
      case "/11":
        title = "";
        metaDescription = "";
        break;
      case "/12":
        title = "";
        metaDescription = "";
        break;
      case "/13":
        title = "";
        metaDescription = "";
        break;
      case "/10":
        title = "";
        metaDescription = "";
        break;
      case "/2":
        title = "";
        metaDescription = "";
        break;
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/14":
        title = "";
        metaDescription = "";
        break;
      case "/15":
        title = "";
        metaDescription = "";
        break;
      case "/31":
        title = "";
        metaDescription = "";
        break;
      case "/25":
        title = "";
        metaDescription = "";
        break;
      case "/32":
        title = "";
        metaDescription = "";
        break;
      case "/5":
        title = "";
        metaDescription = "";
        break;
      case "/18":
        title = "";
        metaDescription = "";
        break;
      case "/33":
        title = "";
        metaDescription = "";
        break;
      case "/8":
        title = "";
        metaDescription = "";
        break;
      case "/34":
        title = "";
        metaDescription = "";
        break;
      case "/35":
        title = "";
        metaDescription = "";
        break;
      case "/36":
        title = "";
        metaDescription = "";
        break;
      case "/17":
        title = "";
        metaDescription = "";
        break;
      case "/37":
        title = "";
        metaDescription = "";
        break;
      case "/20":
        title = "";
        metaDescription = "";
        break;
      case "/19":
        title = "";
        metaDescription = "";
        break;
      case "/21":
        title = "";
        metaDescription = "";
        break;
      case "/38":
        title = "";
        metaDescription = "";
        break;
      case "/4":
        title = "";
        metaDescription = "";
        break;
      case "/wrong-info":
        title = "";
        metaDescription = "";
        break;
      case "/right-info":
        title = "";
        metaDescription = "";
        break;
      case "/right-info1":
        title = "";
        metaDescription = "";
        break;
      case "/39":
        title = "";
        metaDescription = "";
        break;
      case "/40":
        title = "";
        metaDescription = "";
        break;
      case "/41":
        title = "";
        metaDescription = "";
        break;
      case "/42":
        title = "";
        metaDescription = "";
        break;
      case "/43":
        title = "";
        metaDescription = "";
        break;
      case "/44":
        title = "";
        metaDescription = "";
        break;
      case "/45":
        title = "";
        metaDescription = "";
        break;
      case "/46":
        title = "";
        metaDescription = "";
        break;
      case "/47":
        title = "";
        metaDescription = "";
        break;
      case "/on":
        title = "";
        metaDescription = "";
        break;
      case "/48":
        title = "";
        metaDescription = "";
        break;
      case "/49":
        title = "";
        metaDescription = "";
        break;
      case "/50":
        title = "";
        metaDescription = "";
        break;
      case "/51":
        title = "";
        metaDescription = "";
        break;
      case "/52":
        title = "";
        metaDescription = "";
        break;
      case "/53":
        title = "";
        metaDescription = "";
        break;
      case "/54":
        title = "";
        metaDescription = "";
        break;
      case "/55":
        title = "";
        metaDescription = "";
        break;
      case "/56":
        title = "";
        metaDescription = "";
        break;
      case "/57":
        title = "";
        metaDescription = "";
        break;
      case "/58":
        title = "";
        metaDescription = "";
        break;
      case "/59":
        title = "";
        metaDescription = "";
        break;
      case "/60":
        title = "";
        metaDescription = "";
        break;
      case "/26":
        title = "";
        metaDescription = "";
        break;
      case "/61":
        title = "";
        metaDescription = "";
        break;
      case "/62":
        title = "";
        metaDescription = "";
        break;
      case "/63":
        title = "";
        metaDescription = "";
        break;
      case "/64":
        title = "";
        metaDescription = "";
        break;
      case "/65":
        title = "";
        metaDescription = "";
        break;
      case "/66":
        title = "";
        metaDescription = "";
        break;
      case "/67":
        title = "";
        metaDescription = "";
        break;
      case "/68":
        title = "";
        metaDescription = "";
        break;
      case "/69":
        title = "";
        metaDescription = "";
        break;
      case "/70":
        title = "";
        metaDescription = "";
        break;
      case "/22":
        title = "";
        metaDescription = "";
        break;
      case "/71":
        title = "";
        metaDescription = "";
        break;
      case "/72":
        title = "";
        metaDescription = "";
        break;
      case "/73":
        title = "";
        metaDescription = "";
        break;
      case "/74":
        title = "";
        metaDescription = "";
        break;
      case "/75":
        title = "";
        metaDescription = "";
        break;
      case "/23":
        title = "";
        metaDescription = "";
        break;
      case "/24":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Frame2 />} />
      <Route path="/reptail" element={<Frame />} />
      <Route path="/reptail/none" element={<Frame1 />} />
      <Route path="/menu" element={<Frame3 />} />
      <Route path="/menu/seller" element={<Frame4 />} />
      <Route path="/payment" element={<Frame5 />} />
      <Route path="/payment/card" element={<Frame6 />} />
      <Route path="/menu/setting" element={<Frame7 />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/notice/detail" element={<NoticeDetail />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contact/new" element={<ContactNew />} />
      <Route path="/contact1" element={<Frame12 />} />
      <Route path="/contact2" element={<Frame13 />} />
      <Route path="/contact/detail" element={<ContactDetail />} />
      <Route path="/qna" element={<QNA />} />
      <Route path="/login" element={<Frame16 />} />
      <Route path="/nickname/reg" element={<Frame17 />} />
      <Route path="/nickname/wrong" element={<Frame18 />} />
      <Route path="/nickname/wright" element={<Frame19 />} />
      <Route path="/seller/reg" element={<Frame20 />} />
      <Route path="/seller/reg0" element={<Frame21 />} />
      <Route path="/seller/edit" element={<Frame22 />} />
      <Route path="/seller/edit-1" element={<Frame23 />} />
      <Route path="/seller/edit/business" element={<Frame24 />} />
      <Route path="/phone-edit1" element={<Frame25 />} />
      <Route path="/phone-edit" element={<Frame26 />} />
      <Route path="/phone-edit/wrong" element={<Frame27 />} />
      <Route path="/phone-edit/wright" element={<Frame28 />} />
      <Route path="/seller/reg2" element={<Frame29 />} />
      <Route path="/seller/reg3" element={<Frame30 />} />
      <Route path="/phone/" element={<Frame31 />} />
      <Route path="/phone1" element={<Frame32 />} />
      <Route path="/bank" element={<Frame33 />} />
      <Route path="/bank1" element={<Frame34 />} />
      <Route path="/acoount/edit" element={<Frame35 />} />
      <Route path="/wrong/info" element={<WrongInfo />} />
      <Route path="/right/info" element={<RightInfo />} />
      <Route path="/right/info1" element={<RightInfo1 />} />
      <Route path="/seller/reg/done" element={<Frame36 />} />
      <Route path="/seller/reg/business/done" element={<Frame37 />} />
      <Route path="/seller/reg/business" element={<Frame38 />} />
      <Route path="/seller/reg/business1" element={<Frame39 />} />
      <Route path="/seller/reg/business2" element={<Frame40 />} />
      <Route path="/seller/reg/business3" element={<Frame41 />} />
      <Route path="/menu/1" element={<Frame42 />} />
      <Route path="/menu/login" element={<Frame43 />} />
      <Route path="/community" element={<Frame44 />} />
      <Route path="/community/detail/1" element={<On />} />
      <Route path="/community/detail/2" element={<Frame45 />} />
      <Route path="/community/detail/3" element={<Frame46 />} />
      <Route path="/community/reg" element={<Frame47 />} />
      <Route path="/my/community" element={<Frame48 />} />
      <Route path="/my/comment" element={<Frame49 />} />
      <Route path="/community/recent" element={<Frame50 />} />
      <Route path="/community/recent/edit" element={<Frame51 />} />
      <Route path="/reptail/recent/edit" element={<Frame52 />} />
      <Route path="/reptail/recent" element={<Frame53 />} />
      <Route path="/notify/community/edit" element={<Frame54 />} />
      <Route path="/notify/community" element={<Frame55 />} />
      <Route path="/notify/reptail" element={<Frame56 />} />
      <Route path="/chart" element={<Frame57 />} />
      <Route path="/chart/edit" element={<Component10 />} />
      <Route path="/ranking" element={<Frame58 />} />
      <Route path="/search/seller" element={<Frame59 />} />
      <Route path="/search/seller1" element={<Frame60 />} />
      <Route path="/review" element={<Frame61 />} />
      <Route path="/review0" element={<Frame62 />} />
      <Route path="/list/follow0" element={<Frame63 />} />
      <Route path="/list/like0" element={<Frame64 />} />
      <Route path="/list/block0" element={<Frame65 />} />
      <Route path="/list/sell0" element={<Frame66 />} />
      <Route path="/list/sell" element={<Frame67 />} />
      <Route path="/list/buy" element={<Frame68 />} />
      <Route path="/review/write" element={<Frame69 />} />
      <Route path="/list/sell/detail" element={<Frame70 />} />
      <Route path="/list/sell/detail1" element={<Frame71 />} />
      <Route path="/list/buy/detail" element={<Frame72 />} />
      <Route path="/list/buy/detail1" element={<Frame721 />} />
      <Route path="/list/like" element={<Frame73 />} />
      <Route path="/list/follow" element={<Frame74 />} />
      <Route path="/shop" element={<ReptailShop />} />
      <Route path="/search" element={<Search/>} />
      <Route path="/chat" element={<Chat/>} />
      <Route path="/chatdetail1" element={<ChatDetail1/>} />
      <Route path="/chatdetail2" element={<ChatDetail2/>} />
      <Route path="/reptail/detail1" element={<ReptailDetail/>} />
    </Routes>
  );
}
export default App;
