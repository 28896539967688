import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const FrameWrapper: FunctionComponent = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/right-info");
  }, [navigate]);

  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-dark-grey font-body5medium">
      <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
        인증번호
      </b>
      <div
        className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] cursor-pointer text-black"
        onClick={onGroupContainerClick}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[136px]">
          <div className="self-stretch rounded-lg bg-box-color flex flex-row items-center justify-start py-3 px-4">
            <img
              className="h-12 w-[209px] relative rounded-lg hidden"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              123456
            </div>
            <img
              className="h-3.5 w-3.5 relative object-cover z-[1]"
              loading="eager"
              alt=""
              src="/icon@2x.png"
            />
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-1 text-smi text-negative-color">
            <div className="relative tracking-[-0.25px] leading-[21px] font-medium">
              인증번호가 틀렸습니다.
            </div>
          </div>
        </div>
        <button className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-start">
          <img
            className="h-12 w-[114px] relative rounded-lg hidden"
            alt=""
            src="/rectangle.svg"
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            인증요청
          </b>
        </button>
      </div>
    </div>
  );
};

export default FrameWrapper;
