import { FunctionComponent } from "react";

const FrameComponent1: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 pb-[37px] pr-0 pl-[26px] text-center text-base text-black font-body5medium">
      <div className="w-[418px] flex flex-col items-start justify-start gap-[20px_0px]">
        <b className="w-[157px] relative tracking-[-0.25px] leading-[24px] flex items-center justify-center box-border pr-5">
          이 글과 함께 본 게시물
        </b>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[0px_14px] text-left text-sm text-light-gray">
          <div className="flex-1 flex flex-col items-start justify-start gap-[10px_0px] min-w-[97px]">
            <div className="self-stretch h-[130px] relative rounded-8xs bg-whitesmoke-100" />
            <div className="flex flex-col items-start justify-start gap-[2px_0px]">
              <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[0px_4px]">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    카테고리
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    |
                  </div>
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  지역
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-black">
                상품명이 노출되는곳...
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[10px_0px] min-w-[97px]">
            <div className="self-stretch h-[130px] relative rounded-8xs bg-whitesmoke-100" />
            <div className="flex flex-col items-start justify-start gap-[2px_0px]">
              <div className="flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[0px_4px]">
                <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    카테고리
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                    |
                  </div>
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  지역
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-black">
                상품명이 노출되는곳...
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-row items-center justify-center min-w-[97px]">
            <div className="h-[186px] flex-1 flex flex-col items-start justify-start relative gap-[10px_0px]">
              <div className="self-stretch flex-1 relative rounded-8xs bg-whitesmoke-100" />
              <div className="self-stretch h-[46px] flex flex-col items-start justify-start gap-[2px_0px]">
                <div className="flex-1 flex flex-row items-start justify-start py-0 pr-5 pl-0 gap-[0px_4px]">
                  <div className="flex flex-row items-start justify-start gap-[0px_4px]">
                    <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                      카테고리
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                      |
                    </div>
                  </div>
                  <div className="self-stretch w-6 relative tracking-[-0.25px] leading-[22px] font-medium flex items-center">
                    지역
                  </div>
                </div>
                <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
                  상품명이 노출되는곳...
                </div>
              </div>
           
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default FrameComponent1;
