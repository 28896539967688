import { FunctionComponent, useMemo, type CSSProperties } from "react";

type CommulistArea2Type = {
  heartChecked?: boolean;
  xCloseIcon?: boolean;

  /** Style props */
  propWidth?: CSSProperties["width"];
  propAlignSelf?: CSSProperties["alignSelf"];
  propGap?: CSSProperties["gap"];
  propBorderColor?: CSSProperties["borderColor"];
  propAccentColor?: CSSProperties["accentColor"];
};

const CommulistArea2: FunctionComponent<CommulistArea2Type> = ({
  heartChecked,
  xCloseIcon,
  propWidth,
  propAlignSelf,
  propGap,
  propBorderColor,
  propAccentColor,
}) => {
  const commulistArea21Style: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  const frameDiv3Style: CSSProperties = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const heart2Style: CSSProperties = useMemo(() => {
    return {
      borderColor: propBorderColor,
      accentColor: propAccentColor,
    };
  }, [propBorderColor, propAccentColor]);

  return (
    <div
      className="w-[375px] box-border flex flex-col items-start justify-start pt-0 px-5 pb-5 text-left text-xs text-solid1 font-body5medium border-b-[1px] border-solid border-line-color"
      style={commulistArea21Style}
    >
      <div
        className="self-stretch flex flex-col items-end justify-start gap-[8px]"
        style={frameDiv3Style}
      >
        {!xCloseIcon && (
          <img
            className="w-5 h-5 relative overflow-hidden shrink-0 hidden"
            alt=""
            src="/xclose.svg"
          />
        )}
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="flex flex-row items-center justify-start gap-[8px] text-center">
            <div className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                짝꿍찾기
              </b>
            </div>
            <b className="relative tracking-[-0.25px] leading-[20px] text-gray">
              닉네임
            </b>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px] text-base text-black">
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
              게시글 제목을 입력하세요
            </div>
            <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-light-gray">
              게시글의 내용이 노출되는 곳입니다ㅎㅎㅎㅎㅎㅎㅎㅎㅎ 최대...
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-[10px] text-place-holder">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                0
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <img
                className="h-3 w-3 relative overflow-hidden shrink-0"
                alt=""
                src="/messagechatcircle.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                0
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <input
                className="cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
                type="radio"
                checked={heartChecked}
                style={heart2Style}
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                0
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommulistArea2;
