import { FunctionComponent } from "react";
import FrameComponent28 from "../components/FrameComponent28";
import List from "../components/List";
import Navhomeprize from "../components/Navhomeprize";
import Nav1 from "../components/Nav1";

const Frame58: FunctionComponent = () => {
  return (
    <div className="pt-[20px] m-auto max-w-[540px] w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[18px]">
      
      <FrameComponent28 />
      <div className="w-1 relative my-0 z-[1]">
        <div className="relative w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-gray1" />
      </div>
      <div className="self-stretch flex flex-row items-start justify-end py-0 px-0">
        <main className="flex-1 flex flex-col items-start justify-start py-0 pr-px pl-0 text-center text-base text-solid1 font-body5medium">
          <List badgeLangkingGold="/badge--langking--gold.svg" prop="1위" />
          <List badgeLangkingGold="/badge--langking--silver.svg" prop="2위" />
          <List badgeLangkingGold="/badge--langking--bronze.svg" prop="3위" />
          <section className="self-stretch flex flex-col items-start justify-start text-center text-base text-solid1 font-body5medium">
            <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
              <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
                <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
                  4위
                </div>
                <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
                <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
                  <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                    닉네임
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      팔로워49
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      |
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      등록개체69
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
                <b className="relative tracking-[-0.25px] leading-[20px]">
                  팔로잉
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
              <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
                <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
                  5위
                </div>
                <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
                <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
                  <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                    닉네임
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      팔로워49
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      |
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      등록개체69
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
                <b className="relative tracking-[-0.25px] leading-[20px]">
                  팔로잉
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
              <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
                <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
                  6위
                </div>
                <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
                <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
                  <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                    닉네임
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      팔로워49
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      |
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      등록개체69
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
                <b className="relative tracking-[-0.25px] leading-[20px]">
                  팔로잉
                </b>
              </div>
            </div>
          </section>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
              <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
                7위
              </div>
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워49
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체69
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
              <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
                8위
              </div>
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워49
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체69
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-3.5 px-5 gap-[48px]">
            <div className="flex-1 flex flex-row items-center justify-start gap-[10px] min-w-[156px]">
              <div className="w-[34px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center shrink-0">
                9위
              </div>
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left text-dark-grey">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    팔로워49
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    |
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체69
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[47px] rounded-lg bg-solid1 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border text-xs text-white">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                팔로잉
              </b>
            </div>
          </div>
          <Navhomeprize />
        </main>
      </div>

    </div>
  );
};

export default Frame58;
