import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { Link } from "react-router-dom";

type FrameBType = {
  prop?: string;

  /** Style props */
  propMinHeight?: CSSProperties["minHeight"];
  propBackgroundColor?: CSSProperties["backgroundColor"];
  propColor?: CSSProperties["color"];
};

const FrameB: FunctionComponent<FrameBType> = ({
  prop,
  propMinHeight,
  propBackgroundColor,
  propColor,
}) => {
  const frameBStyle: CSSProperties = useMemo(() => {
    return {
      minHeight: propMinHeight,
    };
  }, [propMinHeight]);

  const badgeCategoryBadge12Style: CSSProperties = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const b4Style: CSSProperties = useMemo(() => {
    return {
      color: propColor,
    };
  }, [propColor]);

  return (
    <section
      className="self-stretch flex flex-col items-start justify-start min-h-[190px] text-center text-xs text-solid1 font-body5medium"
      style={frameBStyle}
    >
      <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 px-5 pb-5 gap-[7px]">
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[149px]">
          <div className="w-[162px] h-6 flex flex-row items-center justify-start gap-[8px]">
            <div
              className="self-stretch rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-center py-0 px-2"
              style={badgeCategoryBadge12Style}
            >
              <b
                className="relative tracking-[-0.25px] leading-[20px]"
                style={b4Style}
              >
                {prop}
              </b>
            </div>
            <div className="flex-1 flex flex-row items-start justify-start gap-[4px] text-left text-gray">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                2024.01.01
              </div>
              <div className="flex-1 relative tracking-[-0.25px] leading-[20px] font-medium">
                {" "}
                00:00:00
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-left text-sm text-black">
            <div className="w-[152px] flex flex-row items-start justify-start gap-[4px] text-light-gray">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                카테고리
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                |
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                지역
              </div>
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              상품명을 입력하세요
            </div>
            <b className="relative text-base tracking-[-0.25px] leading-[24px]">
              200,000원
            </b>
          </div>
        </div>
        <div className="h-[98px] w-[98px] relative rounded-lg bg-box-color overflow-hidden shrink-0" />
      </div>
      <button className="cursor-pointer [border:none] p-4 bg-[transparent] self-stretch flex flex-row items-center justify-center relative">
        <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] box-border border-b-[1px] border-solid border-line-color" />
        <Link className="no-underline" to="/list/buy/detail"> 
        <div className="flex flex-row items-center justify-start py-0 px-5 gap-[4px] z-[1]">
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium font-body5medium text-dark-grey text-left">
            상세보기
          </div>
          <img
            className="h-4 w-4 relative overflow-hidden shrink-0"
            alt=""
            src="/chevronright.svg"
          />
        </div>
        </Link>
      </button>
    </section>
  );
};

export default FrameB;
