import { FunctionComponent } from "react";

const FrameComponent33: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row flex-wrap items-center justify-start pt-0 pb-5 pr-[21px] pl-5 gap-[0px_8px] text-left text-base text-dark-grey font-body5medium border-b-[1px] border-solid border-line-color">
      <div className="h-11 w-11 relative rounded-[50%] bg-[#eee]" />
      <div className="flex-1 flex flex-col items-start justify-start gap-[6px_0px] min-w-[149px]">
        <div className="flex flex-row items-center justify-center gap-[0px_6px]">
          <b className="relative tracking-[-0.25px] leading-[24px]">닉네임</b>
          <div className="flex flex-row items-start justify-start text-xs text-light-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              7일전
            </div>
          </div>
        </div>
        <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium text-gray">
          최근 대화 내용이 노출됩니다.
        </div>
      </div>
      <div className="h-11 w-11 relative rounded-lg bg-box-color overflow-hidden shrink-0" />
    </div>
  );
};

export default FrameComponent33;
