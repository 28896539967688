import { FunctionComponent, useCallback } from "react";
import Btnmobilefull from "../components/Btnmobilefull";
import { useNavigate } from "react-router-dom";
import Framephoneauth from "../components/Framephoneauth";
import Component2 from "../components/Component2";

const RightInfo1: FunctionComponent = () => {
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/20");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[284px]">
      <section className="self-stretch flex flex-col items-center justify-start gap-[24px] text-left text-base text-dark-grey font-body5medium">
        <div className="self-stretch h-[58px] flex flex-col items-start justify-start">
          <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/chevronleft.svg"
            />
            <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
              휴대폰 인증
            </b>
          </header>
          <div className="self-stretch h-1 relative bg-box-color">
            <div className="absolute top-[0px] left-[0px] bg-box-color w-[375px] h-1 hidden" />
            <div className="absolute top-[0px] left-[0px] bg-solid1 w-[282px] h-1 z-[1]" />
          </div>
        </div>
        <Btnmobilefull />
        <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px]">
          <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
            휴대폰 번호
          </b>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative cursor-pointer text-place-holder"
            onClick={onDefaultContainerClick}
          >
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              휴대폰번호를 입력하세요.
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px] z-[2]"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
        </div>
        <Framephoneauth
          group165Placeholder="123456"
          rectangle="/rectangle.svg"
          propBackgroundColor="#edeeee"
          propBackgroundColor1="#b8b8b8"
        />
      </section>
      <Component2 prop="등록" />
    </div>
  );
};

export default RightInfo1;
