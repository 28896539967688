import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameCommunicationsType = {
  prop?: string;
  prop1?: string;

  /** Style props */
  propGap?: CSSProperties["gap"];
  propDisplay?: CSSProperties["display"];
  propWidth?: CSSProperties["width"];
  propFlex?: CSSProperties["flex"];
};

const FrameCommunications: FunctionComponent<FrameCommunicationsType> = ({
  prop,
  prop1,
  propGap,
  propDisplay,
  propWidth,
  propFlex,
}) => {
  const frameCommunicationsStyle: CSSProperties = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const b2Style: CSSProperties = useMemo(() => {
    return {
      display: propDisplay,
      width: propWidth,
    };
  }, [propDisplay, propWidth]);

  const div8Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  return (
    <section
      className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-base text-black font-body5medium"
      style={frameCommunicationsStyle}
    >
      <b className="relative tracking-[-0.25px] leading-[24px]" style={b2Style}>
        {prop}
      </b>
      <div className="self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative text-place-holder">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/rectangle.svg"
        />
        <div
          className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]"
          style={div8Style}
        >
          {prop1}
        </div>
      </div>
    </section>
  );
};

export default FrameCommunications;
