import { FunctionComponent, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import FrameComponent4 from "../components/FrameComponent4";

const Contact: FunctionComponent = () => {
  const navigate = useNavigate();

  const onFrameContainerClick = useCallback(() => {
    navigate("/contact/detail");
  }, [navigate]);

  const onContainerClick = useCallback(() => {
    navigate("/contact/new");
  }, [navigate]);

  return (
    <div className="relative bg-white overflow-hidden flex flex-col items-end justify-start gap-[100px]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
        <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 relative gap-[12px] text-center text-base text-black font-body5medium">
          <Link to="/">
            <img
              className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
              loading="eager"
              alt=""
              src="/chevronleft.svg"
            />
          </Link>
          <b className="relative tracking-[-0.25px] leading-[24px] z-[1] w-full">
            문의하기
          </b>
        </div>
        <FrameComponent4
          prop="답변완료"
          prop1="[신고 | 상품신고]"
          onFrameContainerClick={onFrameContainerClick}
        />
        <FrameComponent4
          prop="답변대기"
          prop1="[중분류 | 소분류]"
          dividerBorderBottom="1px solid #e5e5e5"
          divBackgroundColor="#ebebeb"
          propColor="#666"
          onFrameContainerClick={onFrameContainerClick}
        />
        <FrameComponent4
          prop="답변완료"
          prop1="[중분류 | 소분류]"
          dividerBorderBottom="1px solid #e5e5e5"
          divBackgroundColor="#e0daff"
          propColor="#4d33cd"
          onFrameContainerClick={onFrameContainerClick}
        />
        <FrameComponent4
          prop="답변완료"
          prop1="[중분류 | 소분류]"
          dividerBorderBottom="1px solid #e5e5e5"
          divBackgroundColor="#e0daff"
          propColor="#4d33cd"
          onFrameContainerClick={onFrameContainerClick}
        />
        <FrameComponent4
          prop="답변완료"
          prop1="[중분류 | 소분류]"
          dividerBorderBottom="unset"
          divBackgroundColor="#e0daff"
          propColor="#4d33cd"
          onFrameContainerClick={onFrameContainerClick}
        />
      </div>
      <section className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px">
        <div
          className="flex-1 bg-white flex flex-row items-start justify-center pt-3.5 px-3.5 pb-10 cursor-pointer border-t-[1px] border-line-color"
          onClick={onContainerClick}
        >
          <button className="cursor-pointer [border:none] py-3 pr-[21px] pl-5 bg-[transparent] w-[335px] flex flex-row items-center justify-center box-border relative">
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
            <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
              문의하기
            </b>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Contact;
