import { FunctionComponent } from "react";

const FrameComponent27: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] text-left text-xs text-light-gray font-body5medium">
      <header className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          최근 일주일 분양가
        </b>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/xclose.svg"
        />
      </header>
      <div className="self-stretch flex flex-row items-end justify-between py-0 px-5">
        <div className="flex flex-col items-start justify-start gap-[26px]">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            2024.12.25 기준
          </div>
          <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-gray">
            분양일
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[20px] text-gray">
          <div className="flex flex-row items-start justify-start gap-[8px]">
            <div className="rounded-xl bg-white flex flex-row items-center justify-start py-1.5 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
              <div className="flex flex-col items-start justify-start py-0 px-0">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  분양가 높은 순
                </div>
              </div>
              <img className="h-3 w-3 relative" alt="" src="/updownup.svg" />
            </div>
            <div className="rounded-xl bg-white flex flex-row items-center justify-start py-1.5 pr-[11px] pl-[9px] gap-[2px] border-[1px] border-solid border-line-color">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                최신순
              </div>
              <img className="h-3 w-3 relative" alt="" src="/updownup.svg" />
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-3.5 text-sm">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              분양가
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent27;
