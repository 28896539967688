import { FunctionComponent } from "react";

const FrameComponent9: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">인증번호</b>
      <div className="self-stretch flex flex-row items-start justify-start">
        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
          <div className="w-[209px] rounded-lg bg-box-color flex flex-row items-center justify-start py-3 px-4 box-border">
            <img
              className="h-12 w-[209px] relative rounded-lg hidden"
              alt=""
              src="/rectangle.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              123456
            </div>
          </div>
          <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-negative-color">
            인증번호가 틀렸습니다.
          </div>
        </div>
        <button className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-start ml-[-114px]">
          <img
            className="h-12 w-[114px] relative rounded-lg hidden"
            alt=""
            src="/rectangle.svg"
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            인증요청
          </b>
        </button>
      </div>
    </div>
  );
};

export default FrameComponent9;
