import { FunctionComponent } from "react";

const Btn: FunctionComponent = () => {
  return (
    <div className="h-[166px] my-0 mx-[!important] absolute right-[20px] bottom-[138px] flex flex-col items-center justify-start gap-[8px] z-[1] text-center text-xs text-solid1 font-body5medium">
      <img
        className="w-[50px] h-[50px] relative"
        loading="eager"
        alt=""
        src="/group-163.svg"
      />
      <div className="my-0 mx-[!important] absolute w-full top-[calc(50%_-_25px)] right-[0px] left-[0px] flex flex-row items-center justify-center py-[11px] pr-[17px] pl-3.5 box-border">
        <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-[50%] bg-white box-border border-[1px] border-solid border-solid2" />
        <div className="relative tracking-[-0.25px] leading-[14px] font-medium z-[1]">
          <p className="m-0">조회</p>
        </div>
      </div>
      <div className="w-full h-[50px] my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[0px] flex flex-row items-start justify-start gap-[10px]">
        <div className="self-stretch flex-1 relative rounded-[50%] bg-solid1" />
        <img
          className="h-0.5 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
          alt=""
          src="/vector-31.svg"
        />
        <img
          className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
          loading="eager"
          alt=""
          src="/vector-32.svg"
        />
      </div>
    </div>
  );
};

export default Btn;
