import { FunctionComponent } from "react";

const FrameSet: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row flex-wrap items-start justify-start pt-0 pb-5 gap-[12px] text-left text-base text-black font-body5medium border-b-[1px] border-solid border-line-color">
      <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[194px]">
        <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">{`자주하는 질문이 노출됩니다. `}</div>
        <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-gray">
          2023.01.01
        </div>
      </div>
      <img
        className="h-6 w-6 relative overflow-hidden shrink-0"
        alt=""
        src="/chevrondown.svg"
      />
    </div>
  );
};

export default FrameSet;
