import { FunctionComponent } from "react";

const FrameCommunityAlert1: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 text-center text-xs text-negative-color font-body5medium">
      <div className="flex-1 flex flex-row items-center justify-start pt-0 px-5 pb-5 border-b-[1px] border-solid border-line-color">
        <div className="flex-1 flex flex-row flex-wrap items-center justify-start gap-[10px]">
          <img
            className="h-11 w-11 relative"
            loading="eager"
            alt=""
            src="/group1.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[183px]">
            <div className="self-stretch flex flex-row items-center justify-center gap-[24px]">
              <div className="flex-1 flex flex-row items-start justify-start gap-[6px]">
                <div className="h-6 rounded-lg bg-lavenderblush overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border">
                  <b className="relative tracking-[-0.25px] leading-[20px]">
                    HOT
                  </b>
                </div>
                <div className="relative text-base tracking-[-0.25px] leading-[24px] text-dark-grey text-left">
                  <span className="font-medium">{`오늘의 `}</span>
                  <b>렙테일 인기글</b>
                  <span className="font-medium"> 보러가기</span>
                </div>
              </div>
              <img
                className="h-[22px] w-[22px] relative overflow-hidden shrink-0"
                loading="eager"
                alt=""
                src="/chatnew.svg"
              />
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-left text-light-gray">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                1시간 전
              </div>
              <div className="self-stretch w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
                |
              </div>
              <div className="self-stretch w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
                등록개체999+
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameCommunityAlert1;
