import { FunctionComponent } from "react";

const FrameComponent28: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[17px] pl-5 text-left text-sm text-place-holder font-body5medium">
      <div className="flex-1 flex flex-col items-start justify-start gap-[24px]">
        <div className="rounded-lg bg-[#eee] self-stretch flex flex-row items-start justify-start py-3 px-4 relative gap-[10px]">
          
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
            판매자를 검색하세요.
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/searchmd-1.svg"
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between text-base text-black">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            판매자 랭킹
          </b>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray text-right">
            오전 12:00기준
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent28;
