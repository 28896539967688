import { FunctionComponent } from "react";

type FrameComponent24Type = {
  prop?: string;
  prop1?: string;
  xClose?: string;
};

const FrameComponent24: FunctionComponent<FrameComponent24Type> = ({
  prop,
  prop1,
  xClose,
}) => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 text-left text-xs text-light-gray font-body5medium">
      <div className="flex-1 flex flex-row flex-wrap items-start justify-start pt-0 pb-5 pr-[21px] pl-5 gap-[9px] border-b-[1px] border-solid border-line-color">
        <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px] min-w-[162px] text-base text-dark-grey">
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px]">
            <b>닉네임</b>
            <span className="font-medium">{prop}</span>
          </div>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            {prop1}
          </div>
        </div>
        <img
          className="h-[22px] w-[22px] relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src={xClose}
        />
        <div className="w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
          |
        </div>
        <div className="w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
          등록개체999+
        </div>
      </div>
    </div>
  );
};

export default FrameComponent24;
