import { FunctionComponent } from "react";
import FrameMain from "../new/FrameMain";
import InfoFrame from "../new/InfoFrame";
import GroupComponent1 from "../new/GroupComponent1";

const ChatDetail2: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[12px_0px]">
      <FrameMain />
      <InfoFrame />
      <GroupComponent1 />
    </div>
  );
};

export default ChatDetail2;
