import { FunctionComponent } from "react";
import FrameComponent31 from "./FrameComponent31";

const SearchResultContainer: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start pt-0 px-5 pb-2.5 gap-[16px_0px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">렙테일</b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px_0px] text-sm">
        <FrameComponent31 />
        <FrameComponent31 />
      </div>
    </section>
  );
};

export default SearchResultContainer;
