import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

type FrameComponent37Type = {
  prop?: string;
};

const FrameComponent37: FunctionComponent<FrameComponent37Type> = ({
  prop,
}) => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] max-w-full text-left text-base text-black font-body5medium">
      <div className="self-stretch h-[11px] flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full">
        <div className="self-stretch flex-1 relative bg-line-color max-w-full" />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[20px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          개체 상태 인증 사진
        </b>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-sm text-dark-grey">
          <b className="relative tracking-[-0.25px] leading-[22px]">
            판매자 인증 사진
          </b>
          <div className="w-[290px] flex flex-row items-start justify-start gap-[10px]">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                </div>
              </div>
              <img
                className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/imageadd.svg"
              />
            </div>
            <div className="flex-1 flex flex-row items-center justify-center">
              <div className="h-[90px] flex-1 relative">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                    <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                    <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                  </div>
                </div>
                <img
                  className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                  loading="eager"
                  alt=""
                  src="/x.svg"
                />
              </div>
            </div>
            <div className="flex-1 flex flex-row items-center justify-center">
              <div className="h-[90px] flex-1 relative">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                    <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                    <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                  </div>
                </div>
                <img
                  className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                  loading="eager"
                  alt=""
                  src="/x.svg"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
            배송전 개체 상태를 잘 확인할 수 있도록 전체/옆 모습 등 디테일한
            사진을 등록 해주세요.
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] min-h-[186px] text-sm text-dark-grey">
        <b className="relative tracking-[-0.25px] leading-[22px]">
          구매자 인증 사진
        </b>
        <div className="w-[290px] flex flex-row items-start justify-start gap-[10px]">
          <div className="h-[90px] flex-1 relative">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
              </div>
            </div>
            <img
              className="absolute h-[22.22%] w-[22.22%] top-[38.89%] right-[38.89%] bottom-[38.89%] left-[38.89%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="eager"
              alt=""
              src="/imageadd.svg"
            />
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                  <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                </div>
              </div>
              <img
                className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/x.svg"
              />
            </div>
          </div>
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="h-[90px] flex-1 relative">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
                  <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
                  <div className="absolute h-[33.33%] w-[33.33%] top-[32.67%] right-[32.67%] bottom-[34%] left-[34%] hidden" />
                </div>
              </div>
              <img
                className="absolute h-1/5 w-1/5 top-[6.67%] right-[6.67%] bottom-[73.33%] left-[73.33%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/x.svg"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-light-gray">
          배송전 개체 상태를 잘 확인할 수 있도록 전체/옆 모습 등 디테일한 사진을
          등록 해주세요.
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[6px] min-h-[78px] text-smi text-light-gray">
        <div className="self-stretch relative tracking-[-0.25px] leading-[21px] font-medium">
          허위사진 혹은 타인의 사진 도용 시 법적 책임이 따르며 배송 및 개체 상태
          관련하여 렙테일에서는 책임지지 않으니 조심해주세요!
        </div>
        <div className="flex flex-row items-center justify-start gap-[4px] text-xs">
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/btncircleoff.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            동의합니다.
          </div>
        </div>
      </div>
      <div className="self-stretch h-[11px] flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full">
        <div className="self-stretch flex-1 relative bg-line-color max-w-full" />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
        <div className="flex flex-row items-start justify-start py-0 px-5">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            판매자 정보
          </b>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[17px] max-w-full text-dark-grey">
          <div className="self-stretch flex flex-row items-center justify-between py-0 px-5 gap-[20px]">
            <div className="w-[196px] flex flex-row items-center justify-start gap-[10px]">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="flex flex-row items-start justify-start gap-[4px]">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      팔로워999+
                    </div>
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      |
                    </div>
                  </div>
                  <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                    등록개체999+
                  </div>
                </div>
              </div>
            </div>
            <img
              className="h-5 w-5 relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/chevronright1.svg"
            />
          </div>
          
          <div className="self-stretch bg-white box-border flex flex-row items-start justify-center pt-3.5 px-3.5 pb-10 max-w-full border-t-[1px] border-solid border-line-color">
            <Link to="/review/write">
            <button className="cursor-pointer [border:none] py-3 pr-[21px] pl-5 bg-[transparent] w-[335px] flex flex-row items-center justify-center box-border relative max-w-full">
              <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
              <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
                {prop}
              </b>
            </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent37;
