import { FunctionComponent } from "react";
import CompletedBadge from "./CompletedBadge";

const FrameComponent30: FunctionComponent = () => {
  return (
    <div className="w-full flex flex-col items-start justify-start py-0 px-5 box-border gap-[16px_0px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">커뮤니티</b>
      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-5 gap-[0px_12px] text-xs text-solid1 border-b-[1px] border-solid border-line-color">
        <CompletedBadge
          prop="수다수다"
          prop1="게시글의 내용이 노출되는 곳입니다 최대..."
        />
        <div className="h-[98px] w-[98px] relative rounded-lg bg-box-color overflow-hidden shrink-0" />
      </div>
    </div>
  );
};

export default FrameComponent30;
