import { FunctionComponent } from "react";

const FrameDataSubmission: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[119px] text-left text-base text-dark-grey font-body5medium">
      <div className="flex flex-row items-start justify-start py-0 px-5">
        <b className="relative tracking-[-0.25px] leading-[24px]">
          사업자등록증 첨부
        </b>
      </div>
      <div className="self-stretch flex flex-col items-center justify-center relative">
        <div className="w-[130px] h-[130px] absolute my-0 mx-[!important] top-[-107px] left-[20px]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
              <div className="absolute h-[18.46%] w-[18.46%] top-[40.77%] right-[40.77%] bottom-[40.77%] left-[40.77%] flex flex-col items-start justify-start z-[1]">
                <img
                  className="w-6 h-6 relative"
                  loading="eager"
                  alt=""
                  src="/imageadd.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <footer className="self-stretch bg-white flex flex-row items-start justify-center pt-3.5 px-3.5 pb-10 z-[1] border-t-[1px] border-solid border-line-color">
          <button className="cursor-pointer [border:none] py-3 pr-[21px] pl-5 bg-[transparent] w-[335px] flex flex-row items-center justify-center box-border relative">
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
            <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
              다음
            </b>
          </button>
        </footer>
      </div>
    </section>
  );
};

export default FrameDataSubmission;
