import { FunctionComponent } from "react";
import Storyverm from "../components/Storyverm";
import FrameComponent from "../components/FrameComponent";
import Wishlistoff from "../components/Wishlistoff";
import MobileNav from "../components/MobileNav";

const Frame1: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[84px] box-border gap-[16px] tracking-[normal] text-center text-sm text-place-holder font-body5medium">
      <section className="self-stretch flex flex-col items-center justify-start gap-[4px] min-h-[387px] max-w-full text-left text-sm text-place-holder font-body5medium">
        <header className="self-stretch bg-white overflow-hidden flex flex-row items-center justify-between py-2.5 px-5 gap-[20px] top-[0] z-[99] sticky">
          <img
            className="h-5 w-[70px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/layer-1.svg"
          />
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/searchmd.svg"
            />
            <img
              className="h-6 w-6 relative min-h-[24px]"
              alt=""
              src="/chating.svg"
            />
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              alt=""
              src="/bell01.svg"
            />
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              alt=""
              src="/menu01.svg"
            />
          </div>
        </header>
        <Storyverm />
        <div className="self-stretch h-[93px] flex flex-col items-center justify-start gap-[24px] max-w-full">
          <div className="self-stretch h-px relative bg-line-color" />
          <div className="w-[335px] flex flex-row items-start justify-start py-3 px-4 box-border relative gap-[10px] max-w-full mq450:w-[calc(100%_-_40px)]">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
              분양글, 종류명을 검색하세요.
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              alt=""
              src="/searchmd-1.svg"
            />
          </div>
        </div>
        <FrameComponent />
      </section>
      <div className="h-[166px] my-0 mx-[!important] absolute right-[20px] bottom-[138px] [filter:drop-shadow(0px_4px_5.8px_rgba(0,_0,_0,_0.2))] flex flex-col items-center justify-start gap-[8px] z-[2]">
        <img
          className="w-[50px] h-[50px] relative"
          loading="eager"
          alt=""
          src="/group-163.svg"
        />
        <button className="cursor-pointer [border:none] p-0 bg-[transparent] my-0 mx-[!important] absolute w-full top-[calc(50%_-_25px)] right-[0px] left-[0px] flex flex-row items-center justify-center">
          <img
            className="h-[50px] w-[50px] relative rounded-[50%] object-cover"
            alt=""
            src="/ellipse-29@2x.png"
          />
        </button>
        <div className="w-full h-[50px] my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[0px] flex flex-row items-start justify-start gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-solid1" />
          <img
            className="h-0.5 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            loading="eager"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <div className="flex flex-row items-start justify-start pt-0 px-5 pb-16">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          등록된 분양글이 없습니다.
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[30px] text-left text-base text-black">
        <div className="self-stretch h-1 relative bg-box-color overflow-hidden shrink-0" />
        <div className="flex flex-row items-start justify-start py-0 px-5">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            다른 브리더들이 보고 있어요!
          </b>
        </div>
      </div>
      <section className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 px-5 gap-[15px]">
        <Wishlistoff rectangle7="/rectangle-7@2x.png" heart="/heart.svg" />
        <Wishlistoff
          rectangle7="/rectangle-7-1@2x.png"
          heart="/heart-2.svg"
          propOpacity="0.82"
        />
      </section>
      <MobileNav />
    </div>
  );
};

export default Frame1;
