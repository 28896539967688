import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import Nav from "./components/Nav";
import Header from "./components/Header";
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId="126623954205-jug353q7qr57q9sp88qcbkcd9o7lmrbu.apps.googleusercontent.com">
      <Header/>
      <div className="max-w-[540px] m-auto w-full pt-[50px] pb-[78px]">
      <App />
      </div>
      <Nav />
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
