import { FunctionComponent } from "react";

const Btnmobilefull: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[20px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">휴대폰 인증</b>
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-dark-grey">
        <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
          통신사
        </b>
        <div className="self-stretch flex flex-row items-center justify-start py-0 pr-4 pl-0 text-black">
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-start py-3 px-4 relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
                alt=""
                src="/rectangle.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
                렙테일
              </div>
            </div>
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 z-[1] ml-[-40px]"
            alt=""
            src="/chevrondown.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Btnmobilefull;
