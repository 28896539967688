import { FunctionComponent } from "react";
import CircleText from "./CircleText";
import CategoryRegion from "./CategoryRegion";

const ChatButtonGroup: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px_0px] text-center text-xs text-light-gray font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 text-sm text-solid1">
        <div className="flex-1 rounded-lg bg-solid2 flex flex-row items-start justify-start py-3 px-5 gap-[20px]">
          <div className="h-[46px] w-[335px] relative rounded-lg bg-solid2 hidden" />
          <b className="relative tracking-[-0.25px] leading-[22px] z-[1]">
            안내
          </b>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey text-left z-[1]">
            렙테일을 통한 안심 계좌이체를 이용해 보세요!
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
        <CircleText
          circleLabelPlaceholder={`안녕하세요! 분양받고싶어요.
생물에 관해 좀더 자세히 설명 들을 수 있을까요?`}
        />
        <CategoryRegion />
      </div>
      <img
        className="self-stretch relative max-w-full overflow-hidden max-h-full"
        loading="eager"
        alt=""
        src="/new/vector-123.svg"
      />
      <div className="flex flex-row items-start justify-start py-0 pr-0 pl-[21px] text-left">
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">{`2024년 1월 19일 `}</div>
      </div>
    </section>
  );
};

export default ChatButtonGroup;
