import { FunctionComponent } from "react";

const Maincommunityframe: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border max-w-full text-left text-sm text-place-holder font-body5medium">
      <div className="flex flex-col w-full items-start justify-start gap-[24px] shrink-0">
        <div className="w-full flex flex-row items-start justify-start py-0 px-5 box-border max-w-full">
          <div className="bg-[#eee] flex-1 flex flex-row rounded-lg items-start justify-start py-3 px-4 box-border relative gap-[10px] max-w-full">
            
            <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
              게시글 제목, 내용을 검색하세요.
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              alt=""
              src="/searchmd-1.svg"
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-end justify-start gap-[20px] text-center text-base text-dark-grey">
          <div className="overflow-x-auto flex flex-row items-end justify-between pt-1.5 w-full ">
            <div className="h-px w-fullrelative bg-line-color shrink-0" />
            <div className="shrink-0 flex flex-col items-center justify-center">
              <div className="self-stretch flex flex-row items-start justify-around gap-[20px] mq450:flex-wrap">
                <div className="w-[130px] h-[34px] box-border flex flex-col items-center justify-start py-0 px-2.5 text-solid1 border-b-[2px] border-solid border-solid1">
                  <b className="relative tracking-[-0.25px] leading-[24px]">
                    전체
                  </b>
                </div>
                <div className="w-[130px] flex flex-col items-center justify-start py-0 px-5 box-border">
                  <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                    자유게시판
                  </div>
                </div>
                <div className="w-[130px] flex flex-col items-center justify-start py-0 px-5 box-border">
                  <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                    정보공유
                  </div>
                </div>
                <div className="w-[130px] flex flex-col items-center justify-center py-0 px-5 box-border">
                  <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                    헬프미닥터
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-[45px] text-left text-xs text-gray mq450:pl-5 mq450:pr-5 mq450:box-border">
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <div className="flex flex-row items-start justify-start">
                <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
                  <div className="flex flex-row items-center justify-start gap-[2px]">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      수다수다
                    </div>
                    <img
                      className="h-3 w-3 relative overflow-hidden shrink-0"
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="rounded-xl bg-white overflow-hidden flex flex-col items-start justify-start py-1 pr-[11px] pl-[9px] border-[1px] border-solid border-line-color">
                  <div className="flex flex-row items-center justify-start gap-[2px]">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      조회 높은 순
                    </div>
                    <img
                      className="h-3 w-3 relative overflow-hidden shrink-0"
                      alt=""
                      src="/chevrondown.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Maincommunityframe;
