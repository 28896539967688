import { FunctionComponent } from "react";
import FrameComponent8 from "../components/FrameComponent8";
import FrameComponent7 from "../components/FrameComponent7";
import Component3 from "../components/Component3";

const Frame23: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[114px]">
      <main className="self-stretch flex flex-col items-center justify-start gap-[22px] text-left text-base text-black font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            판매자 정보 관리
          </b>
        </header>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-[19px]">
          <b className="w-[153px] relative tracking-[-0.25px] leading-[24px] flex items-center pr-5">
            개인 판매자 정보 관리
          </b>
        </div>
        <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px] text-left text-base text-dark-grey font-body5medium">
          <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
            이름
          </b>
          <div className="self-stretch flex flex-row items-center justify-start py-3 px-4 relative text-black">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              홍길동
            </div>
          </div>
        </section>
        <FrameComponent8 />
        <FrameComponent7 />
      </main>
      <Component3 prop="수정하기" propMarginTop="unset" />
    </div>
  );
};

export default Frame23;
