import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const MainFrame: FunctionComponent = () => {
  const navigate = useNavigate();

  const onInputDefaultContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <section className="self-stretch h-[476px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[30px] text-left text-lg text-black font-body5medium">
      <div className="flex flex-row items-start justify-start pt-0 px-0 pb-3">
        <b className="relative tracking-[-0.25px] leading-[26px]">
          닉네임을 등록해주세요
        </b>
      </div>
      <div className="flex flex-row items-start justify-start py-0 pr-[5px] pl-0">
        <div className="flex flex-col items-end justify-start">
          <img
            className="w-[74px] h-[74px] relative object-cover"
            alt=""
            src="/--1@2x.png"
          />
          <img
            className="w-5 h-5 relative z-[1] mt-[-21px]"
            loading="eager"
            alt=""
            src="/edit.svg"
          />
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base">
        <b className="w-[87px] relative tracking-[-0.25px] leading-[24px] inline-block">
          닉네임
        </b>
        <div
          className="rounded-lg bg-[#eee] self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative cursor-pointer text-place-holder"
        
        >
          
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            닉네임을 입력해 주세요.
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainFrame;
