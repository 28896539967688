import { FunctionComponent } from "react";

const FramerepresentativeName: FunctionComponent = () => {
  return (
    <section className="self-stretch h-[318px] flex flex-col items-center justify-start gap-[20px] text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch h-[62px] flex flex-col items-start justify-start">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            판매자 등록하기
          </b>
        </header>
        <div className="w-52 h-1 relative bg-solid1 z-[1]" />
        <div className="self-stretch h-0 relative bg-box-color" />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-[19px] text-black">
        <b className="w-[167px] relative tracking-[-0.25px] leading-[24px] flex items-center pr-5">
          사업자 판매자 정보 입력
        </b>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start py-0 px-5 gap-[12px]">
        <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center">
          대표자
        </b>
        <div className="self-stretch flex flex-row items-center justify-center text-black">
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
                loading="eager"
                alt=""
                src="/rectangle.svg"
              />
              <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
                대표자명을 입력하세요.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[12px]">
        <b className="relative tracking-[-0.25px] leading-[24px]">상호명</b>
        <div className="self-stretch flex flex-row items-center justify-center text-black">
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
                loading="eager"
                alt=""
                src="/rectangle.svg"
              />
              <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
                상호명을 입력하세요.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FramerepresentativeName;
