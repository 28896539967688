import { FunctionComponent } from "react";

const EllipseGroup: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 gap-[10px] text-left text-sm text-gray font-body5medium">
      <div className="flex flex-col items-start justify-start gap-[40px]">
        <div className="w-11 h-11 relative rounded-[50%] bg-gainsboro-200" />
        <div className="w-11 h-11 relative rounded-[50%] bg-gainsboro-200" />
        <div className="w-11 h-11 relative rounded-[50%] bg-gainsboro-200" />
        <div className="w-11 h-11 relative rounded-[50%] bg-gainsboro-200" />
      </div>
      <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[34px] pl-0 box-border gap-[36px] min-w-[146px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-start justify-start gap-[10px]">
            <div className="flex flex-row items-start justify-start">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                닉네임
              </div>
            </div>
            <div className="flex flex-row items-center justify-start py-0 pr-[9px] pl-0 gap-[4px] text-dark-grey">
              <img
                className="h-5 w-5 relative"
                loading="eager"
                alt=""
                src="/starfull-15.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                5점
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] font-medium text-black">
            최신 리뷰 내용이 노출됩니다.
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-start justify-start gap-[10px]">
            <div className="flex flex-row items-start justify-start">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                닉네임
              </div>
            </div>
            <div className="flex flex-row items-center justify-start py-0 pr-[9px] pl-0 gap-[4px] text-dark-grey">
              <img
                className="h-5 w-5 relative"
                loading="eager"
                alt=""
                src="/starfull-15.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                4점
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] font-medium text-black">
            최신 리뷰 내용이 노출됩니다.
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-start justify-start gap-[10px]">
            <div className="flex flex-row items-start justify-start">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                닉네임
              </div>
            </div>
            <div className="flex flex-row items-center justify-start py-0 pr-[9px] pl-0 gap-[4px] text-dark-grey">
              <img
                className="h-5 w-5 relative"
                loading="eager"
                alt=""
                src="/starfull-15.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                3점
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] font-medium text-black">
            최신 리뷰 내용이 노출됩니다.
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-start justify-start gap-[10px]">
            <div className="flex flex-row items-start justify-start">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                닉네임
              </div>
            </div>
            <div className="flex flex-row items-center justify-start py-0 pr-[9px] pl-0 gap-[4px] text-dark-grey">
              <img
                className="h-5 w-5 relative"
                loading="eager"
                alt=""
                src="/starfull-15.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                2점
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] font-medium text-black">
            최신 리뷰 내용이 노출됩니다.
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end justify-start gap-[64px] text-xs text-light-gray">
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          10시간 전
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          1일전
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          일주일전
        </div>
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          일주일전
        </div>
      </div>
    </section>
  );
};

export default EllipseGroup;
