import { FunctionComponent } from "react";
import AppBarInstance from "./AppBarInstance";

const FrameComponent21: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-center justify-start gap-[20px] text-center text-xs text-solid1 font-body5medium">
      <AppBarInstance />
      <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] inline-block text-black text-left pl-5 pr-5">
        게시글 제목을 입력하세요
      </b>
    </div>
  );
};

export default FrameComponent21;
