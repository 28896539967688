import { FunctionComponent } from "react";
import FrameComponent32 from "../components/FrameComponent32";
import ChatButtonGroup from "../new/ChatButtonGroup";
import FrameGroup from "../new/FrameGroup";
import GroupComponent from "../new/GroupComponent";

const ChatDetail1: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[14px_0px]">
      <FrameComponent32 />
      <main className="self-stretch flex flex-col items-start justify-start py-0 pr-5 pl-[19px] gap-[20px_0px]">
        <ChatButtonGroup />
        <FrameGroup />
      </main>
      <GroupComponent />
    </div>
  );
};

export default ChatDetail1;
