import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameComponent31Type = {
  prop?: string;

  /** Style props */
  appBarTop?: CSSProperties["top"];
  appBarPosition?: CSSProperties["position"];
  appBarHeight?: CSSProperties["height"];
  rectangleIconPosition?: CSSProperties["position"];
  rectangleIconTop?: CSSProperties["top"];
  inputFlex?: CSSProperties["flex"];
};

const FrameComponent31: FunctionComponent<FrameComponent31Type> = ({
  prop,
  appBarTop,
  appBarPosition,
  appBarHeight,
  rectangleIconPosition,
  rectangleIconTop,
  inputFlex,
}) => {
  const frameSection2Style: CSSProperties = useMemo(() => {
    return {
      top: appBarTop,
      position: appBarPosition,
      height: appBarHeight,
    };
  }, [appBarTop, appBarPosition, appBarHeight]);

  const appBarStyle: CSSProperties = useMemo(() => {
    return {
      position: rectangleIconPosition,
      top: rectangleIconTop,
    };
  }, [rectangleIconPosition, rectangleIconTop]);

  const b3Style: CSSProperties = useMemo(() => {
    return {
      flex: inputFlex,
    };
  }, [inputFlex]);

  return (
    <section
      className="self-stretch flex flex-col items-center justify-start gap-[20px] top-[0] z-[99] sticky max-w-full text-left text-sm text-black font-body5medium"
      style={frameSection2Style}
    >
      <header
        className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 px-5 relative gap-[12px] text-center text-base text-black font-body5medium"
        style={appBarStyle}
      >
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b
          className="relative tracking-[-0.25px] leading-[24px] z-[1]"
          style={b3Style}
        >
          {prop}
        </b>
      </header>
      <div className="w-[335px] flex flex-row items-start justify-between py-3 px-4 box-border relative gap-[20px] max-w-full mq450:w-[calc(100%_-_40px)]">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
          닉네임
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/searchmd-1.svg"
        />
      </div>
    </section>
  );
};

export default FrameComponent31;
