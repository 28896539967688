import { FunctionComponent } from "react";
import TitleFrame from "../components/TitleFrame";
import BusinessFrame from "../components/BusinessFrame";
import StepFrame from "../components/StepFrame";

const Frame20: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[30px]">
      <div className="self-stretch flex flex-col items-start justify-start">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            판매자 등록하기
          </b>
        </header>
        <div className="w-[114px] h-1 relative bg-solid1 z-[1]" />
        <div className="self-stretch h-0 relative bg-box-color" />
      </div>
      <TitleFrame />
      <BusinessFrame />
      <StepFrame />
    </div>
  );
};

export default Frame20;
