import { FunctionComponent } from "react";
import FrameComponent31 from "../components/FrameComponent31";
import FrameComponent30 from "../components/FrameComponent30";
import FrameComponent29 from "../components/FrameComponent29";
import Nav1 from "../components/Nav1";

const Frame59: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px] tracking-[normal]">
      <FrameComponent31 prop="검색" />
      <FrameComponent30 />
      <FrameComponent29 />
      <Nav1
        prizeoff="/prizeoff.svg"
        commuoff="/commuon.svg"
        propGap="18.75px"
        propColor="#333"
        propColor1="#4d33cd"
      />
    </div>
  );
};

export default Frame59;
