import { FunctionComponent } from "react";

const FrameBuyerInfo: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[9px] shrink-0 text-left text-sm text-gray font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
        <div className="flex flex-col items-start justify-start gap-[16px]">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            구매자
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            휴대폰번호
          </div>
        </div>
        <div className="w-[180px] flex flex-col items-start justify-start gap-[16px] text-right text-black">
          <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
            성함
          </div>
          <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
            010-0000-0000
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[15px] text-base text-black">
        <div className="flex flex-row items-end justify-start gap-[6px]">
          <div className="flex flex-col items-start justify-start pt-0 px-0 pb-[5px]">
            <b className="relative tracking-[-0.25px] leading-[24px]">
              배송 방법 선택
            </b>
          </div>
          <div className="flex flex-col items-start justify-start pt-0 px-0 pb-2">
            <img
              className="w-[18px] h-[18px] relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/.svg"
            />
          </div>
          <div className="rounded-lg bg-lavender shadow-[0px_2px_5px_rgba(0,_0,_0,_0.15)] flex flex-row items-center justify-center py-2.5 pr-1.5 pl-2.5 z-[1] text-center text-xs text-dark-grey">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              <p className="m-0">판매자가 배송 가능한 방법 중에</p>
              <p className="m-0">원하시는 배송 방법을 선택할 수 있어요!</p>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-place-holder">
          <div className="self-stretch flex flex-row items-start justify-start py-3 px-4 relative gap-[10px]">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              배송 방법을 선택하세요
            </div>
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
              alt=""
              src="/chevrondown.svg"
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-xs text-light-gray">
            <div className="self-stretch relative tracking-[-0.25px] leading-[20px] font-medium">
              배송 과정에서의 사고 문제는 입/분양자 분들 및 배송업체(배송업체
              배송 선택시)에게 있으며 렙테일에서는 책임질 수 없어요!
            </div>
            <div className="flex flex-row items-end justify-start gap-[8px]">
              <div className="flex flex-col items-start justify-start pt-0 px-0 pb-[5px]">
                <div className="flex flex-col items-start justify-start gap-[40px]">
                  <div className="flex flex-row items-center justify-start gap-[4px]">
                    <img
                      className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
                      loading="eager"
                      alt=""
                      src="/btncircleoff.svg"
                    />
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      동의합니다.
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-[4px] text-base text-black">
                    <b className="relative tracking-[-0.25px] leading-[24px]">
                      결제 수단 선택
                    </b>
                    <img
                      className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
                      loading="eager"
                      alt=""
                      src="/.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-lavender shadow-[0px_2px_5px_rgba(0,_0,_0,_0.15)] flex flex-row items-center justify-center py-2.5 pr-1.5 pl-2.5 z-[1] text-center text-dark-grey">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  <p className="m-0">입양을 확정할 때까지 고객님의 금액을</p>
                  <p className="m-0">렙테일이 안전하게 보관하고 있어요!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameBuyerInfo;
