import { FunctionComponent } from "react";

const FrameComponent38: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start py-0 pr-3.5 pl-5 box-border max-w-full text-left text-sm text-gray font-body5medium">
      <div className="flex-1 flex flex-col items-start justify-start gap-[16px] max-w-full">
        <div className="flex flex-row items-start justify-start pt-0 pb-1 pr-5 pl-0 text-base text-black">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            구매 정보
          </b>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between py-0 pr-1.5 pl-0 gap-[20px] mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-[16px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              구매 일시
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              상품명/관리번호
            </div>
          </div>
          <div className="w-[180px] flex flex-col items-start justify-start gap-[16px] text-right text-black">
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium whitespace-nowrap">
              2024.01.01 0:00:00
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              품명을 입력하세요
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between py-0 pr-1.5 pl-0 gap-[20px] mq450:flex-wrap">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            결제수단
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey text-right flex items-center shrink-0">
            신용카드
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between py-0 pr-1.5 pl-0 gap-[20px] mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start gap-[16px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              결제구분
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              판매 수수료
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              상품 수수료
            </div>
          </div>
          <div className="w-[180px] flex flex-col items-start justify-start gap-[16px] text-right text-dark-grey">
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              일시불
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
              000,000,000원
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
              000,000,000원
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="w-[72px] flex flex-col items-start justify-start gap-[16px] min-w-[72px] mq450:flex-1">
            <div className="w-[68px] relative tracking-[-0.25px] leading-[22px] font-medium flex items-center pr-5">
              상품금액
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium pr-5">
              배송 방식
            </div>
            <div className="w-14 relative tracking-[-0.25px] leading-[22px] font-medium flex items-center pr-5">
              배송비
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium pr-5">
              판매 상태
            </div>
            <b className="relative text-base tracking-[-0.25px] leading-[24px] text-solid1">
              총 결제금액
            </b>
          </div>
          <div className="w-[186px] flex flex-col items-start justify-start gap-[16px] min-w-[186px] text-right text-dark-grey mq450:flex-1">
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium pr-1.5">
              000,000,000원
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium pr-1.5">
              배송 업체 배송
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium pr-1.5">
              000,000,000원
            </div>
            <div className="self-stretch flex flex-row items-start justify-end text-center text-xs text-solid1">
              <div className="w-[37px] rounded-lg bg-solid2 overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border">
                <b className="relative tracking-[-0.25px] leading-[20px]">
                  완료
                </b>
              </div>
            </div>
            <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px] inline-block text-solid1 pr-1.5">
              500,000,000원
            </b>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent38;
