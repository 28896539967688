import { FunctionComponent } from "react";

type DefaultType = {
  prop?: string;
  prop1?: string;
};

const Default: FunctionComponent<DefaultType> = ({ prop, prop1 }) => {
  return (
    <div className="self-stretch flex flex-row items-center justify-start py-4 px-5 relative text-center text-sm text-black font-body5medium">
      <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-3xs bg-box-color" />
      <div className="flex flex-col items-start justify-start gap-[2px] z-[1]">
        <b className="relative tracking-[-0.25px] leading-[22px]">{prop}</b>
        <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-dark-grey text-left">
          {prop1}
        </div>
      </div>
    </div>
  );
};

export default Default;
