import { FunctionComponent } from "react";

type RecentBidDefaultType = {
  categoryFrame?: string;
  heart1?: string;
};

const RecentBidDefault: FunctionComponent<RecentBidDefaultType> = ({
  categoryFrame,
  heart1,
}) => {
  return (
    <section className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 px-5 gap-[15px] text-left text-sm text-black font-body5medium">
      <div className="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[104px]">
        <div className="self-stretch h-40 relative">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-lg max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src={categoryFrame}
          />
          <img
            className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
            loading="eager"
            alt=""
            src="/heart-2.svg"
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-start justify-start gap-[4px] text-light-gray">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              카테고리
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              |
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              지역
            </div>
          </div>
          <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
            상품명을 입력하세요
          </div>
          <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px]">
            200,000원
          </b>
          <div className="flex flex-row items-start justify-start gap-[8px] text-xs text-place-holder">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <img
                className="h-3 w-3 relative"
                loading="eager"
                alt=""
                src="/eyeopen.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                999+
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <input
                className="border-lightgray accent-lightgray cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
                checked={true}
                type="radio"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                999+
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[104px]">
        <div className="self-stretch h-40 relative">
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-lg max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-7@2x.png"
          />
          <img
            className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
            loading="eager"
            alt=""
            src={heart1}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-start justify-start gap-[4px] text-light-gray">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              카테고리
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              |
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              지역
            </div>
          </div>
          <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium">
            상품명을 입력하세요
          </div>
          <b className="self-stretch relative text-base tracking-[-0.25px] leading-[24px]">
            200,000원
          </b>
          <div className="flex flex-row items-start justify-start gap-[8px] text-xs text-place-holder">
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <img
                className="h-3 w-3 relative"
                loading="eager"
                alt=""
                src="/eyeopen.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                999+
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <input
                className="border-lightgray accent-lightgray cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
                checked={true}
                type="radio"
              />
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                999+
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentBidDefault;
