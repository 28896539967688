import { FunctionComponent } from "react";

export type Component1Type = {
  prop?: string;
};

const Component1: FunctionComponent<Component1Type> = ({ prop }) => {
  return (
    <section className="self-stretch bg-white flex flex-row items-start justify-center pt-3.5 px-3.5 pb-10 text-center text-xs text-light-gray font-body5medium border-t-[1px] border-solid border-line-color">
      <div className="flex-1 flex flex-row flex-wrap items-start justify-start py-0 px-1.5 gap-[0px_15px]">
        <div className="w-6 flex flex-col items-center justify-start gap-[4px_0px]">
          <img
            className="w-6 h-6 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/new/btnheartoff.svg"
          />
          <div className="self-stretch flex flex-row items-center justify-center py-0 px-1.5">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              11
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-row items-start justify-start gap-[0px_8px] min-w-[192px]">
          <button className=" w-full  cursor-pointer [border:none] py-3 pr-[43px] pl-[45px] bg-[transparent] flex flex-row items-center justify-center relative">
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
            <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
              {prop}
            </b>
          </button>
          <button className="w-full  cursor-pointer [border:none] py-3 pr-[43px] pl-[45px] bg-[transparent] flex flex-row items-center justify-center relative">
            <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid2" />
            <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-solid1 text-center z-[1]">
              채팅하기
            </b>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Component1;
