import { FunctionComponent } from "react";
import RecentBidDefault from "../components/RecentBidDefault";

const Frame73: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[184px] box-border gap-[18px]">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          찜 목록
        </b>
      </header>
      <RecentBidDefault
        categoryFrame="/rectangle-7@2x.png"
        heart1="/heart-2.svg"
      />
      <RecentBidDefault
        categoryFrame="/rectangle-7@2x.png"
        heart1="/heart-2.svg"
      />
    </div>
  );
};

export default Frame73;
