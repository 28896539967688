import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const FrameComponent19: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[12px] min-h-[192px] max-w-full text-center text-sm text-solid1 font-body5medium">
      <div className="self-stretch rounded-3xs bg-box-color flex flex-row items-center justify-between py-1.5 pr-2.5 pl-5 box-border gap-[20px] max-w-full mq450:flex-wrap">
        <div className="h-[76px] w-[335px] relative rounded-3xs bg-box-color hidden max-w-full" />
        <div className="flex flex-col items-start justify-start gap-[2px]">
          <b className="relative tracking-[-0.25px] leading-[22px] z-[1]">
            렙테일 사용서
          </b>
          <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-dark-grey z-[1]">
            렙테일, 어렵지 않아요!
          </div>
        </div>
        <img
          className="h-16 w-[69px] relative object-cover z-[2]"
          loading="eager"
          alt=""
          src="/m001t0305-c-e-commerce-25aug22-2@2x.png"
        />
      </div>
      <div className="self-stretch rounded-3xs bg-solid2 flex flex-row flex-wrap items-center justify-start py-1.5 pr-2.5 pl-5 box-border gap-[36px] max-w-full mq450:gap-[36px]">
        <div className="h-[76px] w-[335px] relative rounded-3xs bg-solid2 hidden max-w-full" />
        <div className="flex-1 flex flex-col items-start justify-start gap-[2px] min-w-[130px]">
          <Link className="no-underline" to="https://www.cafe24.com/" target="_blank" rel="noopener noreferrer">
            <div className="flex flex-row items-center justify-start gap-[10px]">
              <b className="relative tracking-[-0.25px] leading-[22px] z-[1]">
                렙테일샵 바로가기
              </b>
              <img
                className="h-5 w-5 relative overflow-hidden shrink-0 z-[1]"
                loading="eager"
                alt=""
                src="/chevronright.svg"
              />
            </div>
            <div className="relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-dark-grey text-left z-[1]">
              먹이부터 용품까지, 다양한 상품들이 있어요!
            </div>
          </Link>
        </div>
        <img
          className="h-16 w-[69px] relative object-cover z-[1]"
          loading="eager"
          alt=""
          src="/m001t0305-c-e-commerce-25aug22-1@2x.png"
        />
      </div>
    </section>
  );
};

export default FrameComponent19;
