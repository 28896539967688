import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent19 from "../components/FrameComponent19";
import FrameComponent18 from "../components/FrameComponent18";
import MainFrame1 from "../components/MainFrame1";
import Component4 from "../components/Component4";
import Nav from "../components/Nav";

const Frame42: FunctionComponent = () => {
  const navigate = useNavigate();

  const onNestingFramesContainerClick = useCallback(() => {
    navigate("/1");
  }, [navigate]);

  const onNestingFramesContainer1Click = useCallback(() => {
    navigate("/2");
  }, [navigate]);

  const onNestingFramesContainer2Click = useCallback(() => {
    navigate("/3");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start tracking-[normal] text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-5 box-border top-[0] z-[99] sticky max-w-full">
        <header className="flex-1 bg-white overflow-hidden flex flex-row items-center justify-between py-2.5 px-5 box-border gap-[20px] max-w-full">
          <img
            className="h-5 w-[70px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/layer-1.svg"
          />
          <div className="flex flex-row items-start justify-start gap-[16px]">
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/searchmd.svg"
            />
            <img
              className="h-6 w-6 relative min-h-[24px]"
              loading="eager"
              alt=""
              src="/chating.svg"
            />
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/bell01.svg"
            />
            <img
              className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
              loading="eager"
              alt=""
              src="/menu01.svg"
            />
          </div>
        </header>
      </div>
      <div className="self-stretch h-[72px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <div className="w-[260px] flex flex-row items-center justify-start gap-[10px]">
          <img
            className="h-10 w-10 relative object-cover"
            loading="eager"
            alt=""
            src="/ellipse-67@2x.png"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
            닉네임
          </b>
        </div>
      </div>
      <FrameComponent19 />
      <FrameComponent18 />
      <MainFrame1/>
      <Component4 />
      
    </div>
  );
};

export default Frame42;
