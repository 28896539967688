import { FunctionComponent } from "react";
import FramePersonalData from "../components/FramePersonalData";
import Component2 from "../components/Component2";

const Frame29: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[236px]">
      <FramePersonalData />
      <Component2 prop="다음" />
    </div>
  );
};

export default Frame29;
