import { FunctionComponent } from "react";

const Frame62: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[195px] box-border gap-[131px] text-left text-sm text-place-holder font-body5medium">
      <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-left text-base text-black font-body5medium">
        <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] relative gap-[12px] text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
            받은 분양 평가
          </b>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/xclose.svg"
          />
        </header>
        <div className="flex flex-row items-start justify-start py-0 px-[21px]">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            받은 분양 평가
          </b>
        </div>
      </section>
      <div className="flex flex-row items-start justify-start pt-0 px-5 pb-5">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          받은 분양 평가가 없습니다.
        </div>
      </div>
      <div className="self-stretch h-[68px] flex flex-col items-start justify-start gap-[20px] text-base text-black">
        <div className="self-stretch h-1 relative bg-box-color" />
        <div className="flex flex-row items-start justify-start py-0 px-5">
          <b className="relative tracking-[-0.25px] leading-[24px]">
            최신 리뷰 0개
          </b>
        </div>
      </div>
      <div className="w-[157px] relative tracking-[-0.25px] leading-[22px] font-medium inline-block pl-5 pr-5">
        받은 리뷰가 없습니다.
      </div>
    </div>
  );
};

export default Frame62;
