import { FunctionComponent } from "react";
import CompletedBadge from "./CompletedBadge";

const CommunityListArea: FunctionComponent = () => {
  return (
    <div className="w-full flex flex-col items-start justify-start py-0 px-5 box-border gap-[10px_0px]">
      <CompletedBadge
        prop="짝꿍찾기"
        prop1="게시글의 내용이 노출되는 곳입니다 ㅎㅎㅎㅎㅎㅎㅎㅎㅎ최대..."
        propFlex="unset"
        propMinWidth="unset"
        propPadding="0px 0px 20px"
        propAlignSelf="unset"
      />
      <button className="cursor-pointer [border:none] py-3 pr-5 pl-[22px] bg-[transparent] self-stretch flex flex-row items-center justify-center relative">
        <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-box-color" />
        <div className="relative text-base tracking-[-0.25px] leading-[24px] font-medium font-body5medium text-black text-center z-[1]">
          커뮤니티 글 더보기
        </div>
      </button>
    </div>
  );
};

export default CommunityListArea;
