import { FunctionComponent } from "react";

const AppBarFrame1: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] text-left text-base text-place-holder font-body5medium">
      <header className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-5 pl-[55px] relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/rectangle.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          게시물 등록
        </b>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/xclose.svg"
        />
      </header>
      <div className="self-stretch flex flex-col items-start justify-start py-0 px-5 gap-[14px]">
        <div className="self-stretch flex flex-row items-center justify-start py-0 pr-4 pl-0">
          <div className="flex-1 flex flex-row items-center justify-center">
            <div className="flex-1 flex flex-row items-center justify-start py-3 px-4 relative">
              <img
                className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
                alt=""
                src="/rectangle.svg"
              />
              <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
                카테고리
              </div>
            </div>
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 z-[1] ml-[-40px]"
            alt=""
            src="/chevrondown.svg"
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-center py-3 pr-[21px] pl-4 relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            제목을 입력해주세요.
          </div>
        </div>
        <div className="self-stretch rounded-lg bg-solid2 flex flex-row items-start justify-start py-3 pr-[50px] pl-5 gap-[22px] text-center text-sm text-solid1">
          <div className="h-[46px] w-[335px] relative rounded-lg bg-solid2 hidden" />
          <b className="relative tracking-[-0.25px] leading-[22px] z-[1]">
            안내
          </b>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey z-[1]">
            판매 및 상품글에 대한 질문은 금지입니다.
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBarFrame1;
