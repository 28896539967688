import { FunctionComponent } from "react";
import FrameSearchInput1 from "./FrameSearchInput1";
import FrameSearchInput from "./FrameSearchInput";
import { Link } from "react-router-dom";

const FrameComponent2: FunctionComponent = () => {
  return (
    <section className="max-w-full overflow-x-auto flex flex-row items-start justify-start py-0 pr-0 pl-5 box-border text-left text-sm text-black font-body5medium">
      <div className="shrink-0  flex flex-row items-start justify-start gap-[15px] my-2">
        <Link className="no-underline text-[#000]"to="/reptail/detail1">
        <FrameSearchInput1
          rectangleHeart="/rectangle-7@2x.png"
          heart="/heart.svg"
        />
        </Link>
        <Link className="no-underline text-[#000]"to="/reptail/detail1">
        <FrameSearchInput1
          rectangleHeart="/rectangle-7-1@2x.png"
          heart="/heart-2.svg"
        />
        </Link>
        <Link className="no-underline text-[#000]"to="/reptail/detail1">
        <FrameSearchInput />
        </Link>
        <Link className="no-underline text-[#000]"to="/reptail/detail1">
        <FrameSearchInput propOpacity="0.82" />
        </Link>
      </div>
    </section>
  );
};

export default FrameComponent2;
