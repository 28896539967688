import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const FrameComponent18: FunctionComponent = () => {
  const navigate = useNavigate();

  const onFrameArticleContainer1Click = useCallback(() => {
    navigate("/22");
  }, [navigate]);

  const onFrameArticleContainer3Click = useCallback(() => {
    // Please sync "채팅목록" to the project
  }, []);

  const onFrameArticleContainer4Click = useCallback(() => {
    navigate("/23");
  }, [navigate]);

  const onFrameArticleContainer5Click = useCallback(() => {
    navigate("/24");
  }, [navigate]);

  const onFrameArticleContainer6Click = useCallback(() => {
    // Please sync "정산내역(판매자)" to the project
  }, []);

  return (
    <section className="self-stretch h-[445px] flex flex-col items-start justify-start gap-[10px] max-w-full text-left text-base text-black font-body5medium">
      <div className="w-[157px] flex flex-row items-start justify-start py-0 px-5 box-border">
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px]">
          나의활동
        </b>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1.5 box-border max-w-full">
        <div className="flex-1 flex flex-col items-start justify-start max-w-full">
          <div className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full">
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                판매 내역
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onFrameArticleContainer1Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                구매 내역
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full">
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                판매자 계정 관리
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
                loading="eager"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onFrameArticleContainer3Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                채팅 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onFrameArticleContainer4Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                찜 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onFrameArticleContainer5Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                팔로우 목록
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-3.5 px-5 box-border max-w-full cursor-pointer"
            onClick={onFrameArticleContainer6Click}
          >
            <div className="flex-1 flex flex-row items-center justify-start py-0 pr-0 pl-2.5 box-border gap-[30px] max-w-full">
              <div className="w-[271px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center shrink-0">
                정산 내역
              </div>
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0 hidden min-h-[24px]"
                alt=""
                src="/chevronright.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch h-px relative bg-line-color" />
    </section>
  );
};

export default FrameComponent18;
