import { FunctionComponent } from "react";

const UnreadCommentFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 text-left text-base text-dark-grey font-body5medium">
      <div className="flex-1 flex flex-row items-start justify-start pt-0 px-5 pb-5 border-b-[1px] border-solid border-line-color">
        <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[10px]">
          <div className="flex flex-row items-end justify-end">
            <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
          </div>
          <div className="flex-1 flex flex-row items-start justify-start gap-[9px] min-w-[183px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
              <div className="self-stretch relative tracking-[-0.25px] leading-[24px]">
                <b>닉네임이최대로길어진케이스</b>
                <span className="font-medium">님이 댓글에 공감하였습니다.</span>
              </div>
              <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                  10시간 전
                </div>
                <div className="self-stretch w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
                  |
                </div>
                <div className="self-stretch w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
                  등록개체999+
                </div>
              </div>
            </div>
            <img
              className="h-[22px] w-[22px] relative overflow-hidden shrink-0"
              loading="eager"
              alt=""
              src="/chatnew.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnreadCommentFrame;
