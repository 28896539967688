import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type CompletedBadgeType = {
  prop?: string;
  prop1?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propMinWidth?: CSSProperties["minWidth"];
  propPadding?: CSSProperties["padding"];
  propAlignSelf?: CSSProperties["alignSelf"];
};

const CompletedBadge: FunctionComponent<CompletedBadgeType> = ({
  prop,
  prop1,
  propFlex,
  propMinWidth,
  propPadding,
  propAlignSelf,
}) => {
  const completedBadgeStyle: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
      padding: propPadding,
    };
  }, [propFlex, propMinWidth, propPadding]);

  const frameDivStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div
      className="flex-1 flex flex-col items-start justify-start gap-[8px_0px] min-w-[146px] text-left text-xs text-solid1 font-body5medium"
      style={completedBadgeStyle}
    >
      <div className="flex flex-row items-center justify-start gap-[0px_8px] text-center">
        <div className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border">
          <b className="relative tracking-[-0.25px] leading-[20px]">{prop}</b>
        </div>
        <b className="relative tracking-[-0.25px] leading-[20px] text-gray">
          닉네임
        </b>
      </div>
      <div
        className="self-stretch flex flex-col items-start justify-start gap-[6px_0px] text-base text-black"
        style={frameDivStyle}
      >
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
          게시글 제목을 입력하세요
        </div>
        <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-light-gray">
          {prop1}
        </div>
      </div>
      <div className="flex flex-row items-start justify-start gap-[0px_10px] text-place-holder">
        <div className="flex flex-row items-center justify-start gap-[0px_2px]">
          <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[0px_2px]">
          <img
            className="h-3 w-3 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/messagechatcircle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[0px_2px]">
          <input
            className="cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
            type="radio"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedBadge;
