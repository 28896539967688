import { FunctionComponent } from "react";

const PhoneNumberFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">휴대폰 번호</b>
      <div className="self-stretch flex flex-row items-center justify-center">
        <div className="flex-1 flex flex-row items-center justify-center p-3 relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle-1.svg"
          />
          <div className="flex-1 overflow-hidden flex flex-row items-center justify-between py-0 px-1 z-[1]">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                01012345678
              </div>
              <div className="h-4 w-px relative bg-black" />
            </div>
            <img
              className="h-[18px] w-[18px] relative object-cover"
              loading="eager"
              alt=""
              src="/btn-remove@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch relative text-xs tracking-[-0.25px] leading-[20px] font-medium text-solid1">
        사용할 수 있는 번호입니다.
      </div>
    </section>
  );
};

export default PhoneNumberFrame;
