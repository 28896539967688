import { FunctionComponent, useMemo, type CSSProperties } from "react";

type FrameComponent13Type = {
  prop?: string;

  /** Style props */
  propHeight?: CSSProperties["height"];
};

const FrameComponent13: FunctionComponent<FrameComponent13Type> = ({
  prop,
  propHeight,
}) => {
  const frameDiv1Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  return (
    <div
      className="w-[130px] flex flex-col items-start justify-start gap-[12px] text-left text-base text-dark-grey font-body5medium"
      style={frameDiv1Style}
    >
      <b className="relative tracking-[-0.25px] leading-[24px]">{prop}</b>
      <div className="self-stretch h-[130px] relative">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md box-border border-[1px] border-solid border-line-color" />
            <div className="absolute h-[18.46%] w-[18.46%] top-[40.77%] right-[40.77%] bottom-[40.77%] left-[40.77%] flex flex-col items-start justify-start z-[1]">
              <img className="w-6 h-6 relative" alt="" src="/imageadd.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent13;
