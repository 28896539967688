import { FunctionComponent } from "react";
import Component1 from "./Component1";
import Component from "./Component";

const Text1: FunctionComponent = () => {
  return (
    <section className="relative w-full flex flex-row items-start justify-start gap-[15px] text-left text-sm text-black font-body5medium">
      <div className="flex-1 flex flex-col items-start justify-start gap-[14px]">
        <div className="flex flex-row items-center justify-start py-0 pr-5 pl-0 gap-[2px] text-xs text-solid1">
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            판매 중
          </div>
        </div>
        <div className="self-stretch aspect-square relative">
          <img
            className="relative aspect-square w-full  rounded-lg max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-7@2x.png"
          />
          <img
            className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
            loading="eager"
            alt=""
            src="/heart.svg"
          />
        </div>
        <Component1 />
        <div className="self-stretch aspect-square relative">
          <img
            className="relative aspect-square w-full rounded-lg max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/rectangle-7-1@2x.png"
          />
          <img
            className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
            loading="eager"
            alt=""
            src="/heart-2.svg"
          />
        </div>
        <Component />
      </div>
      <div className="self-stretch flex-1 flex flex-col items-end justify-start gap-[16px] text-xs text-gray">
        <div className="flex flex-row items-center justify-start py-0 pr-0 pl-5 gap-[2px]">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            종류
          </div>
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
          />
        </div>
        <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[11px]">
          <div className="self-stretch aspect-square relative">
            <img
              className="relative aspect-square w-full rounded-lg max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/rectangle-7-1@2x.png"
            />
            <img
              className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
              loading="eager"
              alt=""
              src="/heart-2.svg"
            />
          </div>
          <Component1 />
          <div className="self-stretch flex-1 flex flex-col items-end justify-start">
            <div className="flex-1 [filter:drop-shadow(0px_4px_5.8px_rgba(0,_0,_0,_0.2))] flex flex-col items-center justify-start py-0 pr-0 pl-5 relative gap-[8px]">
              <img className="w-[50px] h-[50px] relative" alt="" />
              <button className="cursor-pointer [border:none] p-0 bg-[transparent] my-0 mx-[!important] absolute w-[calc(100%_-_20px)] top-[calc(50%_-_25px)] right-[0px] left-[20px] flex flex-row items-center justify-center">
                <img
                  className="h-[50px] w-[50px] relative rounded-[50%] object-cover"
                  alt=""
                />
              </button>
              <div className="w-[calc(100%_-_20px)] h-[50px] my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[20px] flex flex-row items-start justify-start gap-[10px]">
                <div className="self-stretch flex-1 relative rounded-[50%] bg-solid1" />
                <img
                  className="h-0.5 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
                  alt=""
                />
                <img
                  className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
                  loading="eager"
                  alt=""
                />
              </div>
            </div>
            <div className="self-stretch aspect-square relative z-[1] mt-[-160px]">
              <img
                className="aspect-square w-full rounded-lg max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/rectangle-7-1@2x.png"
              />
              <img
                className="absolute h-[11.25%] w-[11.25%] top-[6.25%] right-[6.25%] bottom-[82.5%] left-[82.5%] max-w-full overflow-hidden max-h-full z-[1]"
                loading="eager"
                alt=""
                src="/heart-2.svg"
              />
            </div>
          </div>
          <Component />
        </div>
      </div>
    </section>
  );
};

export default Text1;
