import { FunctionComponent, useMemo, type CSSProperties } from "react";

type CommulistAreaType = {
  prop?: string;

  /** Style props */
  propBorderBottom?: CSSProperties["borderBottom"];
  propHeight?: CSSProperties["height"];
  propHeight1?: CSSProperties["height"];
  propAlignSelf?: CSSProperties["alignSelf"];
};

const CommulistArea: FunctionComponent<CommulistAreaType> = ({
  prop,
  propBorderBottom,
  propHeight,
  propHeight1,
  propAlignSelf,
}) => {
  const commulistArea2Style: CSSProperties = useMemo(() => {
    return {
      borderBottom: propBorderBottom,
    };
  }, [propBorderBottom]);

  const communityListAreaStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  const badgeCategoryBadge11Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight1,
      alignSelf: propAlignSelf,
    };
  }, [propHeight1, propAlignSelf]);

  return (
    <section
      className="flex flex-col items-start justify-start pt-0 px-5 pb-5 gap-[8px] text-left text-xs text-solid1 font-body5medium"
      style={commulistArea2Style}
    >
      <div
        className="flex flex-row items-center justify-start gap-[8px] text-center"
        style={communityListAreaStyle}
      >
        <div
          className="h-6 rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-start py-0 px-2 box-border"
          style={badgeCategoryBadge11Style}
        >
          <b className="relative tracking-[-0.25px] leading-[20px]">{prop}</b>
        </div>
        <b className="relative tracking-[-0.25px] leading-[20px] text-gray">
          닉네임
        </b>
      </div>
      <div className="flex flex-col items-start justify-start gap-[6px] text-base text-black">
        <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
          게시글 제목을 입력하세요
        </div>
        <div className="relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-light-gray">
          게시글의 내용이 노출되는 곳입니다ㅎㅎㅎㅎㅎㅎㅎㅎㅎ 최대...
        </div>
      </div>
      <div className="flex flex-row items-start justify-start gap-[10px] text-place-holder">
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <img className="h-3 w-3 relative" alt="" src="/eyeopen.svg" />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <img
            className="h-3 w-3 relative overflow-hidden shrink-0"
            alt=""
            src="/messagechatcircle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[2px]">
          <input
            className="cursor-pointer m-0 h-3 w-3 relative overflow-hidden shrink-0"
            type="radio"
          />
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            0
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommulistArea;
