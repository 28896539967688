import { FunctionComponent } from "react";

const AppBarInstance: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-xs text-solid1 font-body5medium">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 px-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="relative tracking-[-0.25px] leading-[24px] z-[1] w-full">
        
        </b>
      </div>
      <div className="self-stretch flex flex-row items-start justify-between py-0 px-5">
        <div className="w-[196px] flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="w-[57px] rounded-lg bg-solid2 overflow-hidden flex flex-row items-center justify-center p-0.5 box-border">
              <b className="relative tracking-[-0.25px] leading-[20px]">
                수다수다
              </b>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[10px] text-left text-base text-dark-grey">
              <div className="h-11 w-11 relative rounded-[50%] bg-gainsboro-200" />
              <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium">
                  닉네임
                </div>
                <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      2024.01.01
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      |
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-[2px]">
                    <img
                      className="h-3 w-3 relative"
                      loading="eager"
                      alt=""
                      src="/eyeopen1.svg"
                    />
                    <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                      0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[16px]">
          <img
            className="h-[18px] w-[18px] relative min-h-[18px]"
            loading="eager"
            alt=""
            src="/-icon.svg"
          />
          <img
            className="h-[18px] w-[18px] relative min-h-[18px]"
            loading="eager"
            alt=""
            src="/share-icon.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default AppBarInstance;
