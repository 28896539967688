import { FunctionComponent } from "react";
import MainFrame from "../components/MainFrame";
import { useNavigate } from "react-router-dom";

const Frame17: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-10 box-border gap-[100px]">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 relative gap-[12px] text-center text-base text-black font-body5medium">
        
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="flex-1 relative tracking-[-0.25px] leading-[24px] z-[1]">
          닉네임 등록
        </b>
      </div>
      <MainFrame />
      <button className="cursor-pointer [border:none] py-3 pr-[21px] pl-5 bg-[transparent] w-[335px] flex flex-row items-center justify-center box-border relative"
      onClick={()=>{navigate("/");}}>
        <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
        <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
          등록
        </b>
      </button>
    </div>
  );
};

export default Frame17;
