import { FunctionComponent } from "react";
import Default from "./Default";

const TitleFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start py-0 pr-5 pl-[19px] gap-[20px] text-left text-base text-black font-body5medium">
      <b className="w-[125px] relative tracking-[-0.25px] leading-[24px] flex items-center">
        판매자 유형 선택
      </b>
      <div className="self-stretch flex flex-col items-start justify-start py-0 pr-px pl-0 gap-[12px] text-center text-sm">
        <Default prop="개인 판매" prop1="개인가의 판매가 가능해요!" />
        <Default prop="사업자 판매" prop1="사업자등록증 인증이 필요합니다." />
      </div>
    </section>
  );
};

export default TitleFrame;
