import { FunctionComponent } from "react";

const CompletedFrame1: FunctionComponent = () => {
  return (
    <div className="h-[206px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[20px] text-center text-base text-black font-body5medium">
      <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
        <p className="m-0">분양을 완료하셨네요.</p>
        <p className="m-0">별점과 한줄평가를 부탁드려요!</p>
      </div>
      <div className="flex flex-row items-start justify-start py-0 pr-2 pl-[7px] gap-[6px]">
        <img
          className="h-[30px] w-[30px] relative min-h-[30px]"
          loading="eager"
          alt=""
          src="/starfull1.svg"
        />
        <img
          className="h-[30px] w-[30px] relative min-h-[30px]"
          loading="eager"
          alt=""
          src="/starfull1.svg"
        />
        <img
          className="h-[30px] w-[30px] relative min-h-[30px]"
          loading="eager"
          alt=""
          src="/starfull1.svg"
        />
        <img
          className="h-[30px] w-[30px] relative min-h-[30px]"
          loading="eager"
          alt=""
          src="/starnon1.svg"
        />
        <img
          className="h-[30px] w-[30px] relative min-h-[30px]"
          loading="eager"
          alt=""
          src="/starnon1.svg"
        />
      </div>
      <b className="relative text-lg tracking-[-0.25px] leading-[26px]">3.0</b>
    </div>
  );
};

export default CompletedFrame1;
