import { FunctionComponent } from "react";
import CommunityFrame3 from "./CommunityFrame3";

const CommulistFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1">
      <div className="flex-1 flex flex-col items-center justify-start pt-0 px-0 pb-5 border-b-[1px] border-solid border-line-color">
        <div className="self-stretch flex flex-row items-start justify-end py-0 px-5">
          <img
            className="h-5 w-5 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/xclose.svg"
          />
        </div>
        <CommunityFrame3
          prop="수다수다"
          heartChecked
          propWidth="unset"
          propPadding="0px 20px"
          propAlignSelf="stretch"
          propFlexWrap="wrap"
          propFlex="1"
          propMinWidth="146px"
          propAlignSelf1="stretch"
          propBorderColor="#d7d7d7"
          propAccentColor="#d7d7d7"
        />
      </div>
    </section>
  );
};

export default CommulistFrame;
