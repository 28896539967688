import { FunctionComponent, useCallback } from "react";
import RightArrowButton from "../new/RightArrowButton";
import FrameComponent6 from "../new/FrameComponent6";
import FrameComponent5 from "../new/FrameComponent5";
import FrameComponent4 from "../new/FrameComponent4";
import FrameComponent3 from "../new/FrameComponent3";
import FrameComponent2 from "../new/FrameComponent2";
import FrameComponent1 from "../new/FrameComponent1";
import Component1 from "../new/Component1";

const ReptailDetail: FunctionComponent = () => {
  const onFrameContainerClick = useCallback(() => {
    // Please sync "타인프로필" to the project
  }, []);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start text-left text-base text-black font-body5medium">
      
      <RightArrowButton />
      <b className="w-[79px] relative tracking-[-0.25px] leading-[24px] hidden items-center h-[34.5px] shrink-0">
        200,000원
      </b>
      <div className="self-stretch h-px relative bg-line-color hidden" />
      <FrameComponent6 />
      <div className="self-stretch h-[30px] flex flex-row items-start justify-start pt-0 px-0 pb-[30px] box-border">
        <img
          className="flex-1 relative rounded-12xs max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/new/vector-3.svg"
        />
      </div>
      <FrameComponent5 />
      <div className="self-stretch h-[30px] flex flex-row items-start justify-start pt-0 px-0 pb-[30px] box-border">
        <img
          className="flex-1 relative rounded-12xs max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/new/vector-3.svg"
        />
      </div>
      <FrameComponent4
        emptyFrame="/new/frame-571@2x.png"
        emptyFrame1="/new/frame-581@2x.png"
        buttonFloat="/new/frame-591@2x.png"
      />
      <div className="self-stretch h-[30px] flex flex-row items-start justify-start pt-0 px-0 pb-[30px] box-border">
        <img
          className="flex-1 relative rounded-12xs max-w-full overflow-hidden max-h-full"
          loading="eager"
          alt=""
          src="/new/vector-3.svg"
        />
      </div>
      <FrameComponent3 propHeight="292px" />
      <FrameComponent2 onFrameContainerClick={onFrameContainerClick} />
      <FrameComponent1 />
      <Component1 prop="분양하기" />
      <div className="h-[166px] my-0 mx-[!important] fixed bottom-[20px] right-[30px] [filter:drop-shadow(0px_4px_5.8px_rgba(0,_0,_0,_0.2))] flex flex-col items-center justify-start gap-[8px_0px] z-[1]">
                <img
                  className="w-[50px] h-[50px] relative"
                  loading="eager"
                  alt=""
                  src="/new/group-163.svg"
                />
                <div className="my-0 mx-[!important] absolute w-full top-[calc(50%_-_25px)] right-[0px] left-[0px] flex flex-row items-center justify-center">
                  <img
                    className="h-[50px] w-[50px] relative rounded-[50%] object-cover"
                    loading="eager"
                    alt=""
                    src="/new/ellipse-29@2x.png"
                  />
                </div>
                <div className="w-full h-[50px] my-0 mx-[!important] absolute right-[0px] bottom-[0px] left-[0px] flex flex-row items-start justify-start gap-[0px_10px]">
                  <div className="self-stretch flex-1 relative rounded-[50%] bg-solid1" />
                  <img
                    className="h-0.5 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
                    alt=""
                    src="/new/register-personal-info.svg"
                  />
                  <img
                    className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
                    loading="eager"
                    alt=""
                    src="/new/vector-32.svg"
                  />
                </div>
          </div>
    </div>
  );
};

export default ReptailDetail;
