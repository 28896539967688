import { FunctionComponent } from "react";

const Component7: FunctionComponent = () => {
  return (
    <div className="self-stretch flex flex-col items-end justify-start text-left text-base text-dark-grey font-body5medium">
      <div className="self-stretch flex flex-row items-center justify-start pt-0 px-5 pb-5 border-b-[1px] border-solid border-line-color">
        <div className="flex-1 flex flex-row flex-wrap items-center justify-start gap-[10px]">
          <div className="flex flex-row items-start justify-start">
            <img
              className="h-11 w-11 relative"
              loading="eager"
              alt=""
              src="/group1.svg"
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[31px] pl-0 box-border gap-[4px] min-w-[183px]">
            <div className="self-stretch flex flex-row items-center justify-center gap-[9px]">
              <div className="flex-1 flex flex-row items-start justify-start">
                <div className="relative tracking-[-0.25px] leading-[24px]">
                  <p className="m-0">
                    <b className="font-body5medium">배송을 잘 받으셨나요?</b>
                    <span className="font-medium"> 배송 상태 인증</span>
                  </p>
                  <p className="m-0 font-medium">
                    사진과 함께 판매자의 후기를 남겨주세요.
                  </p>
                </div>
              </div>
              <img
                className="h-[22px] w-[22px] relative overflow-hidden shrink-0 hidden"
                alt=""
                src="/chatnew.svg"
              />
            </div>
            <div className="flex flex-row items-start justify-start gap-[4px] text-xs text-light-gray">
              <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
                1시간 전
              </div>
              <div className="self-stretch w-1 relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
                |
              </div>
              <div className="self-stretch w-[70px] relative tracking-[-0.25px] leading-[20px] font-medium hidden items-center">
                등록개체999+
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component7;
