import { FunctionComponent } from "react";
import CommunityPostThumbnail from "../components/CommunityPostThumbnail";
import Component5 from "../components/Component5";

const On: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-px box-border gap-[20px]">
      
      <CommunityPostThumbnail />
      <section className="self-stretch flex flex-col items-start justify-start py-0 px-5 box-border gap-[16px] min-h-[116px] text-left text-base text-black font-body5medium">
        <b className="self-stretch relative tracking-[-0.25px] leading-[24px]">
          게시글 제목을 입력하세요
        </b>
        <div className="self-stretch relative text-sm tracking-[-0.25px] leading-[22px] font-medium text-gray">{`게시글의 내용이 노출되는 곳입니다. 게시글의 내용이 노출되는 곳입니다. 게시글의 내용이 노출되는 곳입니다. 게시글의 내용이 노출되는 곳입니다. `}</div>
      </section>
      <section className="self-stretch h-[389px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[20px] text-center text-sm text-gray font-body5medium">
        <div className="rounded-xl overflow-hidden flex flex-row items-center justify-start py-[5px] pr-[11px] pl-[9px] gap-[5px] border-[1px] border-solid border-line-color">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px]"
            loading="eager"
            alt=""
            src="/ic24.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            공감3
          </div>
        </div>
        <div className="self-stretch flex-1 relative rounded-lg bg-box-color overflow-hidden" />
      </section>
      <Component5 prop="텍스트입력" />
    </div>
  );
};

export default On;
