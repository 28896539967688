import { FunctionComponent } from "react";
import AppBar from "../components/AppBar";
import ContactInfoFrame from "../components/ContactInfoFrame";

const Frame4: FunctionComponent = () => {
  return (
    <div className="max-w-[540px] m-auto w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[268px]">
      <AppBar />
      <ContactInfoFrame />
    </div>
  );
};

export default Frame4;
