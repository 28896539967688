import { FunctionComponent } from "react";

const CategorySection: FunctionComponent = () => {
  return (
    <section className=" w-full self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-4 text-left text-lg text-white font-body5medium">
      <div className="self-stretch h-[140px] overflow-hidden shrink-0 flex flex-row items-start justify-start z-[1]">
        <div className="h-[154px] flex-1 relative">
          <div className="absolute top-[0px] left-[0px] bg-solid1 w-full h-[140px]">
            <div className="absolute top-[0px] left-[0px] bg-solid1 w-full h-[140px] hidden" />
            <h3 className="m-0 absolute top-[26px] left-[20px] text-inherit tracking-[-0.25px] leading-[26px] font-bold font-inherit z-[1]">
              파충류를 위한 전문 쇼핑몰 렙테일샵
            </h3>
            <img
              className="absolute top-[27px] left-[272px] w-6 h-6 overflow-hidden z-[1]"
              alt=""
              src="/new/chevronright.svg"
            />
            <div className="absolute top-[62px] left-[20px] text-sm tracking-[-0.25px] leading-[22px] z-[1]">
              <p className="m-0">
                <b className="font-body5medium">귀뚜라미</b>
                <span className="font-medium font-body5medium">{`, `}</span>
                <b className="font-body5medium">먹이</b>
                <span className="font-medium font-body5medium">{`부터 `}</span>
                <b className="font-body5medium">사육장</b>
                <span className="font-medium"> 등 소중한 내 파충류를</span>
              </p>
              <p className="m-0">
                <span className="font-medium">{`위한 다양한 용품까지 다 있는 `}</span>
                <b className="font-body5medium">렙테일샵</b>
                <span className="font-medium font-body5medium">!</span>
              </p>
            </div>
            <img
              className="absolute top-[120px] left-[164px] w-1.5 h-1.5 overflow-hidden z-[1]"
              alt=""
              src="/new/frame-98.svg"
            />
            <img
              className="absolute top-[120px] left-[174px] w-1.5 h-1.5 overflow-hidden z-[1]"
              alt=""
              src="/new/frame-97.svg"
            />
            <img
              className="absolute top-[120px] left-[184px] w-1.5 h-1.5 overflow-hidden z-[1]"
              alt=""
              src="/new/frame-97.svg"
            />
            <img
              className="absolute top-[120px] left-[194px] w-1.5 h-1.5 overflow-hidden z-[1]"
              alt=""
              src="/new/frame-97.svg"
            />
            <img
              className="absolute top-[120px] left-[204px] w-1.5 h-1.5 overflow-hidden z-[1]"
              alt=""
              src="/new/frame-97.svg"
            />
            <img
              className="absolute top-[22.2px] left-[5.6px] w-[7.9px] h-[7.7px] object-contain z-[1]"
              loading="eager"
              alt=""
              src="/new/24x8-1@2x.png"
            />
            <img
              className="absolute top-[21px] left-[352px] w-[12.8px] h-2.5 object-contain z-[1]"
              loading="eager"
              alt=""
              src="/new/24x8-3@2x.png"
            />
            <img
              className="absolute top-[30px] left-[313px] w-1 h-1 object-contain z-[4]"
              alt=""
              src="/new/44x8-1@2x.png"
            />
            <img
              className="absolute top-[129px] left-[13px] w-1 h-1 object-contain z-[1]"
              alt=""
              src="/new/44x8-1@2x.png"
            />
            <img
              className="absolute top-[7px] left-[117px] w-1.5 h-1.5 object-contain z-[1]"
              alt=""
              src="/new/44x8-3@2x.png"
            />
            <img
              className="absolute top-[-1px] left-[30px] w-1.5 h-1.5 object-contain z-[1]"
              loading="eager"
              alt=""
              src="/new/34x8-1@2x.png"
            />
            <img
              className="absolute top-[11px] left-[333px] w-1.5 h-1.5 object-contain z-[1]"
              alt=""
              src="/new/34x8-1@2x.png"
            />
            <img
              className="absolute top-[126px] left-[242px] w-1.5 h-1.5 object-contain z-[1]"
              alt=""
              src="/new/34x8-1@2x.png"
            />
            <img
              className="absolute top-[122px] left-[72px] w-[5px] h-[5px] object-cover z-[1]"
              alt=""
              src="/new/-94x8-1@2x.png"
            />
            <img
              className="absolute top-[18px] left-[226px] w-[3px] h-[3px] object-cover z-[1]"
              alt=""
              src="/new/-94x8-2@2x.png"
            />
          </div>
          <div className="absolute top-[34px] left-[260px] w-[120px] h-[120px]">
            <img
              className="absolute top-[0px] left-[0px] w-[120px] h-[120px] object-cover"
              alt=""
              src="/new/group-316@2x.png"
            />
            <img
              className="absolute top-[-0.2px] left-[31.7px] w-[75.8px] h-[75.8px] object-contain z-[3]"
              alt=""
              src="/new/homeon-nav@2x.png"
            />
            <img
              className="absolute top-[76px] left-[100px] w-[9.3px] h-[7.6px] object-contain z-[3]"
              loading="eager"
              alt=""
              src="/new/4x8-1@2x.png"
            />
            <img
              className="absolute top-[67.8px] left-[4.3px] w-[7.4px] h-2 object-contain z-[3]"
              loading="eager"
              alt=""
              src="/new/24x8-2@2x.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
