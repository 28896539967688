import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

type Component2Type = {
  prop?: string;

  /** Action props */
  onSectionClick?: () => void;
};

const Component2: FunctionComponent<Component2Type> = ({
  prop,
  onSectionClick,
}) => {
  return (
    <section
      className="self-stretch bg-white box-border flex flex-row items-start justify-center pt-3.5 px-3.5 pb-10 max-w-full border-t-[1px] border-solid border-line-color"
      onClick={onSectionClick}
    >
      <Link to="/list/buy">
      <button className="cursor-pointer [border:none] py-3 pr-[21px] pl-5 bg-[transparent] w-[335px] flex flex-row items-center justify-center box-border relative max-w-full">
        <div className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg bg-solid1" />
        <b className="relative text-base tracking-[-0.25px] leading-[24px] font-body5medium text-white text-center z-[1]">
          {prop}
        </b>
      </button>
      </Link>
    </section>
  );
};

export default Component2;
