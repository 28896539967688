import { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";

const TabNotificationFrame: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-center text-base text-solid1 font-body5medium">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 relative gap-[12px] text-center text-base text-black font-body5medium">
        <img
          className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/chevronleft.svg"
        />
        <b className="relative tracking-[-0.25px] leading-[24px] z-[1] w-full">알림</b>
      </div>
      <div className="self-stretch overflow-hidden flex flex-row items-end justify-start pt-1.5 px-0 pb-0">
        <div className="h-px flex-1 relative bg-line-color" />
        <div className="w-full flex flex-row items-center justify-center z-[1] ml-[-500px]">
          <div className="flex-1 flex flex-row items-start justify-start py-0 gap-[1px]">
            <div className="flex-1 flex flex-col items-center justify-start gap-[8px]">
              <div className="flex flex-row items-start justify-start py-0">
                <b className="relative tracking-[-0.25px] leading-[24px]">
                  렙테일 알림
                </b>
              </div>
              <div className="w-full h-0.5 relative bg-solid1" />
            </div>

  
            <div onClick={() => {navigate("/notify/community")}} className="cursor-pointer flex-1 flex flex-row items-start justify-center pt-0 px-0 pb-2.5 text-dark-grey">
              <div className="flex flex-row items-center justify-center py-0 pr-[25px] pl-[30px]">
                <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                  커뮤니티 알림
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default TabNotificationFrame;
