import { FunctionComponent } from "react";

const FrameAuthenticationInput: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start gap-[11px] text-left text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">인증번호</b>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-place-holder">
        <div className="flex-1 flex flex-row items-center justify-center p-3 box-border relative min-w-[136px]">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle.svg"
          />
          <div className="w-[177px] relative tracking-[-0.25px] leading-[24px] font-medium inline-block shrink-0 z-[1]">
            인증번호를 입력하세요.
          </div>
        </div>
        <button className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-[transparent] overflow-hidden flex flex-row items-center justify-start relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            인증요청
          </b>
        </button>
      </div>
    </section>
  );
};

export default FrameAuthenticationInput;
