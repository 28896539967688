import { FunctionComponent } from "react";
import PersonalInfoFrame from "../components/PersonalInfoFrame";
import Component2 from "../components/Component2";

const Frame21: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[268px]">
      <PersonalInfoFrame />
      <Component2 prop="다음" />
    </div>
  );
};

export default Frame21;
