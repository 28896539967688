import { FunctionComponent } from "react";

export type MobileRegisterBtnType = {
  icon?: string;
};

const MobileRegisterBtn: FunctionComponent<MobileRegisterBtnType> = ({
  icon,
}) => {
  return (
    <div className="self-stretch flex flex-row items-start justify-between py-0 px-5 text-left text-xs text-gray font-body5medium">
      <div className="w-[162px] flex flex-col items-start justify-start gap-[16px_0px]">
        <div className="flex flex-row items-center justify-start gap-[0px_4px]">
          <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
            닉네임
          </div>
          <img
            className="h-4 w-4 relative overflow-hidden shrink-0"
            loading="eager"
            alt=""
            src="/new/chevronright.svg"
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px_0px] text-center text-lg text-black">
          <b className="relative tracking-[-0.25px] leading-[26px]">
            상품명이 노출되는 부분
          </b>
          <b className="relative text-xl tracking-[-0.25px] leading-[28px] text-solid1">
            300,000원
          </b>
        </div>
      </div>
      <img
        className="h-[22px] w-[22px] relative"
        loading="eager"
        alt=""
        src={icon}
      />
    </div>
  );
};

export default MobileRegisterBtn;
