import { FunctionComponent } from "react";

const FrameComponent8: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-end justify-start py-0 px-5 gap-[13px] text-left text-base text-black font-body5medium">
      <div className="self-stretch flex flex-row items-start justify-start text-dark-grey">
        <b className="w-[101px] relative tracking-[-0.25px] leading-[24px] flex items-center shrink-0">
          거래 가능 주소
        </b>
      </div>
      <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px]">
        <div className="flex-1 rounded-lg bg-box-color flex flex-row items-center justify-start py-3 px-4 box-border min-w-[43px]">
          <img
            className="h-12 w-[209px] relative rounded-lg hidden"
            alt=""
            src="/rectangle.svg"
          />
          <div className="relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            13002
          </div>
        </div>
        <button className="cursor-pointer [border:none] py-4 pr-[42px] pl-5 bg-solid1 rounded-lg overflow-hidden flex flex-row items-center justify-start">
          <img
            className="h-12 w-[114px] relative rounded-lg hidden"
            alt=""
            src="/rectangle.svg"
          />
          <b className="relative text-base tracking-[-0.25px] leading-[16px] font-body5medium text-white text-center z-[1]">
            주소찾기
          </b>
        </button>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center">
        <div className="flex-1 flex flex-row items-center justify-center">
          <div className="flex-1 flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative">
            <img
              className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
              loading="eager"
              alt=""
              src="/rectangle.svg"
            />
            <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
              서울특별시 성동구
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center text-place-holder">
        <div className="flex-1 flex flex-row items-center justify-start py-3 pr-[50px] pl-4 relative">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            loading="eager"
            alt=""
            src="/rectangle-4.svg"
          />
          <div className="flex-1 relative tracking-[-0.25px] leading-[24px] font-medium z-[1]">
            성동로 00 (성동동, 성동시티 8단지)
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[4px] text-xs text-solid1">
        <img
          className="h-[18px] w-[18px] relative overflow-hidden shrink-0"
          loading="eager"
          alt=""
          src="/btncircleon.svg"
        />
        <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
          상세주소 비공개
        </div>
      </div>
    </section>
  );
};

export default FrameComponent8;
