import { FunctionComponent } from "react";
import Maincommunityframe from "../components/Maincommunityframe";
import CommunityFrame from "../components/CommunityFrame";
import Btn from "../components/Btn";
import CommulistArea from "../components/CommulistArea";
import Nav1 from "../components/Nav1";

const Frame44: FunctionComponent = () => {
  return (
    <div className="pt-[20px] m-auto max-w-[540px] w-full relative bg-white overflow-hidden flex flex-col items-start justify-start gap-[12px] tracking-[normal]">
      <Maincommunityframe />
      <CommunityFrame prop="헬프미닥터" heartChecked />
      <CommunityFrame
        prop="닥터케어팁"
        propBorderBottom="1px solid #e5e5e5"
        propBorderColor="unset"
        propAccentColor="unset"
      />
      <CommunityFrame
        prop="꿀팁공유"
        propBorderBottom="1px solid #fff"
        propBorderColor="unset"
        propAccentColor="unset"
      />
      <Btn />
      <CommulistArea prop="질문해요" />
  
    </div>
  );
};

export default Frame44;
