import { FunctionComponent } from "react";

const FrameComponent6: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-start justify-start pt-0 px-5 pb-[30px] gap-[20px_0px] text-center text-base text-black font-body5medium">
      <b className="relative tracking-[-0.25px] leading-[24px]">개체 정보</b>
      <div className="self-stretch rounded-lg bg-box-color overflow-hidden flex flex-col items-center justify-start p-5 gap-[14px_0px] text-left text-sm text-gray">
        <div className="self-stretch flex flex-row items-start justify-between">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium flex items-center shrink-0">
            종
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey text-right flex items-end shrink-0">
            텍스트
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium flex items-center shrink-0">
            모프
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-dark-grey text-right flex items-center shrink-0">
            텍스트
          </div>
        </div>
        <div className="self-stretch h-px relative bg-line-color" />
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[0px_14px] text-right text-dark-grey">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-gray text-left flex items-center shrink-0">
            크기
          </div>
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium">
              텍스트
            </div>
          <div className="flex-1 flex flex-row items-start justify-start gap-[0px_19px] min-w-[93px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-gray text-left flex items-center shrink-0">
              성별
            </div>
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium flex items-center shrink-0">
            -
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[0px_14px] text-right text-dark-grey">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-gray text-left flex items-center shrink-0">
            체중
          </div>
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium">
              -
            </div>
          <div className="flex-1 flex flex-row items-start justify-start gap-[0px_19px] min-w-[93px]">
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium text-gray text-left flex items-center shrink-0">
              해칭
            </div>
          </div>
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium flex items-center shrink-0">
            -
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent6;
