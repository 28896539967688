import { FunctionComponent, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import FramesStack from "../components/FramesStack";


const Notice: FunctionComponent = () => {
  const navigate = useNavigate();

  const onFramesStackContainerClick = useCallback(() => {
    navigate("/notice/detail");
  }, [navigate]);

  return (
    <div className="relative bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px] text-left text-sm text-place-holder font-body5medium">
      <div className="self-stretch overflow-hidden flex flex-row items-start justify-start py-3 relative gap-[12px] text-center text-base text-black font-body5medium">
        <Link to="/">
          <img
            className="h-6 w-6 absolute overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
        </Link>
        <b className="relative tracking-[-0.25px] leading-[24px] z-[1] text-center w-full">
          공지사항
        </b>
      </div>
      <div className="w-full rounded-lg bg-[#eee] flex flex-row items-start justify-start py-3 px-4 box-border relative gap-[10px]">
      
        <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
          검색어를 입력하세요.
        </div>
        <img
          className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
          loading="eager"
          alt=""
          src="/searchmd1.svg"
        />
      </div>
      <section className="self-stretch flex flex-col items-start justify-start gap-[20px] text-left text-base text-black font-body5medium">
        <FramesStack
          prop="공지사항 최대값 기준 노출케이스 입니다..."
          onFramesStackContainerClick={onFramesStackContainerClick}
        />
        <FramesStack
          prop="제목이 노출됩니다."
          propAlignSelf="unset"
          onFramesStackContainerClick={onFramesStackContainerClick}
        />
        <div
          className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-5 gap-[10px] cursor-pointer border-b-[1px] border-solid border-line-color"
          onClick={onFramesStackContainerClick}
        >
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium pl-5 pr-5">
            제목이 노출됩니다.
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 text-xs text-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              2023.01.01
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-5 gap-[10px] cursor-pointer border-b-[1px] border-solid border-line-color"
          onClick={onFramesStackContainerClick}
        >
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium pl-5 pr-5">
            제목이 노출됩니다.
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 text-xs text-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              2023.01.01
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-5 gap-[10px] cursor-pointer border-b-[1px] border-solid border-line-color"
          onClick={onFramesStackContainerClick}
        >
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium pl-5 pr-5">
            제목이 노출됩니다.
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 text-xs text-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              2023.01.01
            </div>
          </div>
        </div>
        <div
          className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-5 gap-[10px] cursor-pointer border-b-[1px] border-solid border-line-color"
          onClick={onFramesStackContainerClick}
        >
          <div className="self-stretch relative tracking-[-0.25px] leading-[24px] font-medium pl-5 pr-5">
            제목이 노출됩니다.
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-5 text-xs text-gray">
            <div className="relative tracking-[-0.25px] leading-[20px] font-medium">
              2023.01.01
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Notice;
