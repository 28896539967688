import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type Component3Type = {
  prop?: string;

  /** Style props */
  propTop?: CSSProperties["top"];
  propRight?: CSSProperties["right"];
  propBottom?: CSSProperties["bottom"];
};

const Component3: FunctionComponent<Component3Type> = ({
  prop,
  propTop,
  propRight,
  propBottom,
}) => {
  const div5Style: CSSProperties = useMemo(() => {
    return {
      top: propTop,
      right: propRight,
      bottom: propBottom,
    };
  }, [propTop, propRight, propBottom]);

  return (
    <div
      className="my-0 mx-[!important] absolute top-[-4px] right-[20px] rounded-lg bg-white shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)] flex flex-col items-center justify-center z-[2] text-center text-sm text-dark-grey font-body5medium"
      style={div5Style}
    >
      <div className="flex flex-row items-center justify-center py-2.5 px-5">
        <div className="flex flex-row items-center justify-center">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            {prop}
          </div>
        </div>
      </div>
      <img
        className="w-[70px] h-px relative"
        loading="eager"
        alt=""
        src="/ellipse.svg"
      />
      <div className="flex flex-row items-center justify-center py-2.5 px-[26px]">
        <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
          나가기
        </div>
      </div>
      <img
        className="w-[70px] h-px relative"
        loading="eager"
        alt=""
        src="/ellipse.svg"
      />
      <div className="flex flex-row items-center justify-center py-2.5 px-5">
        <div className="flex flex-row items-center justify-center">
          <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
            신고하기
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component3;
