import { FunctionComponent, useCallback } from "react";
import FrameComponent26 from "../components/FrameComponent26";
import { useNavigate } from "react-router-dom";

const Frame57: FunctionComponent = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/26");
  }, [navigate]);

  const onGroupContainer1Click = useCallback(() => {
    navigate("/26");
  }, [navigate]);

  const onGroupContainer2Click = useCallback(() => {
    navigate("/26");
  }, [navigate]);

  const onGroupContainer3Click = useCallback(() => {
    navigate("/26");
  }, [navigate]);

  const onGroupContainer4Click = useCallback(() => {
    navigate("/26");
  }, [navigate]);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[39px] box-border gap-[20px] tracking-[normal] text-left text-sm text-light-gray font-body5medium">
      <div className="w-[50px] hidden flex-col items-start justify-start gap-[5px]">
        <img
          className="w-[50px] h-[50px] relative"
          alt=""
          src="/group-163.svg"
        />
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start relative gap-[10px]">
          <div className="self-stretch flex-1 relative rounded-[50%] bg-soild-color z-[0]" />
          <img
            className="h-0 w-[15px] absolute my-0 mx-[!important] top-[25px] left-[17px] z-[1]"
            alt=""
            src="/vector-31.svg"
          />
          <img
            className="h-[15px] w-0 absolute my-0 mx-[!important] top-[17px] left-[25px] object-contain z-[2]"
            alt=""
            src="/vector-32.svg"
          />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 box-border top-[0] z-[99] sticky max-w-full">
        <header className="flex-1 overflow-hidden flex flex-row items-start justify-start py-3 pr-[55px] pl-5 box-border relative gap-[12px] max-w-full text-center text-base text-black font-body5medium">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/chevronleft.svg"
          />
          <b className="flex-1 relative tracking-[-0.25px] leading-[24px] whitespace-nowrap z-[1]">
            <p className="m-0">최근 분양가</p>
          </b>
        </header>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-5 pb-1 box-border max-w-full text-place-holder">
        <div className="flex-1 flex flex-row items-start justify-start py-3 px-4 box-border relative gap-[10px] max-w-full">
          <img
            className="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-lg max-w-full overflow-hidden max-h-full"
            alt=""
            src="/rectangle.svg"
          />
          <div className="flex-1 relative tracking-[-0.25px] leading-[22px] font-medium z-[1]">
            검색어를 입력하세요.
          </div>
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0 min-h-[24px] z-[1]"
            loading="eager"
            alt=""
            src="/searchmd1.svg"
          />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 px-0 box-border max-w-full text-center text-base text-dark-grey">
        <div className="overflow-x-auto flex flex-row items-end justify-start py-0 pr-5 pl-0 box-border max-w-[153%] shrink-0">
          <div className="h-px w-[575px] relative bg-line-color shrink-0" />
          <div className="w-[535px] shrink-0 flex flex-row items-start justify-start gap-[20px] max-w-full z-[1] ml-[-555px] mq450:flex-wrap">
            <div className="w-12 box-border flex flex-row items-start justify-center pt-0 px-0 pb-2.5 text-solid1 border-b-[2px] border-solid border-solid1">
              <b className="relative tracking-[-0.25px] leading-[24px]">게코</b>
            </div>
            <div className="flex-[0.8333] flex flex-row items-center justify-center py-0 pr-1.5 pl-2.5 box-border min-w-[62px] mq450:flex-1">
              <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                그 외 도마뱀
              </div>
            </div>
            <div className="flex-1 flex flex-row items-center justify-center min-w-[62px]">
              <div className="relative tracking-[-0.25px] leading-[24px] font-medium">
                모니터/테구
              </div>
            </div>
            <div className="w-[75px] flex flex-row items-center justify-center">
              <div className="w-[55px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center">
                스네이크
              </div>
            </div>
            <div className="h-6 w-[61px] flex flex-row items-center justify-center">
              <div className="self-stretch w-[41px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center">
                거북류
              </div>
            </div>
            <div className="h-6 w-[61px] flex flex-row items-center justify-center">
              <div className="self-stretch w-[41px] relative tracking-[-0.25px] leading-[24px] font-medium flex items-center justify-center">
                양서류
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrameComponent26 />
      <div
        className="self-stretch flex flex-col items-center justify-start gap-[20px] cursor-pointer"
        onClick={onGroupContainerClick}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 gap-[24px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[6px] min-w-[113px]">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="flex flex-row items-start justify-start gap-[4px]">
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  중분류
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  |
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                소분류
              </div>
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
              소분류 이름이 노출됩니다.
            </div>
          </div>
          <b className="w-[137px] relative text-base tracking-[-0.25px] leading-[24px] flex text-black text-right items-center shrink-0">
            50,000,000,000원
          </b>
        </div>
        <div className="self-stretch h-px relative bg-line-color" />
      </div>
      <div
        className="self-stretch flex flex-col items-center justify-start gap-[20px] cursor-pointer"
        onClick={onGroupContainer1Click}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 gap-[24px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[6px] min-w-[113px]">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="flex flex-row items-start justify-start gap-[4px]">
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  중분류
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  |
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                소분류
              </div>
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
              소분류 이름이 노출됩니다.
            </div>
          </div>
          <b className="w-[137px] relative text-base tracking-[-0.25px] leading-[24px] flex text-black text-right items-center shrink-0">
            50,000,000,000원
          </b>
        </div>
        <div className="self-stretch h-px relative bg-line-color" />
      </div>
      <div
        className="self-stretch flex flex-col items-center justify-start gap-[20px] cursor-pointer"
        onClick={onGroupContainer2Click}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 gap-[24px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[6px] min-w-[113px]">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="flex flex-row items-start justify-start gap-[4px]">
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  중분류
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  |
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                소분류
              </div>
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
              소분류 이름이 노출됩니다.
            </div>
          </div>
          <b className="w-[137px] relative text-base tracking-[-0.25px] leading-[24px] flex text-black text-right items-center shrink-0">
            50,000,000,000원
          </b>
        </div>
        <div className="self-stretch h-px relative bg-line-color" />
      </div>
      <div
        className="self-stretch flex flex-col items-center justify-start gap-[20px] cursor-pointer"
        onClick={onGroupContainer3Click}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 gap-[24px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[6px] min-w-[113px]">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="flex flex-row items-start justify-start gap-[4px]">
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  중분류
                </div>
                <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                  |
                </div>
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                소분류
              </div>
            </div>
            <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
              소분류 이름이 노출됩니다.
            </div>
          </div>
          <b className="w-[137px] relative text-base tracking-[-0.25px] leading-[24px] flex text-black text-right items-center shrink-0">
            50,000,000,000원
          </b>
        </div>
        <div className="self-stretch h-px relative bg-line-color" />
      </div>
      <div
        className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 px-5 box-border gap-[24px] max-w-full cursor-pointer"
        onClick={onGroupContainer4Click}
      >
        <div className="flex-1 flex flex-col items-start justify-start gap-[6px] min-w-[113px]">
          <div className="flex flex-row items-start justify-start gap-[4px]">
            <div className="flex flex-row items-start justify-start gap-[4px]">
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                중분류
              </div>
              <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
                |
              </div>
            </div>
            <div className="relative tracking-[-0.25px] leading-[22px] font-medium">
              소분류
            </div>
          </div>
          <div className="self-stretch relative tracking-[-0.25px] leading-[22px] font-medium text-black">
            소분류 이름이 노출됩니다.
          </div>
        </div>
        <b className="w-[137px] relative text-base tracking-[-0.25px] leading-[24px] flex text-black text-right items-center shrink-0">
          50,000,000,000원
        </b>
        <div className="h-px w-[375px] relative bg-line-color hidden max-w-full" />
      </div>
    </div>
  );
};

export default Frame57;
