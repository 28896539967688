import { FunctionComponent } from "react";
import AppBarInstance from "./AppBarInstance";

const BaseFrame: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col items-center justify-start gap-[20px] text-left text-base text-black font-body5medium">
      <AppBarInstance />
      <b className="self-stretch relative tracking-[-0.25px] leading-[24px] inline-block pl-5 pr-5">
        게시글 제목을 입력하세요
      </b>
    </section>
  );
};

export default BaseFrame;
